import React, { useState } from 'react';
import '../styles/Signup.css';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/api';
import { ToastContainer, toast } from 'react-toastify';

function Signup() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  const showAlert = (message) => {
    alert(message);
  };
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      confirmPassword === ''
    ) {
      alert('Please fill in all fields');
    } else if (password !== confirmPassword) {
      alert('Password and Confirm Password do not match');
    } else if (password.length > 15) {
      alert('Password must be at less than 15 characters long');
    } else if (selectedValue === '') {
      alert('You must select a bootcamp');
    } else {
      try {
        const resp = await api.userSignup({
          email,
          firstName,
          lastName,
          password,
          termAndCondition: true,
          type: selectedValue,
        });
        toast.success('Successfully Registered!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate('/newQuestion');
        }, 2000);
      } catch (error) {
        showAlert(
          `Invalid registration, please enter valid registration details ${error.response.data.error}`
        );
      }
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when the dropdown value changes
  };
  return (
    <div className="app-container">
      <div className="container">
        <h1 className="header-text">Create your account</h1>
        <p className="sub-text">All fields are required.</p>
        <input
          className="input-fields"
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <input
          className="input-fields"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <input
          className="input-fields"
          type="text"
          placeholder="Email Address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div class="custom-select">
          <select value={selectedValue} onChange={handleSelectChange}>
            <option value="option1">Select Bootcamp</option>
            <option value="pg">Product Gym</option>
            <option value="elevate">Elevate</option>
          </select>
        </div>
        <div className="password-field">
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Enter your password"
            className="password-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="toggle-password"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className="password-field">
          <input
            type={confirmPasswordVisible ? 'text' : 'password'}
            placeholder="confirm password"
            className="password-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            className="toggle-password"
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            {confirmPasswordVisible ? 'Hide' : 'Show'}
          </button>
        </div>
        <button className="button" onClick={handleSignup}>
          <span className="button-text">Create Account</span>
        </button>

        <span className="link-text">
          Already have an account? <a href="/login">Sign up here.</a>
        </span>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Signup;
