import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Analysis.module.css';
import { MainContent } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { pdfjs } from 'react-pdf';
import Modal from '@mui/material/Modal';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';
import StudentAnalysis from './StudentAnalysis';
import Analyzer from './Analyzer';
import { DocumentType } from 'components/SelectField';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Person from '../../../images/person.png';
import LoadingOverlay from 'components/LoadingOverlay';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { DocumentIcon } from 'svg/CustomSVGIcon';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { Done } from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const SubmitAnywayModal = ({
  open,
  setOpen,
  setSubmitOpen,
  documentId,
  setLoading,
  navigate,
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      status: 'coachReview',
    };
    const response = await organizationAPI.updateDocumentAnalyzeStatusById(
      documentId,
      data
    );
    setLoading(true);

    navigate('/student/documentanalysis/dashboard');
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.custom_modal}>
          <DoDisturbOnOutlinedIcon
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h3 className={styles.modal_header}>
            Are you sure you wish to submit this partial Business Plan?
          </h3>
          <p className={styles.modal_description}>
            There are unfixed problems with the document you have submitted.
            Please confirm if you still wish to proceed with the submission
            without correcting them.
          </p>
          <div className={styles.modal_button}>
            <button className={styles.yes} onClick={handleSubmit}>
              Submit Anyway
            </button>
            <button className={styles.no} onClick={() => setOpen(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const SubmitModal = ({ open, setOpen, documentId, setLoading, navigate }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      status: 'coachReview',
    };
    const response = await organizationAPI.updateDocumentAnalyzeStatusById(
      documentId,
      data
    );
    setLoading(true);

    navigate('/student/documentanalysis/dashboard');
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.custom_modal}>
          <DocumentIcon />
          <h3 className={styles.modal_header}>
            Are you sure you wish to submit this Business Plan?
          </h3>
          <p className={styles.modal_description}>
            Please confirm your submission.
          </p>
          <div className={styles.modal_button}>
            <button className={styles.yes_success} onClick={handleSubmit}>
              Yes
            </button>
            <button className={styles.no} onClick={() => setOpen(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const CommentModal = ({
  documentId,
  comment,
  user,
  open,
  setOpen,
  setLoading,
  getDocumentById,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
      schoolName: 'NFTE',
    };

    const response = await organizationAPI.addCommentToDocumentAnalyze(
      data,
      documentId
    );
    await getDocumentById();
    setReply('');
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <div className={styles.bottom_section}>
          <CustomTextField
            label={`Reply`}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            multiline
            className={styles.reply_field}
          />

          <div className={styles.modal_bottom}>
            <button
              disabled={!reply}
              className={styles.reply}
              onClick={handleReply}
            >
              Post Reply
            </button>
            <button className={styles.collapse} onClick={() => setOpen(false)}>
              Collapse Comments
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const NewDocumentAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitAnywayOpen, setSubmitAnywayOpen] = useState(false);
  const navigate = useNavigate();
  const [comment, setComment] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [user, setUser] = useState({});

  const [missingSlides, setMissingSlides] = useState([]);
  const [slides, setSlides] = useState([]);
  const [documentStatus, setDocumentStatus] = useState('');
  const [topicCount, setTopicCount] = useState(0);
  const [correctSequence, setCorrectSequence] = useState(false);
  const [title, setTitle] = useState('');
  const [rubric, setRubric] = useState([]);

  const location = useLocation();
  const documentId = location.pathname.split('/')[4];

  const [active, setActive] = useState([
    { name: 'Analysis View', active: true, status: 'pending' },
    { name: 'Analyzer AI Chat', active: false, status: 'pending' },
  ]);

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/student/documentanalysis/dashboard');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (documentStatus === 'pass') {
      setOpen(true);
    } else {
      setSubmitAnywayOpen(true);
    }
  };

  const getDocumentById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getDocumentAnalyzeById(documentId);
      const answer = response.data.answers.map((item) => {
        setMissingSlides(item.answer.missingTopics);
        setDocumentStatus(item.answer.status);
        setSlides(item.answer.content);
        setCorrectSequence(item.answer.correctSequence);
        setTopicCount(item.answer.topicCount);
        setTitle(item.answer.title);
        setRubric(JSON.parse(item.answer.rubric));
        if (item.answer.rubric) {
          return { ...item, rubric: JSON.parse(item.answer.rubric) };
        }
        return { ...item };
      });
      setComment(response.data.comments);
      console.log(answer);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentById();
  }, []);

  return (
    <MainContent user={user} active={'documentanalysis'}>
      {loading && <LoadingOverlay />}
      <CommentModal
        documentId={documentId}
        comment={comment}
        user={user}
        open={openComment}
        setOpen={setOpenComment}
        setLoading={setLoading}
        getDocumentById={getDocumentById}
      />

      <SubmitAnywayModal
        open={submitAnywayOpen}
        documentId={documentId}
        setOpen={setSubmitAnywayOpen}
        setSubmitOpen={setOpen}
        setLoading={setLoading}
        navigate={navigate}
      />

      <SubmitModal
        open={open}
        setOpen={setOpen}
        setLoading={setLoading}
        documentId={documentId}
        navigate={navigate}
      />

      <div className={styles.header}>
        <h2>Document Analysis</h2>

        <div className={styles.button_section}>
          <button className={styles.comment_button} onClick={handleReturn}>
            <ArrowBackIos />
            Return to Documents
          </button>
          <button
            className={styles.comment_button}
            onClick={() => setOpenComment(true)}
          >
            <CommentOutlinedIcon />
            Comments
          </button>

          <button onClick={handleSubmit}>
            <Done />
            Submit
          </button>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.body}>
        <div className={styles.document_section}>
          {/* <FormControl
            fullWidth
            variant="outlined"
            className={styles.document_type}
          >
            <DocumentType
              value={selectedDocumentType}
              setValue={setSelectedDocumentType}
              input={'SELECT DOCUMENT'}
              label={'SELECTED DOCUMENT'}
            />
          </FormControl> */}
          <div className={styles.document}>
            {/* <Document file={pdfURL}>
                  <Page pageNumber={1} />
                </Document> */}

            {slides.map((item, index) => {
              if (item.content === 'not available') {
                return (
                  <div className={styles.alert_text}>
                    <div className={styles.alert_error} key={index}>
                      <ErrorIcon />
                      <p>
                        Required slide {`"${item.topic}"`} is missing and must
                        be added.
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={styles.success_text} key={index}>
                    <div className={styles.alert_text_content}>
                      <h4>{item.topic}</h4>
                      <p>{item.content}</p>
                    </div>
                  </div>
                );
              }
            })}

            {/* <div className={styles.alert_text}>
              <div className={styles.alert_error}>
                <ErrorIcon />
                <p>
                  This slide is not in the correct order. It should come after
                  ‘Problem/Opportunity’.
                </p>
              </div>
              <div className={styles.alert_text_content}>
                <h4>Value Proposition</h4>
                <p>This is an example of descriptive body text.</p>
                <ul>
                  <li>This is an example of a bullet point.</li>
                  <li>Here, have another example.</li>
                  <li>And another! It’s free!</li>
                </ul>
              </div>
            </div>
            <div className={styles.alert_text}>
              <div className={styles.alert_error}>
                <ErrorIcon />
                <p>
                  This slide is not in the correct order. It should come before
                  ‘Value Proposition’.
                </p>
              </div>
              <div className={styles.alert_error_incomplete}>
                <ReportProblemIcon />
                <p>
                  This slide is incomplete, and must be properly filled out and
                  resubmitted.
                </p>
              </div>
              <div className={styles.alert_text_content}>
                <h4>Problem/Opportunity</h4>
                <p>This is an example of descriptive body text.</p>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.analyzer_section}>
          {/* <div className={styles.course_list}>
                <ul>
                  {active.map((item, index) => (
                    <li
                      key={index}
                      className={item.active ? styles.active : ''}
                      onClick={() => {
                        const newActive = active.map((item, i) => {
                          if (i === index) {
                            return { ...item, active: true };
                          } else {
                            return { ...item, active: false };
                          }
                        });
                        setActive(newActive);
                      }}
                    >
                      {item.name === 'Analysis View' ? (
                        <AnalysisIcon />
                      ) : (
                        <AnalyzerIcon />
                      )}{' '}
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div> */}
          <div className={styles.container}>
            {active.map((item) => {
              if (item.active) {
                if (item.name === 'Analysis View') {
                  return (
                    <StudentAnalysis
                      documentStatus={documentStatus}
                      documentId={documentId}
                      missingSlides={missingSlides}
                      slides={slides}
                      topicCount={topicCount}
                      correctSequence={correctSequence}
                      title={title}
                      rubric={rubric}
                      getDocumentById={getDocumentById}
                    />
                  );
                } else if (item.name === 'Analyzer AI Chat') {
                  return <Analyzer />;
                }
              }
            })}
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default NewDocumentAnalysis;
