import React, { useEffect, useState } from 'react';
import styles from './styles/Profile.module.css';

import { Sidebar, MainContent } from '../../components/sidebar';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import organizationAPI from '../../api/api';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingOverlay from '../../components/LoadingOverlay';
import ErrorOverlay from '../../components/ErrorOverlay';
import { CustomTextField } from 'components/TextField';
import { ToastContainer, toast } from 'react-toastify';
import { InputAdornment, IconButton } from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  ErrorOutline,
  Check,
} from '@mui/icons-material';
import { CustomAutoCompleteForUserList } from 'components/AutoComplete';

const ProfileDetails = () => {
  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [region, setRegion] = useState('');
  const [course, setCourse] = useState('');
  const [avatar, setAvatar] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [organization_id, setOrganization_id] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);
  const [acceptedUsers, setAcceptedUsers] = useState([]);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [userNewPassword, setUserNewPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [showUserNewPassword, setShowUserNewPassword] = useState(false);
  const [showUserConfirmPassword, setShowUserConfirmPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [userPasswordMatch, setUserPasswordMatch] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [ownPasswordDisableButton, setOwnPasswordDisableButton] =
    useState(true);
  const [userPasswordDisableButton, setUserPasswordDisableButton] =
    useState(true);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate(
      `/${await organizationAPI.getOrganizationCodeFromLocalStorage()}/login`
    );
  };

  useEffect(() => {
    if (selectedUser && selectedUser.isGoogleAuth) {
      setUserPasswordMatch(false);
      setErrorMessage(
        'Google Authentication in use for this account. Password cannot be changed.'
      );
      setUserPasswordDisableButton(true);
    } else if (
      selectedUser &&
      selectedUser.isGoogleAuth === false &&
      userNewPassword.newPassword !== userNewPassword.confirmPassword
    ) {
      setUserPasswordMatch(false);
      setErrorMessage('Passwords do not match');
      setUserPasswordDisableButton(true);
    } else if (
      selectedUser &&
      selectedUser.isGoogleAuth === false &&
      userNewPassword.newPassword.length !== 0 &&
      userNewPassword.confirmPassword.length !== 0 &&
      userNewPassword.newPassword === userNewPassword.confirmPassword
    ) {
      setUserPasswordMatch(true);
      setErrorMessage('');
      setUserPasswordDisableButton(false);
    } else {
      setUserPasswordMatch(true);
      setErrorMessage('');
      setUserPasswordDisableButton(true);
    }
  }, [selectedUser, userNewPassword]);

  useEffect(() => {
    if (
      newPassword.newPassword !== '' &&
      newPassword.confirmPassword !== '' &&
      newPassword.newPassword !== newPassword.confirmPassword
    ) {
      setPasswordMatch(false);
      setErrorMessage('Passwords do not match');
      setOwnPasswordDisableButton(true);
    } else if (
      newPassword.newPassword.length !== 0 &&
      newPassword.confirmPassword.length !== 0 &&
      newPassword.newPassword === newPassword.confirmPassword &&
      currentPassword.length !== 0
    ) {
      setPasswordMatch(true);
      setErrorMessage('');
      setOwnPasswordDisableButton(false);
    } else {
      setPasswordMatch(true);
      setErrorMessage('');
      setOwnPasswordDisableButton(true);
    }
  }, [newPassword, currentPassword]);

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getUser();
      setOrganization_id(response.data.organization_id);
      setFirstName(response.data.user.firstName);
      setLastName(response.data.user.lastName);
      setEmail(response.data.user.email);
      setRole(response.data.user.role);
      setRegion(response.data.user.region);
      setCourse(response.data.user.course);
      setAvatar(response.data.user.avatar);
      setUser(response.data.user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
      console.log(error);
    }
    setLoading(false);
  };

  const getAcceptedUsers = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAcceptedUser(organization_id);
      setAcceptedUsers(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleOwnPasswordButton = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const organizationCode =
        await organizationAPI.getOrganizationCodeFromLocalStorage();
      const data = {
        organizationCode: organizationCode,
        email: user.email,
        oldPassword: currentPassword,
        newPassword: newPassword.newPassword,
      };

      const response = await organizationAPI.updateOwnPassword(data);

      if (response.data.success === false) {
        toast.error(response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'ownPasswordChanged',
        });
      } else {
        setCurrentPassword('');
        setNewPassword({ newPassword: '', confirmPassword: '' });
        toast.success('Successfully Password Changed!', {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'ownPasswordChanged',
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleSelectedUserPasswordButton = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const organizationCode =
        await organizationAPI.getOrganizationCodeFromLocalStorage();
      const data = {
        organizationCode: organizationCode,
        userEmail: selectedUser.email,
        password: userNewPassword.newPassword,
      };
      const response = await organizationAPI.updateUserPassword(data);

      if (response.data.success === false) {
        toast.error(response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'userPasswordChanged',
        });
      } else {
        setSelectedUser(null);
        setUserNewPassword({ newPassword: '', confirmPassword: '' });
        toast.success('Successfully Password Changed!', {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'userPasswordChanged',
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  useEffect(() => {
    organization_id && getAcceptedUsers();
  }, [organization_id]);

  return (
    <MainContent user={user} active={'settings'}>
      {loading && <LoadingOverlay />}
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <div className={styles.query__container__header}>
        <h4>Settings</h4>
      </div>
      <div className={styles.border}></div>

      <div className={styles.query__container__body}>
        <div className={styles.details}>
          <h4>Contact Details</h4>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            className={styles.details__form}
          >
            <CustomTextField label="FIRST NAME" value={firstName} disabled />

            <CustomTextField label="LAST NAME" value={lastName} disabled />
            <CustomTextField label="EMAIL ADDRESS" value={email} disabled />
            <CustomTextField label="ROLE" value={role} disabled />

            <CustomTextField label="REGION" value={region} disabled />

            <CustomTextField label="COURSE" value={course} disabled />
          </Box>
        </div>

        <div className={styles.border}></div>
        <div className={styles.details}>
          <h4>Password Settings</h4>
          <div className={styles.border}></div>
          <h4>Change Own Password</h4>
          <p>
            Please note that the new password must have at least one uppercase
            letter, one lowercase letter, one number and one punctuation mark or
            symbol.
          </p>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            className={styles.password_change_form}
          >
            <CustomTextField
              label="CURRENT PASSWORD"
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              label="NEW PASSWORD"
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={newPassword.newPassword}
              onChange={(e) =>
                setNewPassword({
                  ...newPassword,
                  newPassword: e.target.value,
                })
              }
            />
            <CustomTextField
              label="CONFIRM NEW PASSWORD"
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={newPassword.confirmPassword}
              onChange={(e) =>
                setNewPassword({
                  ...newPassword,
                  confirmPassword: e.target.value,
                })
              }
            />
            <button
              className={styles.submit_button}
              disabled={ownPasswordDisableButton}
              onClick={handleOwnPasswordButton}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Check />
                <span>Set New Password</span>
              </div>
            </button>
          </Box>
          {!passwordMatch ? (
            <p className={styles.alert_text}>
              <ErrorOutline />
              <span>{errorMessage}</span>
            </p>
          ) : (
            <></>
          )}
          {user.role === 'ADMIN' && (
            <>
              <div className={styles.border}></div>
              <h4>Set/Reset User Password</h4>
              <p>
                Please note that the new password must have at least one
                uppercase letter, one lowercase letter, one number and one
                punctuation mark or symbol.
              </p>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                className={styles.password_change_form}
              >
                <CustomAutoCompleteForUserList
                  options={acceptedUsers || []}
                  label="USERNAME"
                  placeholder="Search FOR USER"
                  value={selectedUser}
                  onChange={(e, value) => setSelectedUser((state) => value)}
                />

                <CustomTextField
                  label="NEW PASSWORD (OPTIONAL)"
                  type={showUserNewPassword ? 'text' : 'password'}
                  value={userNewPassword.newPassword}
                  onChange={(e) =>
                    setUserNewPassword({
                      ...userNewPassword,
                      newPassword: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowUserNewPassword(!showUserNewPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showUserNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextField
                  label="CONFIRM NEW PASSWORD"
                  type={showUserConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowUserConfirmPassword(!showUserConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showUserConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={userNewPassword.confirmPassword}
                  onChange={(e) =>
                    setUserNewPassword({
                      ...userNewPassword,
                      confirmPassword: e.target.value,
                    })
                  }
                />
                <button
                  className={styles.submit_button}
                  disabled={userPasswordDisableButton}
                  onClick={handleSelectedUserPasswordButton}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Check />
                    <span>Set New Password</span>
                  </div>
                </button>
              </Box>
              {!userPasswordMatch ? (
                <p className={styles.alert_text}>
                  <ErrorOutline />
                  <span>{errorMessage}</span>
                </p>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        <div className={styles.logout_section}>
          <LogoutIcon />
          <a onClick={handleLogout} className={styles.logout}>
            Log Out
          </a>
        </div>
      </div>

      <div className={styles.border}></div>
      <div className={styles.query__container__body}></div>
      <ToastContainer />
    </MainContent>
  );
};

export default ProfileDetails;
