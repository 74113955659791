import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Analysis.module.css';
import { MainContent } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { pdfjs } from 'react-pdf';
import Modal from '@mui/material/Modal';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import LoadingOverlay from 'components/LoadingOverlay';
import ErrorOverlay from 'components/ErrorOverlay';
import { DocumentIcon } from 'svg/CustomSVGIcon';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { Done } from '@mui/icons-material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDropzone } from 'react-dropzone';
import { FormControl, TextField } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { UploadIcon } from 'svg/CustomSVGIcon';
import { set } from 'rsuite/esm/utils/dateUtils';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DragAndDrop = ({ name, setName, onDrop }) => {
  const [files, setFiles] = useState([]);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles(acceptedFiles);
      setName(acceptedFiles[0].name);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <div className={styles.dropBox}>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          color: '#888',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <UploadIcon />
            <p>
              Click to attach your file, or drag and drop it here from your file
              browser. You can upload multiple files simultaneously.
            </p>
          </>
        )}

        {files.length > 0 && (
          <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
            {files.length > 0 && (
              <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
                {files.map((file) => (
                  <li
                    key={file.name}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {name} - {(file.size / (1024 * 1024)).toFixed(2)} mb
                    </div>
                    <div>
                      <CloseOutlinedIcon onClick={() => removeFile(file)} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const SubmitAnywayModal = ({
  open,
  setOpen,
  documentId,
  setLoading,
  navigate,
  getDocumentById,
  setErrorVisible,
}) => {
  const [files, setFiles] = useState([]);
  const [name, setName] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const handleCoachSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      status: 'coachReview',
    };
    const response = await organizationAPI.updateDocumentAnalyzeStatusById(
      documentId,
      data
    );
    setLoading(true);

    navigate('/student/documentanalysis/dashboard');
  };

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    setFiles(acceptedFiles);
  }, []);

  useEffect(() => {
    if (name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name]);

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('ruleId', '665d8524df19cd76fe971ac8');
    formData.append('documentId', documentId);
    formData.append('filename', name);

    try {
      const response = await organizationAPI.documentAnalyzerUploadDocument(
        formData
      );
      // await getDocumentsByUser();
      await getDocumentById();
      setLoading(false);
      setOpen(false);
      setName('');
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    const response = await organizationAPI.documentAnalyzerUploadDocument(
      formData
    );
    // await getDocumentsByUser();
    await getDocumentById();
    setLoading(false);

    setOpen(false);
    setName('');
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_content}>
            <h2>Upload New Document</h2>
            <DragAndDrop name={name} setName={setName} onDrop={handleDrop} />
            <div>
              <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Document Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              {/* <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <SelectResumeStatus
                  input={'STATUS'}
                  label={'STATUS'}
                  value={tags}
                  setValue={setTags}
                />
              </FormControl> */}
            </div>
            <button
              className={styles.start}
              disabled={buttonDisable}
              onClick={handleUploadDocument}
            >
              Confirm and Upload
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const CommentModal = ({
  documentId,
  comment,
  user,
  open,
  setOpen,
  setLoading,
  getDocumentById,
  setErrorVisible,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
      schoolName: organizationAPI.getOrganizationCodeFromLocalStorage(),
    };

    try {
      const response = await organizationAPI.addCommentToDocumentAnalyze(
        data,
        documentId
      );
      await getDocumentById();
      setReply('');
      setLoading(false); 
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <div className={styles.bottom_section}>
          <CustomTextField
            label={`Reply`}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            multiline
            className={styles.reply_field}
          />

          <div className={styles.modal_bottom}>
            <button
              disabled={!reply}
              className={styles.reply}
              onClick={handleReply}
            >
              Post Reply
            </button>
            <button className={styles.collapse} onClick={() => setOpen(false)}>
              Collapse Comments
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const NewDocumentAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const navigate = useNavigate();
  const [comment, setComment] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [user, setUser] = useState({});
  const [status, setStatus] = useState('In Progress');
  const [answer, setAnswer] = useState([]);
  const [openSubmitAnyway, setOpenSubmitAnyway] = useState(false);
  const [documentName, setDocumentName] = useState('');

  const location = useLocation();
  const documentId = location.pathname.split('/')[4];

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/student/resumeanalysis/dashboard');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenSubmitAnyway(true);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  const getDocumentById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getDocumentAnalyzeById(documentId);
      setAnswer(response.data.answers[0].answer);
      setStatus(response.data.status);
      setComment(response.data.comments);
      setDocumentName(response.data.filename);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCoachSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      status: 'coachReview',
    };
    try {
      const response = await organizationAPI.updateDocumentAnalyzeStatusById(
        documentId,
        data
      );
      setLoading(true);
      navigate('/student/resumeanalysis/dashboard');
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentById();
  }, []);

  return (
    <MainContent user={user} active={'resumeanalysis'}>
      {loading && <LoadingOverlay />}
      <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />

      <CommentModal
        documentId={documentId}
        comment={comment}
        user={user}
        open={openComment}
        setOpen={setOpenComment}
        setLoading={setLoading}
        getDocumentById={getDocumentById}
      />
      <SubmitAnywayModal
        open={openSubmitAnyway}
        setOpen={setOpenSubmitAnyway}
        documentId={documentId}
        setLoading={setLoading}
        navigate={navigate}
        getDocumentById={getDocumentById}
        setErrorVisible={setErrorVisible}
      />

      <div className={styles.header}>
        <h2>Document Analysis</h2>

        <div className={styles.button_section}>
          <button className={styles.comment_button} onClick={handleReturn}>
            <ArrowBackIos />
            Back
          </button>
          <button className={styles.comment_button} onClick={handleSubmit}>
            Reupload Documents
          </button>
          <button
            className={styles.comment_button}
            onClick={() => setOpenComment(true)}
          >
            <CommentOutlinedIcon />
            Comments
          </button>

          <button onClick={handleCoachSubmit}>
            <Done />
            Submit Documents
          </button>
          <CustomTextField
            label="Status"
            placeholder="Status"
            value={status}
            disabled
          />
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.body}>
        <h4 className={styles.resume_header}>DOCUMENT ANALYSIS</h4>
        <h3 className={styles.resume_title}>{documentName}</h3>
        <div className={styles.border}></div>
        <div className={styles.resume_body}>
          <ul>
            {answer.map((item, index) => {
              if (item.compliance) {
                return (
                  <li className={styles.resume_success} key={index}>
                    <h4>
                      <CheckCircleOutlineOutlinedIcon /> {item.description}
                      <span>Rule Number </span> {item.ruleNumber}
                    </h4>
                    <p>
                      <span>Compliance Success: </span> The resume follows
                      Product Gym’s established format.
                    </p>
                  </li>
                );
              } else {
                return (
                  <li className={styles.resume_error} key={index}>
                    <h4>
                      <CancelOutlinedIcon /> {item.description}
                      <span>Rule Number </span> {item.ruleNumber}
                    </h4>
                    <p>
                      <span>Compliance Failure: </span> {item.comment}
                    </p>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </MainContent>
  );
};

export default NewDocumentAnalysis;
