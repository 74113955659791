import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Assessment.module.css';
import { MainContent } from 'components/sidebar';
import { Modal, Box, Typography, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import { MultipleSelectDocument } from 'components/SelectField';

import {
  Archive,
  ChevronLeft,
  ChevronRight,
  Delete,
  DoNotDisturb,
  Download,
  Edit,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  RemoveCircleOutline,
  Send,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';
import { CustomTextField } from 'components/TextField';
import Save from '@mui/icons-material/Save';
import Check from '@mui/icons-material/Check';

const RemoveSectionModal = ({
  selectedAssessment,
  open,
  setOpen,
  setLoading,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessment(
        selectedAssessment._id
      );
      await getAssessment();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Section</h5>
          <p>
            Are you sure you want to delete this section? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const CreateModal = ({ open, setOpen, setLoading, navigate }) => {
  const [disableButton, setDisableButton] = useState(true);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const [assessmentName, setAssessmentName] = useState('');

  const createAssessment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      assessmentName,
    };
    try {
      const response = await organizationAPI.createAssessment(data);
      navigate(`/coach/psych/details/${response.data._id}`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    if (assessmentName.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [assessmentName]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h3>Create New Assessment</h3>
        </Typography>

        <div className={styles.modal_buttons}>
          <CustomTextField
            label="Assessment Name"
            placeholder={'Enter Assessment Name'}
            value={assessmentName}
            onChange={(e) => setAssessmentName(e.target.value)}
          />
          <button
            onClick={createAssessment}
            className={styles.create}
            disabled={disableButton}
          >
            Start Now
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const assessmentId = location.pathname.split('/')[4];

  const [user, setUser] = useState(null);

  const [assessment, setAssessment] = useState(null);
  const [currentQuestionPage, setCurrentQuestionPage] = useState(0);

  const handleAnswerSelection = async (questionId, answer) => {
    const data = {
      questionId: questionId,
      mcqSelection: answer,
    };

    try {
      const response = await organizationAPI.submitAssessmentEvaluationAnswer(
        assessment.evaluationId,
        data
      );
      toast.success('Answer saved successfully');
    } catch (error) {
      console.log(error);
      toast.error('Error saving answer');
    }
  };

  const getAssessmentEvaluation = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getStudentAssessmentEvaluation(
        assessmentId
      );
      setAssessment(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    const response = await organizationAPI.updateAssessmentEvaluation(
      assessment.evaluationId,
      {
        status: 'completed',
      }
    );
    handleAssessmentNavigation(assessment.evaluationId);
    setLoading(false);
  };

  const handleAssessmentNavigation = (id) => {
    navigate(`/student/psych/assessment/${id}/success`);
  };

  useEffect(() => {
    getMe();
    getAssessmentEvaluation();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>{assessment?.assessmentName}</h1>

            {/* <div className={styles.learner_header_buttons}>
              <button className={styles.discard}>
                <Save />
                Save and Exit
              </button>
              <button
                className={styles.save}
                onClick={() => handleAssessmentNavigation('123')}
              >
                <Check />
                Save Progress
              </button>
              <button className={styles.abort}>
                <DoNotDisturb />
                Abort and Return to Assessments
              </button>
            </div> */}
          </div>
          <div className={styles.border}></div>

          <div className={styles.coach_roaster}>
            <div className={styles.question_button_header}>
              <button
                className={styles.discard}
                disabled={currentQuestionPage === 0}
                onClick={() => setCurrentQuestionPage(currentQuestionPage - 1)}
              >
                <ChevronLeft />
                Previous Question
              </button>

              <p>{`${currentQuestionPage + 1} of ${
                assessment?.questions.length
              } answered`}</p>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((currentQuestionPage + 1) / assessment?.questions.length) *
                    100
                  }
                />
              </Box>
              {assessment?.status !== 'completed' &&
              currentQuestionPage === assessment?.questions.length - 1 ? (
                <button className={styles.discard} onClick={handleSubmit}>
                  <ChevronRight />
                  Submit
                </button>
              ) : (
                <button
                  className={styles.discard}
                  onClick={() =>
                    setCurrentQuestionPage(currentQuestionPage + 1)
                  }
                  disabled={
                    currentQuestionPage === assessment?.questions.length - 1
                  }
                >
                  <ChevronRight />
                  Skip to Next Question
                </button>
              )}
            </div>

            <div className={styles.border}></div>

            <div className={styles.question_header}>
              {/* <h3>
                {assessment?.questions.length > 0 &&
                  assessment?.questions[currentQuestionPage].sectionName}
              </h3> */}
              <h5>
                {assessment?.questions.length > 0 &&
                  `${currentQuestionPage + 1}. ${
                    assessment?.questions[currentQuestionPage].questionName
                  }`}
              </h5>
            </div>

            <div className={styles.question_body}>
              <p
                className={
                  assessment?.questions.length > 0 &&
                  assessment.questions[currentQuestionPage].options[0] ===
                    assessment.questions[currentQuestionPage]?.selectedAnswer &&
                  styles.selectedQuestion
                }
                onClick={() => {
                  if (assessment?.status !== 'completed') {
                    handleAnswerSelection(
                      assessment.questions[currentQuestionPage].questionId,
                      assessment.questions[currentQuestionPage].options[0]
                    );
                    const newAssessment = { ...assessment };
                    newAssessment.questions[
                      currentQuestionPage
                    ].selectedAnswer =
                      assessment.questions[currentQuestionPage].options[0];
                    setAssessment(newAssessment);
                    if (currentQuestionPage < assessment.questions.length - 1) {
                      setCurrentQuestionPage(currentQuestionPage + 1);
                    }
                  }
                }}
              >
                {assessment?.questions.length > 0 &&
                  `A. ${assessment?.questions[currentQuestionPage].options[0]}`}
              </p>
              <p
                className={
                  assessment?.questions.length > 0 &&
                  assessment.questions[currentQuestionPage].options[1] ===
                    assessment.questions[currentQuestionPage]?.selectedAnswer &&
                  styles.selectedQuestion
                }
                onClick={() => {
                  if (assessment?.status !== 'completed') {
                    handleAnswerSelection(
                      assessment.questions[currentQuestionPage].questionId,
                      assessment.questions[currentQuestionPage].options[1]
                    );
                    const newAssessment = { ...assessment };
                    newAssessment.questions[
                      currentQuestionPage
                    ].selectedAnswer =
                      assessment.questions[currentQuestionPage].options[1];
                    setAssessment(newAssessment);
                    if (currentQuestionPage < assessment.questions.length - 1) {
                      setCurrentQuestionPage(currentQuestionPage + 1);
                    }
                  }
                }}
              >
                {assessment?.questions.length > 0 &&
                  `B. ${assessment?.questions[currentQuestionPage].options[1]}`}
              </p>

              <p
                className={
                  assessment?.questions.length > 0 &&
                  assessment.questions[currentQuestionPage].options[2] ===
                    assessment.questions[currentQuestionPage]?.selectedAnswer &&
                  styles.selectedQuestion
                }
                onClick={() => {
                  if (assessment?.status !== 'completed') {
                    handleAnswerSelection(
                      assessment.questions[currentQuestionPage].questionId,
                      assessment.questions[currentQuestionPage].options[2]
                    );
                    const newAssessment = { ...assessment };
                    newAssessment.questions[
                      currentQuestionPage
                    ].selectedAnswer =
                      assessment.questions[currentQuestionPage].options[2];
                    setAssessment(newAssessment);
                    if (currentQuestionPage < assessment.questions.length - 1) {
                      setCurrentQuestionPage(currentQuestionPage + 1);
                    }
                  }
                }}
              >
                {assessment?.questions.length > 0 &&
                  `C. ${assessment?.questions[currentQuestionPage].options[2]}`}
              </p>

              <p
                className={
                  assessment?.questions.length > 0 &&
                  assessment.questions[currentQuestionPage].options[3] ===
                    assessment.questions[currentQuestionPage]?.selectedAnswer &&
                  styles.selectedQuestion
                }
                onClick={() => {
                  if (assessment?.status !== 'completed') {
                    handleAnswerSelection(
                      assessment.questions[currentQuestionPage].questionId,
                      assessment.questions[currentQuestionPage].options[3]
                    );
                    const newAssessment = { ...assessment };
                    newAssessment.questions[
                      currentQuestionPage
                    ].selectedAnswer =
                      assessment.questions[currentQuestionPage].options[3];
                    setAssessment(newAssessment);
                    if (currentQuestionPage < assessment.questions.length - 1) {
                      setCurrentQuestionPage(currentQuestionPage + 1);
                    }
                  }
                }}
              >
                {assessment?.questions.length > 0 &&
                  `D. ${assessment?.questions[currentQuestionPage].options[3]}`}
              </p>
            </div>
          </div>
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
