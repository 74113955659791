import React, { useEffect, useState } from 'react';
import styles from '../styles/Business.module.css';
import { MainContent } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import {
  PendingIcon,
  ProgressIcon,
  DoneIcon,
  AddCollaboratorIcon,
  SummaryIcon,
} from 'svg/CustomSVGIcon';
import NFTEAIFeedbackCard from './NFTEAIFeedbackCard';
import ProblemCard from './ProblemCard';
import SolutionCard from './SolutionCard';
import MetricsCard from './Metrics';
import PropositionsCard from './Propositions';
import AdvantageCard from './Advantage';
import ChannelCard from './Channel';
import SegmentsCard from './Segments';
import CostCard from './Cost';
import RevenueCard from './Revenue';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Person from 'images/person.png';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import LoadingOverlay from 'components/LoadingOverlay';
import organizationAPI from 'api/api';
import { CloseOutlined } from '@mui/icons-material';
import {
  CustomTextField,
  inputLabelStyle,
  inputStyle,
  textStyle,
} from 'components/TextField';
import { CustomButton } from 'components/Button';
import { assign } from 'lodash';
import { KeyboardArrowRight, PictureAsPdf } from '@mui/icons-material';

import {
  Document,
  Page,
  PDFDownloadLink,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import ErrorOverlay from 'components/ErrorOverlay';

const SummarizePDF = ({ content }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text1: {
      fontSize: 21,
      marginBottom: 10,
    },
    text2: {
      fontSize: 16,
      marginBottom: 10,
    },
  });
  return (
    <Document>
      {content &&
        content.length > 0 &&
        content.map((item) => {
          return (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.text1}>{item.name}</Text>
                <Text style={styles.text2}>{item.summary}</Text>
              </View>
            </Page>
          );
        })}
    </Document>
  );
};

const SummaryModal = ({ open, setOpen, summary, fileName }) => {
  const style = {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    width: 500,
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.summary_modal_header}>
          <h6>Lean Canvas Summary</h6>
          <button onClick={() => setOpen(false)}>
            COLLAPSE SUMMARY <KeyboardArrowRight />
          </button>
        </div>

        <div className={styles.summary_modal_export}>
          <h3>Lean Canvas Summary</h3>

          <button>
            <PDFDownloadLink
              style={{ textDecoration: 'none' }}
              document={<SummarizePDF content={summary} />}
              fileName={`${fileName}_summarize.pdf`}
            >
              <PictureAsPdf />
              Export PDF
            </PDFDownloadLink>
          </button>
        </div>

        <div className={styles.summary_modal_body}>
          {summary &&
            summary.map((item) => {
              return (
                <div className={styles.summary_modal_body_item}>
                  <h4>{item.name}</h4>
                  <p>{item.summary}</p>
                </div>
              );
            })}
        </div>
      </Box>
    </Modal>
  );
};

const CommentModal = ({
  canvasId,
  comment,
  user,
  assignees,
  open,
  handleClose,
  setLoading,
  getCanvas,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      avatar: user.avatar,
      role: user.role,
      recipientList: assignees,
      schoolName: 'NFTE',
    };

    const response = await organizationAPI.addCommentToCanvas(data, canvasId);
    const canvasResponse = getCanvas();
    setReply('');
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>

        <CustomTextField
          label="Reply"
          multiline
          className={styles.reply_field}
          value={reply}
          onChange={(e) => setReply(e.target.value)}
        />

        <div className={styles.modal_bottom}>
          <button
            disabled={!reply}
            className={styles.reply}
            onClick={handleReply}
          >
            Post Reply
          </button>
          <button className={styles.collapse} onClick={handleClose}>
            Collapse Comments
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const OverallScore = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
  user,
  gradeId,
  tileId,
  setLoading,
  getCanvas,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      coachId: user._id,
      coachName: user.firstName + ' ' + user.lastName,
      tileId: tileId,
      gradingId: gradeId,
      filedsToUpdate: {},
    };

    if (justification !== justificationText) {
      data.filedsToUpdate.justification = justification;
    }

    if (feedback !== feedbackText) {
      data.filedsToUpdate.feedback = feedback;
    }

    if (score !== scoreVal) {
      data.filedsToUpdate.score = score;
    }

    const response = await organizationAPI.updateCanvasTile(data);
    getCanvas();
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>Total Score</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button className={styles.save} onClick={handleSave}>
              Save and Return to Scorecard
            </button>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={false}
          />
        </div>
      </Box>
    </Modal>
  );
};

const EditModalScoreSection = ({
  subtitle,
  justification,
  setJustification,
  feedback,
  setFeedback,
  score,
  setScore,
  disabledInput,
}) => {
  return (
    <div className={styles.edit_modal_body}>
      <div className={styles.scorecard_header}>
        <span>ANSWER </span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{subtitle}</h4>
        <h4>
          <input
            className={styles.score}
            value={score}
            onChange={(e) => setScore(e.target.value)}
            disabled={disabledInput}
          />
          / 4
        </h4>
      </div>
      <div className={styles.progress}>
        <LinearProgress variant="determinate" value={50} />
      </div>

      <CustomTextField
        multiline
        className={styles.edit_textfield}
        label={`JUSTIFICATION`}
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
        disabled={disabledInput}
      />
      <CustomTextField
        multiline
        className={styles.edit_textfield}
        label={`FEEDBACK`}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        disabled={disabledInput}
      />
    </div>
  );
};

const ViewModal = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>NFTE AI Scorecard</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={true}
          />
        </div>
      </Box>
    </Modal>
  );
};

const AddCollaboratorModal = ({
  open,
  setOpen,
  studentList,
  additionalMember,
  user,
  setLoading,
  getCanvas,
  getAllStudent,
  canvasId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
  };

  const [collaboratorList, setCollaboratorList] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState([]);

  useEffect(() => {
    const temp = studentList
      .filter((item) => item.id !== user._id)
      .filter(
        (item) => !additionalMember.find((member) => member.userId === item.id)
      )
      .map((item) => {
        return {
          name: `${item.firstName} ${item.lastName}`,
          userId: item.id,
          email: item.email,
          role: item.role,
        };
      });
    setCollaboratorList(temp);
  }, [studentList]);

  const handleAddCollaborator = async () => {
    setLoading(true);
    const data = selectedCollaborator;

    const response = await organizationAPI.addCollaboratorToCanvas(
      data,
      canvasId
    );
    getCanvas();
    getAllStudent();
    setSelectedCollaborator([]);
    setLoading(false);
  };

  const handleDeleteCollaborator = async (userId) => {
    setLoading(true);

    const response = await organizationAPI.deleteCollaboratorToCanvas(
      canvasId,
      userId
    );
    getCanvas();
    getAllStudent();
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={styles.modalInviteCollaborator}>
        <div className={styles.inviteCollaboratorHeader}>
          <h1>Invite Collaborators</h1>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <p>Current Collaborators</p>
        <div className={styles.currentUserList}>
          <ul>
            {
              <li>
                <p className={styles.currentUser}>
                  {user.firstName} {user.lastName}
                </p>
              </li>
            }
            {additionalMember &&
              additionalMember.map((item) => (
                <li>
                  <p className={styles.currentUser}>{item.name}</p>
                  <CloseOutlined
                    onClick={() => handleDeleteCollaborator(item.userId)}
                  />
                </li>
              ))}
          </ul>
        </div>

        <div className={styles.inviteCollaborator}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={collaboratorList}
            className={styles.autocomplete}
            getOptionLabel={(option) => option?.name}
            // defaultValue={[top100Films[13]]}
            filterSelectedOptions
            value={selectedCollaborator}
            inputValue={selectedCollaborator?.name}
            onChange={(e, value) => {
              setSelectedCollaborator(value);
            }}
            renderInput={(params) => (
              <TextField
                sx={textStyle}
                inputProps={inputStyle}
                InputLabelProps={inputLabelStyle}
                label="ADD NEW COLLABORATORS"
                placeholder="Search by name"
                {...params}
              />
            )}
          />

          <CustomButton
            onClick={() => handleAddCollaborator()}
            className={styles.inviteButton}
          >
            Add Personnel
          </CustomButton>
        </div>
      </Box>
    </Modal>
  );
};

const Scrorecard = ({
  gradeId,
  title,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  return (
    <div className={styles.scorecard}>
      <ViewModal
        subtitle={title}
        open={viewModal}
        setOpen={setViewModal}
        justificationText={gradeList.justification}
        feedbackText={gradeList.feedback}
        scoreVal={gradeList.score}
      />
      <div className={styles.scorecard_header}>
        <span>TOPIC</span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{gradeList.name}</h4>
        <h4>{`${gradeList.score} / ${title === 'overall' ? 36 : 4}`}</h4>
      </div>
      <div className={styles.progress}>
        {title === 'overall' ? (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 36}
          />
        ) : (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 4}
          />
        )}
      </div>
      <div className={styles.scorecard_bottom}>
        <a
          onClick={() => {
            setViewModal(true);
          }}
        >
          <span>View Details</span>
          <ArrowForwardIosIcon />
        </a>
      </div>
    </div>
  );
};

const AIFeedbackModal = ({
  open,
  setOpen,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [overall, setOverall] = useState(false);
  const [iterationId, setIterationId] = useState(null);
  const [active, setActive] = useState('scorecard');

  useEffect(() => {
    if (gradeList) {
      setIterationId(gradeList[0]._id);
    }
  }, [gradeList]);

  // const [feedbackList, setFeedbackList] = useState([
  //   'This is an example of AI-generated feedback from the NFTE AI. It can be regenerated by clicking the button above.',
  //   'Each regeneration of the feedback creates its own card in this scrollable stack.',
  //   'Individual regenerations can be starred (to be moved to the top of the stack) or deleted.',
  // ]);
  // const deleteFeedback = (index) => {
  //   const tempProblemList = [...feedbackList];
  //   tempProblemList.splice(index, 1);
  //   setFeedbackList(tempProblemList);
  // };

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.ai_modal}>
        <div className={styles.ai_modal_top}>
          <button onClick={() => setOpen(false)} className={styles.return}>
            Return to Canvas
          </button>
          <Box
            className={styles.filter_menu}
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{}} className={styles.drop_down}>
              <select
                className={styles.select}
                value={iterationId}
                onChange={(e) => setIterationId(e.target.value)}
              >
                {gradeList &&
                  gradeList.map((item) => (
                    <option value={item._id}>
                      {convertDate(item.createdAt)}
                    </option>
                  ))}
              </select>
              <ArrowDropDownIcon />
            </FormControl>
          </Box>
        </div>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5
              className={active === 'scorecard' && styles.modal_header_card}
              onClick={() => setActive('scorecard')}
            >
              NFTE AI Scorecard
            </h5>
          </div>
          <div className={styles.modal_header_left}>
            <h5
              className={active === 'activity' && styles.modal_header_card}
              onClick={() => setActive('activity')}
            >
              Activity Log
            </h5>
          </div>
        </div>
        {active === 'scorecard' && (
          <>
            {gradeList.length > 0 &&
              gradeList.map((item) => {
                if (item._id === iterationId) {
                  return (
                    <>
                      <div className={styles.ai_body}>
                        <div className={styles.scorecard_section}>
                          {item.tiles.map((tile) => {
                            if (tile.name !== 'overall')
                              return (
                                <Scrorecard
                                  gradeId={item._id}
                                  title={tile.name}
                                  gradeList={tile}
                                  user={user}
                                  setLoading={setLoading}
                                  getCanvas={getCanvas}
                                />
                              );
                          })}
                        </div>
                      </div>

                      {item.tiles.map((tile) => {
                        if (tile.name === 'overall') {
                          return (
                            <div className={styles.total}>
                              <OverallScore
                                subtitle={tile.name}
                                justificationText={tile.justification}
                                feedbackText={tile.feedback}
                                open={overall}
                                setOpen={setOverall}
                                scoreVal={tile.score}
                                user={user}
                                gradeId={item._id}
                                tileId={tile.tile_id}
                                setLoading={setLoading}
                                getCanvas={getCanvas}
                              />
                              <div className={styles.top}>
                                <div className={styles.top_left}>
                                  <p>Total Score </p>
                                  <a
                                    onClick={() => {
                                      setOverall(true);
                                    }}
                                  >
                                    <span>View Details</span>
                                    <ArrowForwardIosIcon />
                                  </a>
                                </div>
                                <h4>{tile.score} / 36</h4>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={(tile.score * 100) / 36}
                                color="success"
                              />
                            </div>
                          );
                        }
                      })}
                    </>
                  );
                }
              })}
          </>
        )}

        {active === 'activity' && (
          <>
            <div className={styles.activity_log_section}>
              <ul>
                {gradeList &&
                  gradeList.map((item) => {
                    if (item._id === iterationId) {
                      return (
                        <>
                          {item.activities.map((activity) => (
                            <li>
                              <div>
                                <p className={styles.date}>
                                  {convertDate(activity.modifiedAt)}
                                </p>
                                <p className={styles.activity}>
                                  <span className={styles.user}>
                                    {activity.coach_name}{' '}
                                  </span>
                                  modified the canvas
                                </p>
                              </div>
                              <div className={styles.activity_log_border}></div>
                            </li>
                          ))}
                        </>
                      );
                    }
                  })}
              </ul>
            </div>
          </>
        )}
        {/* <div>
          {feedbackList.map((feedback, index) => (
            <NFTEAIFeedback
              feedbackText={feedback}
              deleteFeedback={deleteFeedback}
              index={index}
            />
          ))}

          <div className={styles.generate}>
            <button>
              <FeedbackOutlinedIcon /> Generate New Feedback
            </button>
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

const Business = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [collaboratorOpen, setCollaboratorOpen] = useState(false);
  const location = useLocation();
  const canvasId = location.pathname.split('/')[4];
  const [canvas, setCanvas] = useState(null);
  const [workflowList, setWorkflowList] = useState([]);
  const [canvasStatus, setCanvasStatus] = useState('');
  const [comment, setComment] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [additionalMember, setAdditionalMember] = useState([]);

  const [openAIModal, setOpenAIModal] = useState(false);
  const [gradeList, setGradeList] = useState([]);

  const [summaryEnable, setSummaryEnable] = useState(false);

  const [summary, setSummary] = useState([]);
  const [summaryOpen, setSummaryOpen] = useState(false);

  const getCanvas = async () => {
    setLoading(true);
    const response = await organizationAPI.getIndividualCanvas(canvasId);
    setCanvas(response.data);
    setWorkflowList(response.data.workflow);
    setCanvasStatus(response.data.status);
    setComment(response.data.comments);
    setAdditionalMember(response.data.additionalMembers);

    const gradingResponse =
      await organizationAPI.getGradingAIForIndividualCanvas(canvasId);
    setGradeList(gradingResponse.data.reverse());
    setLoading(false);
  };

  const getSummary = async () => {
    setLoading(true);
    const response = await organizationAPI.getSummaryByCanvasId(canvasId);
    setSummary(response?.data[0]?.summaries);
    setLoading(false);
  };

  const getAllStudent = async () => {
    const studentResponse = await organizationAPI.getAllCanvasStudent();
    const tempStudentList = studentResponse.data.map(
      (item) => item.course === user.course && item
    );

    setStudentList(tempStudentList);
  };

  const createSummary = async () => {
    setLoading(true);
    const data = {
      canvasId,
    };
    const response = await organizationAPI.createSummary(data);
    await getSummary();

    setLoading(false);
  };

  const getProfile = async () => {
    setLoading(true);
    const response = await organizationAPI.getUser();
    setUser(response.data.user);
    setAssignees(response.data.assignees);
    setLoading(false);
  };

  useEffect(() => {
    if (user) getAllStudent();
  }, [user]);

  useEffect(() => {
    getProfile();

    getCanvas();
    getSummary();
  }, []);

  const [active, setActive] = useState([
    { name: 'Problems', active: true, status: 'pending' },
    { name: 'Solutions', active: false, status: 'pending' },
    { name: 'Key Metrics', active: false, status: 'pending' },
    { name: 'Unique Value Propositions', active: false, status: 'pending' },
    { name: 'Unfair Advantage', active: false, status: 'pending' },
    { name: 'Channels', active: false, status: 'pending' },
    { name: 'Customer Segments', active: false, status: 'pending' },
    { name: 'Cost Structure', active: false, status: 'pending' },
    { name: 'Revenue Streams', active: false, status: 'pending' },
  ]);

  useEffect(() => {
    const newActive = active.map((item, index) => {
      if (item.name === 'Problems') {
        if (workflowList.filter((item) => item.type === 'problem').length === 3)
          return { ...item, status: 'done' };
        else if (
          workflowList.filter((item) => item.type === 'problem').length === 0
        )
          return { ...item, status: 'pending' };
        else return { ...item, status: 'progress' };
      } else if (item.name === 'Solutions') {
        if (
          workflowList.filter((item) => item.type === 'solution').length === 3
        )
          return { ...item, status: 'done' };
        else if (
          workflowList.filter((item) => item.type === 'solution').length === 0
        )
          return { ...item, status: 'pending' };
        else return { ...item, status: 'progress' };
      } else if (item.name === 'Key Metrics') {
        if (workflowList.filter((item) => item.type === 'metrics').length > 0)
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Unique Value Propositions') {
        if (
          workflowList.filter((item) => item.type === 'uniqueValue').length > 0
        )
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Unfair Advantage') {
        if (
          workflowList.filter((item) => item.type === 'unfairAdvantage')
            .length > 0
        )
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Channels') {
        if (workflowList.filter((item) => item.type === 'channels').length > 0)
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Customer Segments') {
        if (
          workflowList.filter((item) => item.type === 'customerSegment')
            .length > 0
        )
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Cost Structure') {
        if (
          workflowList.filter((item) => item.type === 'costStructure').length >
          0
        )
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      } else if (item.name === 'Revenue Streams') {
        if (
          workflowList.filter((item) => item.type === 'revenueStream').length >
          0
        )
          return { ...item, status: 'done' };
        else return { ...item, status: 'pending' };
      }
    });
    setActive(newActive);
  }, [workflowList]);

  useEffect(() => {
    const total = active.filter((item) => item.status === 'done').length;
    if (total === 9) {
      setSummaryEnable(true);
    } else {
      setSummaryEnable(false);
    }
  }, [active]);

  const handleSummary = async () => {
    if (summary === undefined || summary.length === 0) {
      createSummary();
      setSummaryOpen(true);
    } else {
      setSummaryOpen(true);
    }
  };

  const handleArchive = async () => {
    setLoading(true);

    const status = canvasStatus === 'archived' ? 'progress' : 'archived';

    try {
      await organizationAPI.updateCanvasStatus(canvasId, status);
      getCanvas();
    } catch (error) {
      setErrorVisible(true);
    }
    navigate('/student/learner/canvas');
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const checkRequiredTypes = (actualTypes, requiredTypes) =>
      requiredTypes.every((requiredType) =>
        actualTypes.some((actualType) => actualType.type === requiredType)
      );

    const requiredTypes = [
      'problem',
      'solution',
      'metrics',
      'uniqueValue',
      'unfairAdvantage',
      'channels',
      'customerSegment',
      'costStructure',
      'revenueStream',
    ];

    const isAllRequiredTypesExist = checkRequiredTypes(
      workflowList,
      requiredTypes
    );

    try {
      if (isAllRequiredTypesExist) {
        const data = {
          canvasId,
        };
        //Run the Grading AI
        const response = await organizationAPI.submitDraft(data);
      }

      const canvasStatusResponse = await organizationAPI.updateCanvasStatus(
        canvasId,
        'review'
      );
      getCanvas();
      navigate('/student/learner/canvas');
    } catch (error) {
      setErrorVisible(true);
    }
    setLoading(false);
  };

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  return (
    <MainContent user={user} active={'canvas'}>
      {loading && <LoadingOverlay />}
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      {user && (
        <AddCollaboratorModal
          open={collaboratorOpen}
          setOpen={setCollaboratorOpen}
          studentList={studentList}
          additionalMember={additionalMember}
          user={user}
          setLoading={setLoading}
          getCanvas={getCanvas}
          getAllStudent={getAllStudent}
          canvasId={canvasId}
        />
      )}
      {gradeList.length > 0 && (
        <AIFeedbackModal
          open={openAIModal}
          gradeList={gradeList}
          setOpen={setOpenAIModal}
          user={user}
          setLoading={setLoading}
          getCanvas={getCanvas}
        />
      )}

      <SummaryModal
        open={summaryOpen}
        setOpen={setSummaryOpen}
        summary={summary}
        fileName={canvas?.canvasName}
      />

      <div className={styles.header}>
        <h1>{canvas && canvas.canvasName}</h1>

        <div className={styles.header_buttons}>
          <CustomTextField
            label={`Canvas Status`}
            disabled
            value={canvasStatus.toUpperCase()}
          />

          <button onClick={handleArchive} className={styles.comments}>
            {canvasStatus === 'archived' ? (
              <UnarchiveOutlinedIcon />
            ) : (
              <ArchiveOutlinedIcon />
            )}
          </button>
          <button onClick={handleOpen} className={styles.comments}>
            <CommentOutlinedIcon /> Comments
          </button>

          <button
            onClick={() => setCollaboratorOpen(true)}
            className={styles.comments}
          >
            <AddCollaboratorIcon /> Add Collaborator
          </button>

          <CommentModal
            canvasId={canvasId}
            comment={comment}
            user={user}
            assignees={assignees}
            open={open}
            handleClose={handleClose}
            setLoading={setLoading}
            getCanvas={getCanvas}
          />
          {gradeList.length > 0 && (
            <button
              onClick={() => setOpenAIModal(true)}
              className={styles.feedback}
            >
              <FeedbackOutlinedIcon />
              Coach Feedback
            </button>
          )}
          {summaryEnable && (
            <button className={styles.feedback} onClick={() => handleSummary()}>
              <SummaryIcon /> Summarize
            </button>
          )}

          <button
            className={styles.next}
            disabled={!summaryEnable}
            onClick={handleSubmit}
          >
            Submit Draft <NavigateNextOutlinedIcon />
          </button>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.course_list}>
        <ul>
          {active.map((item, index) => (
            <li
              key={index}
              className={item.active ? styles.active : ''}
              onClick={() => {
                const newActive = active.map((item, i) => {
                  if (i === index) {
                    return { ...item, active: true };
                  } else {
                    return { ...item, active: false };
                  }
                });
                setActive(newActive);
              }}
            >
              {item.status === 'pending' ? (
                <PendingIcon />
              ) : item.status === 'progress' ? (
                <ProgressIcon fill={'#0AA6E0'} />
              ) : (
                <DoneIcon />
              )}
              {item.name}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.all}>
        <div className={styles.container}>
          {active.map((item) => {
            if (item.active) {
              if (item.name === 'Problems') {
                return (
                  <ProblemCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                    summary={summary}
                    createSummary={createSummary}
                  />
                );
              } else if (item.name === 'Solutions') {
                return (
                  <SolutionCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Key Metrics') {
                return (
                  <MetricsCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Unique Value Propositions') {
                return (
                  <PropositionsCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Unfair Advantage') {
                return (
                  <AdvantageCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Channels') {
                return (
                  <ChannelCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Customer Segments') {
                return (
                  <SegmentsCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Cost Structure') {
                return (
                  <CostCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              } else if (item.name === 'Revenue Streams') {
                return (
                  <RevenueCard
                    canvasId={canvasId}
                    workflowList={workflowList}
                    getCanvas={getCanvas}
                  />
                );
              }
            }
          })}
        </div>
        {/* <div className={styles.card}>
              <NFTEAIFeedbackCard />
            </div> */}
      </div>
    </MainContent>
  );
};

export default Business;
