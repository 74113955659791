const ErrorOverlay = ({ showOverlay, onClose }) => {
  if (!showOverlay) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '36px 48px',
          gap: '24px',
          position: 'absolute',
          width: '416px',
          height: '212px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#FFF',
          border: '1px solid #E7E7ED',
          borderRadius: '8px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          {/* Your custom loading image */}
        </div>
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '32px',
              textAlign: 'center',
              letterSpacing: '0.2px',
              color: '#141418',
            }}
          >
            Server Error!
          </span>
          <p
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              letterSpacing: '0.2px',
              color: '#88888F',
            }}
          >
            There is a server error. Either something is wrong with your request
            or the server is unable to resolve your chat. Please contact
            support.
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 16px',
              gap: '2px',
              width: '73px',
              height: '36px',
              background: '#4C00EE',
              borderRadius: '4px',
              marginLeft: '38%',
              marginTop: '10%',
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            <span
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#FFFFFF',
              }}
            >
              Close
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorOverlay;
