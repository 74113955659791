import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Candidate.module.css';
import { MainContent } from 'components/sidebar';
import { Modal, Box, Typography, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import { MultipleSelectDocument } from 'components/SelectField';

import {
  Archive,
  Check,
  ChevronLeft,
  Delete,
  Download,
  Edit,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  RemoveCircleOutline,
  Send,
  Summarize,
  Visibility,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';
import { CustomTextField } from 'components/TextField';
import { CustomAutoCompleteForUserList } from 'components/AutoComplete';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [regionalBreakdown, setRegionalBreakdown] = useState(null);
  const [collapseDropdown, setCollapseDropdown] = useState(null);

  const location = useLocation();
  const assessmentId = location.pathname.split('/')[4];

  const handleAssessmentSummaryNavigation = (e, userId) => {
    e.preventDefault();

    navigate(`/coach/psych/assessmentsummary/${userId}/${assessmentId}`);
  };

  const handleClassSummaryNavigation = (e, courseId) => {
    e.preventDefault();

    navigate(`/coach/psych/classsummary/${courseId}/${assessmentId}`);
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userId: selectedUser._id,
      assessmentId,
    };
    console.log(data);
    try {
      const response = await organizationAPI.addUserToAssessmentEvaluation(
        data
      );
      getRegionalBreakdown();
      toast.success('Invite sent successfully');
    } catch (error) {
      toast.error('Error sending invite');
    }
    setLoading(false);
    setSelectedUser(null);
  };

  const getAssessmentUserList = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentUserList();
      setUserList(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getRegionalBreakdown = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getRegionalBreakdown(assessmentId);
      // set collapseDropdown to only true for the first region and false for the rest [true, false, false, ...]
      const temp = [];
      Object.keys(response.data).forEach((region, index) => {
        temp.push(index === 0);
      });

      setCollapseDropdown(temp);
      setRegionalBreakdown(response.data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getRegionalBreakdown();
    getAssessmentUserList();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>{assessment?.assessmentName}</h1>

            <div className={styles.learner_header_buttons}>
              <button
                className={styles.back}
                onClick={() => {
                  navigate('/coach/psych/dashboard');
                }}
              >
                <ChevronLeft />
                Return to Assessments
              </button>
              <button className={styles.summarize_button}>
                <Summarize />
                Assessment Summary
              </button>
            </div>
          </div>
          <div className={styles.border}></div>

          <div className={styles.coach_roaster}>
            <div className={styles.add_audience_section}>
              <h2>Add Assessment Audience</h2>
              <div className={styles.add_audience_input}>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <CustomAutoCompleteForUserList
                    options={userList || []}
                    label="Search for a user"
                    placeholder="Search for a user"
                    value={selectedUser}
                    onChange={(e, value) => setSelectedUser((state) => value)}
                  />
                </div>

                <button onClick={handleSendInvite}>
                  <Check />
                  Add
                </button>
              </div>
              <div className={styles.border}></div>
            </div>

            <div className={styles.candidate_table}>
              <div className={styles.table_header}>
                <div>
                  <p>Candidates</p>
                </div>
                <div>
                  <p>Region</p>
                </div>
                <div>
                  <p>School</p>
                </div>
                {/* <div>
                  <p>Score</p>
                </div> */}
                <div>
                  <p>Result Bracket</p>
                </div>
              </div>
              <div className={styles.border}></div>
              {regionalBreakdown &&
                Object.keys(regionalBreakdown).map((region, index) => (
                  <div className={styles.course_group}>
                    <div className={styles.course_group_header}>
                      <div className={styles.course_group_header_p}>
                        <p>{region}</p>
                      </div>
                      <div className={styles.course_group_space}></div>
                      <div className={styles.course_summary_section}>
                        <p
                          onClick={(e) => {
                            handleClassSummaryNavigation(e, region);
                          }}
                        >
                          <Visibility /> View Class Summary
                        </p>
                        <p
                          onClick={() => {
                            const temp = [...collapseDropdown];
                            temp[index] = !temp[index];
                            setCollapseDropdown(temp);
                          }}
                        >
                          <ExpandLess /> Collapse
                        </p>
                      </div>
                    </div>

                    {collapseDropdown &&
                      collapseDropdown[index] &&
                      regionalBreakdown[region].map((student) => (
                        <div className={styles.course_group_body}>
                          <p>{`${student.user.firstName} ${student.user.lastName}`}</p>
                          <p>{student.user.region}</p>
                          <p>{student.user.course}</p>
                          {/* <p>0</p> */}
                          <p>
                            <Visibility
                              onClick={(e) => {
                                handleAssessmentSummaryNavigation(
                                  e,
                                  student.user._id
                                );
                              }}
                            />
                          </p>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
