import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Modal,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

import { DatePicker } from 'antd';
import moment from 'moment';

import Remove from 'images/remove.png';
import Resend from 'images/resend.png';
import Revoke from 'images/revoke.png';

import LoadingOverlay from 'components/LoadingOverlay';
import ErrorOverlay from 'components/ErrorOverlay';
import { MainContent } from 'components/sidebar';
import organizationAPI from 'api/api';

import { ToastContainer, toast } from 'react-toastify';

import { CustomTextField } from 'components/TextField';

import styles from './Dashboard.module.css';
import {
  inputLableStyle,
  selectStyle,
  menuStyle,
  listTextStyle,
  SelectUserRole,
  SelectCourse,
  SelectRegion,
  MultipleSelectCoach,
  MultipleSelectCourse,
  MultipleSelectRegion,
  checkboxStyle,
  MultipleSelectCoachForStudent,
} from 'components/SelectField';
import {
  CustomButton,
  CustomTextButton,
  CustomWhiteButton,
} from 'components/Button';
import { CustomRadioGroup } from 'components/RadioGroup';
import { BulkCSVIcon, BulkIcon } from 'svg/CustomSVGIcon';
import { Close } from '@mui/icons-material';
import Papa from 'papaparse';

const CSVInviteSection = ({
  item,
  index,
  id,
  inviteeData,
  setInviteeData,
  user,
  coachData,
  invitedEmailList,
  courseList,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [course, setCourse] = useState('');
  const [region, setRegion] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [selectedAsigneeIds, setSelectedAsigneeIds] = useState([]);
  const [recipientEmailErrorMessage, setrecipientEmailErrorMessage] =
    useState('');

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setrecipientEmailErrorMessage(`Enter valid Email(s)`);
    } else if (invitedEmailList.includes(currentEmails)) {
      setrecipientEmailErrorMessage(`Email already invited`);
    } else {
      setrecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (item) {
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setEmail(item.email);
      setRole(item.role);

      if (item.role === 'COACH' || item.role === 'STUDENT') {
        setCourse(item.course);
        setRegion(item.region);
      }
    }
  }, []);

  useEffect(() => {
    if (email) validateEmail(email);
    else setrecipientEmailErrorMessage('');
  }, [email]);

  useEffect(() => {
    let isEnabled = Boolean(
      (firstName || item.firstName) &&
        (lastName || item.lastName) &&
        (email || item.email) &&
        (role || item.role)
    );

    if (role === 'COACH' || role === 'STUDENT') {
      isEnabled = Boolean(
        isEnabled && (course || item.course) && (region || item.region)
      );
    }

    if (recipientEmailErrorMessage) isEnabled = false;

    if (!role) return;

    const temp = inviteeData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          firstName,
          lastName,
          email,
          role,
          course,
          region,
          assigneeIds: selectedAsigneeIds,
        };
      }
      return item;
    });
    setInviteeData(temp);

    setSubmitEnabled(isEnabled);
  }, [firstName, lastName, email, role, course, region, selectedAsigneeIds]);

  return (
    <div className={styles.bulk_modal_invite_section}>
      <div className={styles.invite_bulk_section_header}>
        <p>
          Invitee {index + 1}
          {!submitEnabled && <span>* (Missing Field)</span>}
          {item.firstName}
        </p>

        {index > 0 && (
          <CustomTextButton
            onClick={() => {
              setInviteeData(inviteeData.filter((item) => item.id !== id));
            }}
          >
            - Remove
          </CustomTextButton>
        )}
      </div>
      <Box
        className={styles.form_menu}
        component="form"
        noValidate
        autoComplete="off"
      >
        <CustomTextField
          label="FIRST NAME"
          defaultValue={item.firstName}
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />

        <CustomTextField
          label="LAST NAME"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />

        <CustomTextField
          label="EMAIL ADDRESS"
          placeholder={'Email Address'}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          error={recipientEmailErrorMessage ? true : false}
          helperText={recipientEmailErrorMessage}
        />
      </Box>
      <div className={styles.input_section}>
        <Box
          className={styles.filter_menu}
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl className={styles.drop_down_select}>
            <SelectUserRole
              label={'ROLE'}
              input={'ROLE'}
              role={user.role}
              value={role}
              setValue={setRole}
              data={['ADMIN', 'COACH', 'STUDENT']}
            />
          </FormControl>

          {(role === 'STUDENT' || role === 'COACH') && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down_select}
            >
              <SelectCourse
                courseList={courseList}
                label={'SELECT COURSE'}
                input={'COURSE'}
                value={course}
                setValue={setCourse}
              />
            </FormControl>
          )}
          {(role === 'STUDENT' || role === 'COACH') && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down}
            >
              <SelectRegion
                label={'SELECT REGION'}
                input={'REGION'}
                value={region}
                setValue={setRegion}
              />
            </FormControl>
          )}
        </Box>
        {role === 'STUDENT' && (
          <div style={{ width: '33%' }}>
            <FormControl sx={{ width: '100%' }}>
              <MultipleSelectCoach
                input={'ASSIGN COACHES'}
                label={'Assign Coaches'}
                coachData={coachData}
                selectedAsigneeIds={selectedAsigneeIds}
                setSelectedAsigneeIds={setSelectedAsigneeIds}
              />
            </FormControl>
          </div>
        )}

        {/* <button className={styles.cancel}>
                <UploadFileIcon /> Bulk Invite by Upload
              </button> */}
      </div>
    </div>
  );
};

const InviteSection = ({
  index,
  id,
  inviteeData,
  setInviteeData,
  user,
  coachData,
  invitedEmailList,
  courseList,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [course, setCourse] = useState('');
  const [region, setRegion] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [selectedAsigneeIds, setSelectedAsigneeIds] = useState([]);
  const [recipientEmailErrorMessage, setrecipientEmailErrorMessage] =
    useState('');

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setrecipientEmailErrorMessage(`Enter valid Email(s)`);
    } else if (invitedEmailList.includes(currentEmails)) {
      setrecipientEmailErrorMessage(`Email already invited`);
    } else {
      setrecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (email) validateEmail(email);
    else setrecipientEmailErrorMessage('');
  }, [email]);

  useEffect(() => {
    let isEnabled = firstName && lastName && email && role;

    if (role === 'COACH' || role === 'STUDENT') {
      isEnabled = isEnabled && course && region;
    }

    if (role === 'STUDENT') {
      isEnabled = isEnabled && selectedAsigneeIds.length > 0;
    }

    if (recipientEmailErrorMessage) isEnabled = false;

    const temp = inviteeData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          firstName,
          lastName,
          email,
          role,
          course,
          region,
          assigneeIds: selectedAsigneeIds,
          isEnabled,
        };
      }
      return item;
    });
    setInviteeData(temp);

    setSubmitEnabled(isEnabled);
  }, [firstName, lastName, email, role, course, region, selectedAsigneeIds]);

  return (
    <div className={styles.bulk_modal_invite_section}>
      <div className={styles.invite_bulk_section_header}>
        <p>
          Invitee {index + 1}
          {!submitEnabled && <span>* (Missing Field)</span>}
        </p>

        {index > 0 && (
          <CustomTextButton
            onClick={() => {
              setInviteeData(inviteeData.filter((item) => item.id !== id));
            }}
          >
            - Remove
          </CustomTextButton>
        )}
      </div>
      <Box
        className={styles.form_menu}
        component="form"
        noValidate
        autoComplete="off"
      >
        <CustomTextField
          label="FIRST NAME"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />

        <CustomTextField
          label="LAST NAME"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />

        <CustomTextField
          label="EMAIL ADDRESS"
          placeholder={'Email Address'}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          error={recipientEmailErrorMessage ? true : false}
          helperText={recipientEmailErrorMessage}
        />
      </Box>
      <div className={styles.input_section}>
        <Box
          className={styles.filter_menu}
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl className={styles.drop_down_select}>
            <SelectUserRole
              label={'ROLE'}
              input={'ROLE'}
              role={user.role}
              value={role}
              setValue={setRole}
              data={['ADMIN', 'COACH', 'STUDENT']}
            />
          </FormControl>

          {(role === 'STUDENT' || role === 'COACH') && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down_select}
            >
              <SelectCourse
                courseList={courseList}
                label={'SELECT COURSE'}
                input={'COURSE'}
                value={course}
                setValue={setCourse}
              />
            </FormControl>
          )}
          {(role === 'STUDENT' || role === 'COACH') && (
            <FormControl
              sx={{
                width: '49%',
                ml: 1,
              }}
              className={styles.drop_down}
            >
              <SelectRegion
                label={'SELECT REGION'}
                input={'REGION'}
                value={region}
                setValue={setRegion}
              />
            </FormControl>
          )}
        </Box>
        {role === 'STUDENT' && (
          <div style={{ width: '33%' }}>
            <FormControl sx={{ width: '100%' }}>
              <MultipleSelectCoach
                input={'ASSIGN COACHES'}
                label={'Assign Coaches'}
                coachData={coachData}
                selectedAsigneeIds={selectedAsigneeIds}
                setSelectedAsigneeIds={setSelectedAsigneeIds}
              />
            </FormControl>
          </div>
        )}

        {/* <button className={styles.cancel}>
                <UploadFileIcon /> Bulk Invite by Upload
              </button> */}
      </div>
    </div>
  );
};

const BulkCSVInviteModal = ({
  inviteeData,
  setInviteeData,
  open,
  setOpen,
  user,
  coachData,
  organization_id,
  invitedEmailList,
  setLoading,
  getAllData,
  courseList,
}) => {
  const [error, setError] = useState('');
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {}, [inviteeData]);

  const bulkInvite = async (e) => {
    e.preventDefault();
    const tempOld = inviteeData.map((item) => {
      if (item.role === 'ADMIN') {
        if (item.firstName && item.lastName && item.email && item.role) {
          return {
            ...item,
            isEnabled: true,
          };
        } else {
          return {
            ...item,
            isEnabled: false,
          };
        }
      }

      if (item.role === 'COACH' || item.role === 'STUDENT') {
        if (item.firstName && item.lastName && item.email && item.role) {
          return {
            ...item,
            isEnabled: true,
          };
        } else {
          return {
            ...item,
            isEnabled: false,
          };
        }
      }

      return item;
    });

    const error = tempOld.some((item) => !item.isEnabled);
    if (error) {
      setError('Please fill all the fields');
      return;
    } else {
      setError('');
    }

    const temp = tempOld.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        role: item.role,
        course: item.course,
        region: item.region,
        assigneeIds: item.assigneeIds?.map((item) => item.id) || [],
      };
    });

    const data = {
      invitations: temp,
      organizationId: organization_id,
    };

    setLoading(true);

    try {
      const response = await organizationAPI.bulkInvitee(data);

      if (response.status === 200) {
        toast.success('Bulk Invite sent successfully');
        setOpen(false);
        setInviteeData([]);
        getAllData();
      } else {
        toast.error('Bulk Invite failed');
      }
    } catch (error) {
      setError('Bulk Invite failed');
      setLoading(false);
    }

    setLoading(false);
  };

  

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.bulk_modal}>
          <div className={styles.invite_section_header}>
            <h5>
              Bulk CSV Invite Personnel <span>{error && `* ${error}`}</span>
            </h5>
          </div>

          <div className={styles.bulk_invite_scroll}>
            {inviteeData.length > 0 &&
              inviteeData.map((item, index) => {
                return (
                  <div className={styles.bulk_invite_section} key={item.id}>
                    <CSVInviteSection
                      item={item}
                      invitedEmailList={invitedEmailList}
                      index={index}
                      id={item.id}
                      inviteeData={inviteeData}
                      setInviteeData={setInviteeData}
                      user={user}
                      coachData={coachData}
                      courseList={courseList}
                      finalData={finalData}
                    />
                  </div>
                );
              })}
          </div>

          <div className={styles.bulk_modal_button}>
            <CustomTextButton
              onClick={() => {
                setInviteeData([
                  ...inviteeData,
                  {
                    id: crypto.randomUUID(20).toString('hex'),
                    isEnabled: false,
                  },
                ]);
              }}
            >
              + Add New Invitee
            </CustomTextButton>
            <div className={styles.bulk_modal_group_button}>
              <CustomButton onClick={bulkInvite}>
                <SendIcon /> Send Invite
              </CustomButton>
              <CustomWhiteButton onClick={() => setOpen(false)}>
                <Close /> Cancel
              </CustomWhiteButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const BulkInviteModal = ({
  open,
  setOpen,
  user,
  coachData,
  organization_id,
  invitedEmailList,
  setLoading,
  getAllData,
  courseList,
}) => {
  const [inviteeData, setInviteeData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (inviteeData.length === 0) {
      setError('');
      setInviteeData([
        {
          id: crypto.randomUUID(20).toString('hex'),
          isEnabled: false,
        },
      ]);
    }
  }, [inviteeData]);

  const bulkInvite = async (e) => {
    e.preventDefault();
    const error = inviteeData.some((item) => !item.isEnabled);
    if (error) {
      setError('Please fill all the fields');
      return;
    } else {
      setError('');
    }

    const temp = inviteeData.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        role: item.role,
        course: item.course,
        region: item.region,
        assigneeIds: item.assigneeIds.map((item) => item.id),
      };
    });

    const data = {
      invitations: temp,
      organizationId: organization_id,
    };

    setLoading(true);

    try {
      const response = await organizationAPI.bulkInvitee(data);

      if (response.status === 200) {
        toast.success('Bulk Invite sent successfully');
        setOpen(false);
        setInviteeData([]);
        getAllData();
      } else {
        toast.error('Bulk Invite failed');
      }
    } catch (error) {
      setError('Bulk Invite failed');
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.bulk_modal}>
          <div className={styles.invite_section_header}>
            <h5>
              Invite Personnel <span>{error && `* ${error}`}</span>
            </h5>
          </div>

          <div className={styles.bulk_invite_scroll}>
            {inviteeData.length > 0 &&
              inviteeData.map((item, index) => {
                return (
                  <div className={styles.bulk_invite_section} key={item.id}>
                    <InviteSection
                      invitedEmailList={invitedEmailList}
                      index={index}
                      id={item.id}
                      inviteeData={inviteeData}
                      setInviteeData={setInviteeData}
                      user={user}
                      coachData={coachData}
                      courseList={courseList}
                    />
                  </div>
                );
              })}
          </div>

          <div className={styles.bulk_modal_button}>
            <CustomTextButton
              onClick={() => {
                setInviteeData([
                  ...inviteeData,
                  {
                    id: crypto.randomUUID(20).toString('hex'),
                    isEnabled: false,
                  },
                ]);
              }}
            >
              + Add New Invitee
            </CustomTextButton>
            <div className={styles.bulk_modal_group_button}>
              <CustomButton onClick={bulkInvite}>
                <SendIcon /> Send Invite
              </CustomButton>
              <CustomWhiteButton onClick={() => setOpen(false)}>
                <Close /> Cancel
              </CustomWhiteButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const TableDropDownCheck = ({
  coachData,
  student,
  setLoading,
  organization_id,
  assigneeIds,
}) => {
  const [selectedAsigneeIds, setSelectedAsigneeIds] = useState(
    assigneeIds
      .map((id) => {
        const coach = coachData.find((coach) => coach.id === id);
        return coach ? { ...coach } : null;
      })
      .filter(Boolean)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const uniqueIds = new Set(value.map((item) => item.id));
    const uniqueAssignees = coachData.filter((coach) =>
      uniqueIds.has(coach.id)
    );

    setSelectedAsigneeIds(uniqueAssignees);
  };

  const handleUpdateAssignees = async () => {
    setLoading(true);
    const assigneeIds = selectedAsigneeIds.map((item) => item.id);
    const data = {
      organizationId: organization_id,
      userId: student.id,
      assignees: assigneeIds,
    };

    const response = await organizationAPI.updateAssignees(data);
    setLoading(false);

    if (response.status === 200) {
      toast.success('Assignee updated successfully');
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label" sx={inputLableStyle}>
          ASSIGN COACHES
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label "
          id="demo-multiple-checkbox"
          multiple
          value={selectedAsigneeIds}
          onChange={handleChange}
          input={<OutlinedInput label="Assign Teacher" />}
          renderValue={(selected) =>
            selected.map((x) => `${x.firstName} ${x.lastName}`).join(', ')
          }
          onClose={handleUpdateAssignees}
          MenuProps={MenuProps}
          sx={selectStyle}
        >
          {coachData.length > 0 &&
            coachData
              .slice(0)
              .sort(function (a, b) {
                let firstNameComparison = a.firstName
                  .toLowerCase()
                  .localeCompare(b.firstName.toLowerCase());

                if (firstNameComparison === 0) {
                  return a.lastName
                    .toLowerCase()
                    .localeCompare(b.lastName.toLowerCase());
                }

                return firstNameComparison;
              })
              .map((coach) => (
                <MenuItem key={coach.id} value={coach} sx={menuStyle}>
                  <Checkbox
                    sx={checkboxStyle}
                    checked={selectedAsigneeIds.some(
                      (item) => item.id === coach.id
                    )}
                  />
                  <ListItemText
                    primaryTypographyProps={listTextStyle}
                    primary={`${coach.firstName} ${coach.lastName}`}
                  />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};

const RemoveModal = ({
  open,
  setOpen,
  handleRemove,
  removeUserId,
  removeUserEmail,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.custom_modal}>
          <DoDisturbOnOutlinedIcon
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h3 className={styles.modal_header}>Removing User</h3>
          <p className={styles.modal_description}>
            Are you sure you want to remove this user?
          </p>
          <div className={styles.modal_button}>
            <button
              className={styles.yes}
              onClick={() => {
                handleRemove(removeUserId, removeUserEmail);
                setOpen(false);
              }}
            >
              Yes
            </button>
            <button className={styles.no} onClick={() => setOpen(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const RevokeModal = ({ open, setOpen, handleRevoke, revokeUserId }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.custom_modal}>
          <DoDisturbOnOutlinedIcon
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h3 className={styles.modal_header}>Revoking User</h3>
          <p className={styles.modal_description}>
            Are you sure you want to revoke this user?
          </p>
          <div className={styles.modal_button}>
            <button
              className={styles.yes}
              onClick={() => {
                handleRevoke(revokeUserId);
                setOpen(false);
              }}
            >
              Yes
            </button>
            <button className={styles.no} onClick={() => setOpen(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [coachData, setCoachData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [course, setCourse] = useState('');
  const [region, setRegion] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [selectedAsigneeIds, setSelectedAsigneeIds] = useState([]);
  const [adminFilterStatus, setAdminFilterStatus] = useState('ALL');
  const [coachFilterStatus, setCoachFilterStatus] = useState('ALL');
  const [studentFilterStatus, setStudentFilterStatus] = useState('ALL');
  const [userId, setUserId] = useState('');
  const [organization_id, setOrganization_id] = useState('');
  const [invitedEmailList, setInvitedEmailList] = useState([]);
  const [recipientEmailErrorMessage, setrecipientEmailErrorMessage] =
    useState('');

  const [selectedCourseIdForCoach, setSelectedCourseIdForCoach] = useState([]);
  const [selectedCourseIdForStudent, setSelectedCourseIdForStudent] = useState(
    []
  );
  const [selectedRegionForCoach, setSelectedRegionForCoach] = useState([]);
  const [selectedRegionForStudent, setSelectedRegionForStudent] = useState([]);
  const [selectedCoachIdForStudent, setSelectedCoachIdForStudent] = useState(
    []
  );

  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkCSVModalOpen, setBulkCSVModalOpen] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [removeEmail, setRemoveEmail] = useState('');

  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [revokeId, setRevokeId] = useState('');
  const [revokeEmail, setRevokeEmail] = useState('');

  const [user, setUser] = useState({});

  const [courseName, setCourseName] = useState('');
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseRegionName, setCourseRegionName] = useState('');
  const [courseCreateEnabled, setCourseCreateEnabled] = useState(false);
  const [inviteeData, setInviteeData] = useState([]);
  const hiddenFileInput = useRef(null);

  const { RangePicker } = DatePicker;

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const handleInviteUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const assigneeIds = selectedAsigneeIds.map((item) => item.id);
    const data = {
      organizationId: organization_id,
      firstName,
      lastName,
      email,
      role,
      assigneeIds,
    };

    if (region) data.region = region;
    if (course) data.course = course;

    try {
      const response = await organizationAPI.inviteUser(data);

      setFirstName('');
      setLastName('');
      setEmail('');
      setRole('');
      setCourse('');
      setRegion('');
      setSelectedAsigneeIds([]);

      await getAllData();
      setLoading(false);

      if (response.status === 200) {
        toast.success('User invited successfully');
      }
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleRemove = async (id, email) => {
    setLoading(true);
    const data = {
      organizationId: organization_id,
      userId: id,
      email,
    };

    try {
      const response = await organizationAPI.deleteUser(data);

      await getAllData();
      setLoading(false);

      if (response.status === 200) {
        toast.success('User delete successfully');
      }
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleRevoke = async (id) => {
    setLoading(true);
    const data = {
      organizationId: organization_id,
      invitationId: id,
    };

    try {
      const response = await organizationAPI.revokeInvitation(data);

      await getAllData();
      setLoading(false);

      if (response.status === 200) {
        toast.success('Invitation Revoke successfully');
      }
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleResend = async (id) => {
    setLoading(true);
    const data = {
      organizationId: organization_id,
      invitationId: id,
    };

    try {
      const response = await organizationAPI.resendInvitation(data);

      await getAllData();
      setLoading(false);

      if (response.status === 200) {
        toast.success('Invitation sent successfully');
      }
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUserId(response.data.user._id);
      setOrganization_id(response.data.organization_id);
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
    }
    setLoading(false);
  };

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setrecipientEmailErrorMessage(`Enter valid Email(s)`);
    } else if (invitedEmailList.includes(currentEmails)) {
      setrecipientEmailErrorMessage(`Email already invited`);
    } else {
      setrecipientEmailErrorMessage('');
    }
  };

  const handleCreateCourse = async () => {
    try {
      const data = {
        organizationId: organization_id,
        courseName: courseName,
        assignedRegion: courseRegionName,
      };
      const response = await organizationAPI.createCourse(data);

      if (response.status === 200) {
        toast.success('Course created successfully');
        setCourseName('');
        setCourseRegionName('');
        setCourseCreateEnabled(false);
        await getAllCourses();
      }
    } catch (error) {
      setErrorVisible(true);
    }
  };
  const getAllCourses = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAllCourses();

      setCourseData(response.data.courses);

      const temp = response.data.courses.map((item) => {
        return item.courseName;
      });

      setCourseList(temp.slice(0).sort());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleCourseDelete = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteCourse(id);

      if (response.status === 200) {
        toast.success('Course deleted successfully');
        await getAllCourses();
      }
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  const handleFileChange = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const temp = results.data.map((item) => {
          return {
            id: crypto.randomUUID(20).toString('hex'),
            isEnabled: false,
            firstName: item['FirstName'],
            lastName: item['LastName'],
            email: item['Email'],
            role: item['Role'],
            course: item['Course'],
            region: item['Region'],
          };
        });

        console.log(temp, 'temp');

        setInviteeData(temp);
        setBulkCSVModalOpen(true);
      },
    });
  };

  useEffect(() => {
    if (courseName && courseRegionName && !courseNameError) {
      setCourseCreateEnabled(true);
    } else {
      setCourseCreateEnabled(false);
    }

    if (courseName) {
      for (const item of courseList) {
        if (item.toLowerCase() === courseName.toLowerCase()) {
          setCourseNameError(true);
          break;
        } else {
          setCourseNameError(false);
        }
      }
    }
  }, [courseName, courseRegionName, courseNameError]);

  useEffect(() => {
    if (email) validateEmail(email);
    else setrecipientEmailErrorMessage('');
  }, [email]);

  const getAllData = async () => {
    setLoading(true);
    const admin = [];
    const coach = [];
    const student = [];
    const invitedEmails = [];

    try {
      const response = await organizationAPI.getUsersList(organization_id);

      const course_response = await organizationAPI.getAllCourses();

      setCourseData(course_response.data.courses);

      const temp = course_response.data.courses.map((item) => {
        return item.courseName;
      });

      setCourseList(temp);

      response.data.forEach((item) => {
        if (item.role === 'ADMIN') {
          admin.push(item);
        } else if (item.role === 'COACH') {
          coach.push(item);
        } else if (item.role === 'STUDENT') {
          student.push(item);
        }

        invitedEmails.push(item.email);
      });

      setInvitedEmailList(invitedEmails);
      setAdminData(admin);
      setCoachData(coach);
      setStudentData(student);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorVisible(true);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (organization_id) {
      getAllData();
    }
  }, [organization_id]);

  useEffect(() => {
    let isEnabled = firstName && lastName && email && role;

    if (role === 'COACH' || role === 'STUDENT') {
      isEnabled = isEnabled && course && region;
    }

    if (role === 'STUDENT') {
      isEnabled = isEnabled && selectedAsigneeIds.length > 0;
    }

    setSubmitEnabled(isEnabled);
  }, [firstName, lastName, email, role, course, region, selectedAsigneeIds]);

  return (
    <MainContent active="dashboard" user={user}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnHover
      />
      {inviteeData.length > 0 && (
        <BulkCSVInviteModal
          inviteeData={inviteeData}
          setInviteeData={setInviteeData}
          open={bulkCSVModalOpen}
          setOpen={setBulkCSVModalOpen}
          setLoading={setLoading}
          user={user}
          organization_id={organization_id}
          coachData={coachData}
          invitedEmailList={invitedEmailList}
          getAllData={getAllData}
          courseList={courseList}
        />
      )}

      <BulkInviteModal
        open={bulkModalOpen}
        setOpen={setBulkModalOpen}
        setLoading={setLoading}
        user={user}
        organization_id={organization_id}
        coachData={coachData}
        invitedEmailList={invitedEmailList}
        getAllData={getAllData}
        courseList={courseList}
      />

      <RemoveModal
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        handleRemove={handleRemove}
        removeUserId={removeId}
        removeUserEmail={removeEmail}
      />

      <RevokeModal
        open={openRevokeModal}
        setOpen={setOpenRevokeModal}
        handleRevoke={handleRevoke}
        revokeUserId={revokeId}
      />

      <div className={styles.header}>
        <h1>Welcome {user && user.firstName + ' ' + user.lastName}</h1>
      </div>
      <div className={styles.line}></div>

      <div className={styles.invite_section}>
        <div className={styles.invite_bulk_section_header}>
          <h5>Invite Personnel</h5>
          <div className={styles.invite_bulk_button_section}>
            <CustomButton
              onClick={() => setBulkModalOpen(true)}
              style={{ margin: 0 }}
            >
              <BulkIcon /> Bulk Invite
            </CustomButton>

            <CustomButton
              onClick={() => {
                hiddenFileInput.current.click();
              }}
              style={{ margin: 0 }}
            >
              <BulkCSVIcon /> Bulk Invite by CSV Upload
            </CustomButton>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              ref={hiddenFileInput}
              style={{ display: 'none' }} // Make the file input element invisible
            />
          </div>
        </div>
        <Box
          className={styles.form_menu}
          component="form"
          noValidate
          autoComplete="off"
        >
          <CustomTextField
            label="FIRST NAME"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />

          <CustomTextField
            label="LAST NAME"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />

          <CustomTextField
            label="EMAIL ADDRESS"
            placeholder={'Email Address'}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            error={recipientEmailErrorMessage ? true : false}
            helperText={recipientEmailErrorMessage}
          />
        </Box>
        <div className={styles.input_section}>
          <Box
            className={styles.filter_menu}
            component="form"
            noValidate
            autoComplete="off"
          >
            <FormControl className={styles.drop_down_select}>
              <SelectUserRole
                label={'ROLE'}
                input={'ROLE'}
                role={user.role}
                value={role}
                setValue={setRole}
                data={['ADMIN', 'COACH', 'STUDENT']}
              />
            </FormControl>

            {(role === 'STUDENT' || role === 'COACH') && (
              <FormControl
                sx={{
                  width: '49%',
                  ml: 1,
                }}
                className={styles.drop_down_select}
              >
                <SelectCourse
                  courseList={courseList}
                  label={'SELECT COURSE'}
                  input={'COURSE'}
                  value={course}
                  setValue={setCourse}
                />
              </FormControl>
            )}
            {(role === 'STUDENT' || role === 'COACH') && (
              <FormControl
                sx={{
                  width: '49%',
                  ml: 1,
                }}
                className={styles.drop_down}
              >
                <SelectRegion
                  label={'SELECT REGION'}
                  input={'REGION'}
                  value={region}
                  setValue={setRegion}
                />
              </FormControl>
            )}
          </Box>
          {role === 'STUDENT' && (
            <div style={{ width: '33%' }}>
              <FormControl sx={{ width: '100%' }}>
                <MultipleSelectCoach
                  input={'ASSIGN COACHES'}
                  label={'Assign Coaches'}
                  coachData={coachData}
                  selectedAsigneeIds={selectedAsigneeIds}
                  setSelectedAsigneeIds={setSelectedAsigneeIds}
                />
              </FormControl>
            </div>
          )}

          {/* <button className={styles.cancel}>
                <UploadFileIcon /> Bulk Invite by Upload
              </button> */}
        </div>
        <CustomButton
          onClick={handleInviteUser}
          disabled={!submitEnabled || recipientEmailErrorMessage}
        >
          <SendIcon /> Send Invite
        </CustomButton>
      </div>

      {user.role === 'ADMIN' && (
        <div className={styles.coach_roaster}>
          <div className={styles.add_course}>
            <h5>Add Course</h5>
            <div className={styles.add_course_input_section}>
              <div className={styles.add_course_input}>
                <div>
                  <CustomTextField
                    label="COURSE NAME"
                    placeholder="Enter Course Name"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                  />
                  {courseNameError && (
                    <p className={styles.error_message}>
                      Course name already exist
                    </p>
                  )}
                </div>
                <FormControl
                  sx={{
                    width: '200px',
                    maxWidth: '200px',
                  }}
                  className={styles.drop_down}
                >
                  <SelectRegion
                    label={'SELECT REGION'}
                    input={'REGION'}
                    value={courseRegionName}
                    setValue={setCourseRegionName}
                  />
                </FormControl>
              </div>
              <CustomButton
                onClick={handleCreateCourse}
                disabled={!courseCreateEnabled}
              >
                <SendIcon />
                Add Course
              </CustomButton>
            </div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.coach_top}>
            <h5>Courses</h5>
            <div className={styles.top_right}>
              <div className={styles.search}>
                {/* <RangePicker /> */}
                {/* <TextField
                    id="input-with-icon-textfield"
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
              </div>
            </div>
          </div>

          <div className={styles.coach_table}>
            <table className={styles.styled_table}>
              <thead className={styles.table_headers}>
                <tr>
                  <th>Course Code</th>
                  <th>Date of Creation</th>
                  <th>Region</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {courseData?.length > 0 &&
                  courseData.map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>{`${item.courseName}`}</td>
                        <td className={styles.data}>{`${convertDate(
                          item.createdAt
                        )}`}</td>
                        <td
                          className={styles.data}
                        >{`${item.assignedRegion}`}</td>
                        <td className={styles.data}>
                          <div className={styles.status}>
                            <img src={Remove} alt="remove" />
                            <button
                              onClick={(e) => handleCourseDelete(e, item._id)}
                            >
                              Remove
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {adminData.length === 0 && (
              <div className={styles.empty_table}>
                <p>No admin or admin candidate listed yet.</p>
              </div>
            )}
          </div>
        </div>
      )}

      {user.role === 'ADMIN' && (
        <div className={styles.coach_roaster}>
          <div className={styles.coach_top}>
            <h5>
              Admin Roster{' '}
              <span className={styles.data}>
                (Showing{' '}
                {
                  adminData.filter((item) => {
                    if (adminFilterStatus === 'ALL') return item;
                    else return item.status === adminFilterStatus;
                  }).length
                }{' '}
                user)
              </span>
            </h5>
            <div className={styles.top_right}>
              <FormControl className={styles.radio_buttons}>
                <CustomRadioGroup
                  options={['All', 'Pending', 'Accepted']}
                  value={adminFilterStatus}
                  setValue={setAdminFilterStatus}
                />
              </FormControl>
              <div className={styles.search}>
                {/* <RangePicker /> */}
                {/* <TextField
                    id="input-with-icon-textfield"
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
              </div>
            </div>
          </div>

          <div className={styles.coach_table}>
            <table className={styles.styled_table}>
              <thead className={styles.table_headers}>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Invited On</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {adminData.length > 0 &&
                  adminData
                    .filter((item) => {
                      if (adminFilterStatus === 'ALL') return item;
                      else return item.status === adminFilterStatus;
                    })
                    .map((item, index) => {
                      return (
                        <tr className={styles.coachrow} key={index}>
                          <td
                            className={styles.data}
                          >{`${item.firstName} ${item.lastName}`}</td>
                          <td className={styles.data}>{item.email}</td>
                          <td className={styles.data}>{item.invitationDate}</td>
                          <td className={styles.data}>{item.status}</td>
                          <td className={styles.data}>
                            {item.status === 'PENDING' && (
                              <div className={styles.status}>
                                <div className={styles.resend}>
                                  <img src={Resend} alt="resend" />
                                  <button onClick={() => handleResend(item.id)}>
                                    Resend
                                  </button>
                                </div>
                                <div className={styles.revoke}>
                                  <img src={Revoke} alt="revoke" />
                                  <button
                                    onClick={() => {
                                      setOpenRevokeModal(true);
                                      setRevokeId(item.id);
                                    }}
                                  >
                                    Revoke
                                  </button>
                                </div>
                              </div>
                            )}
                            {item.status === 'ACCEPTED' && (
                              <div className={styles.status}>
                                <img src={Remove} alt="remove" />
                                <button
                                  onClick={() => {
                                    setOpenRemoveModal(true);
                                    setRemoveId(item.id);
                                    setRemoveEmail(item.email);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {adminData.length === 0 && (
              <div className={styles.empty_table}>
                <p>No admin or admin candidate listed yet.</p>
              </div>
            )}
          </div>
        </div>
      )}
      {user.role === 'ADMIN' && (
        <div className={styles.coach_roaster}>
          <div className={styles.coach_top}>
            <h5>
              Coach Roster{' '}
              <span className={styles.data}>
                (Showing{' '}
                {
                  coachData
                    .filter((item) => {
                      if (coachFilterStatus === 'ALL') return item;
                      else return item.status === coachFilterStatus;
                    })
                    .filter((item) => {
                      if (selectedCourseIdForCoach.length === 0) return item;
                      else if (selectedCourseIdForCoach.includes(item.course))
                        return item;
                    })
                    .filter((item) => {
                      if (selectedRegionForCoach.length === 0) return item;
                      else if (selectedRegionForCoach.includes(item.region))
                        return item;
                    }).length
                }{' '}
                user)
              </span>
            </h5>

            <div className={styles.top_right}>
              <div style={{ width: '30%' }}>
                <FormControl sx={{ width: '100%' }}>
                  <MultipleSelectCourse
                    courseList={courseList}
                    value={selectedCourseIdForCoach}
                    setValue={setSelectedCourseIdForCoach}
                    input={'Filter by Course'}
                    label={'Filter by Course'}
                  />
                </FormControl>
              </div>
              <div style={{ width: '30%' }}>
                <FormControl sx={{ width: '100%' }}>
                  <MultipleSelectRegion
                    value={selectedRegionForCoach}
                    setValue={setSelectedRegionForCoach}
                    input={'Filter by Region'}
                    label={'Filter by Region'}
                  />
                </FormControl>
              </div>

              <FormControl className={styles.radio_buttons}>
                <CustomRadioGroup
                  options={['All', 'Pending', 'Accepted']}
                  value={coachFilterStatus}
                  setValue={setCoachFilterStatus}
                />
              </FormControl>
              <div className={styles.search}>
                {/* <RangePicker /> */}
                {/* <TextField
                    id="input-with-icon-textfield"
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
              </div>
            </div>
          </div>

          <div className={styles.coach_table}>
            <table className={styles.styled_table}>
              <thead className={styles.table_headers}>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Invited On</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {coachData.length > 0 &&
                  coachData
                    .filter((item) => {
                      if (coachFilterStatus === 'ALL') return item;
                      else return item.status === coachFilterStatus;
                    })
                    .filter((item) => {
                      if (selectedCourseIdForCoach.length === 0) return item;
                      else if (selectedCourseIdForCoach.includes(item.course))
                        return item;
                    })
                    .filter((item) => {
                      if (selectedRegionForCoach.length === 0) return item;
                      else if (selectedRegionForCoach.includes(item.region))
                        return item;
                    })
                    .map((item, index) => {
                      return (
                        <tr className={styles.coachrow} key={item.id}>
                          <td
                            className={styles.data}
                          >{`${item.firstName} ${item.lastName}`}</td>
                          <td className={styles.data}>{item.email}</td>
                          <td className={styles.data}>{item.invitationDate}</td>
                          <td className={styles.data}>{item.status}</td>
                          <td className={styles.data}>
                            {item.status === 'PENDING' && (
                              <div className={styles.status}>
                                <div className={styles.resend}>
                                  <img src={Resend} alt="resend" />
                                  <button onClick={() => handleResend(item.id)}>
                                    Resend
                                  </button>
                                </div>
                                <div className={styles.revoke}>
                                  <img src={Revoke} alt="revoke" />
                                  <button
                                    onClick={() => {
                                      setOpenRevokeModal(true);
                                      setRevokeId(item.id);
                                    }}
                                  >
                                    Revoke
                                  </button>
                                </div>
                              </div>
                            )}
                            {item.status === 'ACCEPTED' && (
                              <div className={styles.status}>
                                <img src={Remove} alt="remove" />
                                <button
                                  onClick={() => {
                                    setOpenRemoveModal(true);
                                    setRemoveId(item.id);
                                    setRemoveEmail(item.email);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {coachData.length === 0 && (
              <div className={styles.empty_table}>
                <p>No coach or coach candidates listed yet.</p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.coach_roaster}>
        <div className={styles.coach_top}>
          <h5>
            Student Roster{' '}
            <span className={styles.data}>
              (Showing{' '}
              {
                studentData
                  .filter((item) => {
                    if (studentFilterStatus === 'ALL') return item;
                    else return item.status === studentFilterStatus;
                  })
                  .filter((item) => {
                    const temp = selectedCoachIdForStudent.map(
                      (coach) => coach.id
                    );
                    if (selectedCoachIdForStudent.length === 0) return item;
                    else {
                      const result = temp.some((val) =>
                        item.assigneeIds.includes(val)
                      );
                      return result;
                    }
                  }).length
              }{' '}
              user)
            </span>
          </h5>
          <div className={styles.student_top_right}>
            {user.role === 'ADMIN' && (
              <>
                <div style={{ width: '30%' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <MultipleSelectCoachForStudent
                      coachData={coachData}
                      selectedCoachIdForStudent={selectedCoachIdForStudent}
                      setSelectedCoachIdForStudent={
                        setSelectedCoachIdForStudent
                      }
                    />
                  </FormControl>
                </div>

                <div style={{ width: '30%' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <MultipleSelectCourse
                      courseList={courseList}
                      value={selectedCourseIdForStudent}
                      setValue={setSelectedCourseIdForStudent}
                      input={'Filter by Course'}
                      label={'Filter by Course'}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '30%' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <MultipleSelectRegion
                      value={selectedRegionForStudent}
                      setValue={setSelectedRegionForStudent}
                      input={'Filter by Region'}
                      label={'Filter by Region'}
                    />
                  </FormControl>
                </div>
              </>
            )}
            <FormControl className={styles.radio_buttons}>
              <CustomRadioGroup
                options={['All', 'Pending', 'Accepted']}
                value={studentFilterStatus}
                setValue={setStudentFilterStatus}
              />
            </FormControl>
            <div className={styles.search}>{/* <RangePicker /> */}</div>
          </div>
        </div>

        <div className={styles.coach_table}>
          <table className={styles.styled_table}>
            <thead className={styles.table_headers}>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Invited On</th>
                <th className={styles.center}>Status</th>
                <th className={styles.center}>Assignees</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {studentData.length > 0 &&
                studentData
                  .filter((item) => {
                    if (studentFilterStatus === 'ALL') return item;
                    else return item.status === studentFilterStatus;
                  })
                  .filter((item) => {
                    const temp = selectedCoachIdForStudent.map(
                      (coach) => coach.id
                    );
                    if (selectedCoachIdForStudent.length === 0) return item;
                    else {
                      const result = temp.some((val) =>
                        item.assigneeIds.includes(val)
                      );
                      return result;
                    }
                  })
                  .filter((item) => {
                    if (selectedCourseIdForStudent.length === 0) return item;
                    else if (selectedCourseIdForStudent.includes(item.course))
                      return item;
                  })
                  .filter((item) => {
                    if (selectedRegionForStudent.length === 0) return item;
                    else if (selectedRegionForStudent.includes(item.region))
                      return item;
                  })
                  .map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td
                          className={styles.data}
                        >{`${item.firstName} ${item.lastName}`}</td>
                        <td className={styles.data}>{item.email}</td>
                        <td className={styles.data}>{item.invitationDate}</td>
                        <td className={styles.data}>{item.status}</td>
                        <td className={styles.onboarded}>
                          <div className={styles.onboarded_section}>
                            <TableDropDownCheck
                              coachData={coachData}
                              student={item}
                              setLoading={setLoading}
                              organization_id={organization_id}
                              assigneeIds={item.assigneeIds}
                            />
                          </div>
                        </td>
                        <td className={styles.data}>
                          {item.status === 'PENDING' && (
                            <div className={styles.status}>
                              <div className={styles.resend}>
                                <img src={Resend} alt="resend" />
                                <button onClick={() => handleResend(item.id)}>
                                  Resend
                                </button>
                              </div>
                              <div className={styles.revoke}>
                                <img src={Revoke} alt="revoke" />
                                <button
                                  onClick={() => {
                                    setOpenRevokeModal(true);
                                    setRevokeId(item.id);
                                  }}
                                >
                                  Revoke
                                </button>
                              </div>
                            </div>
                          )}
                          {item.status === 'ACCEPTED' && (
                            <div className={styles.status}>
                              <img src={Remove} alt="remove" />
                              <button
                                onClick={() => {
                                  setOpenRemoveModal(true);
                                  setRemoveId(item.id);
                                  setRemoveEmail(item.email);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {studentData.length === 0 && (
            <div className={styles.empty_table}>
              <p>No student or student candidates listed yet.</p>
            </div>
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default Settings;
