import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Summary.module.css';
import HearingIcon from '@mui/icons-material/Hearing';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import SpatialAudioOffOutlinedIcon from '@mui/icons-material/SpatialAudioOffOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const Summary = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <h6>Summary and Statistics</h6>
        <div className={styles.border}></div>
        <p>
          This is an automatically generated summary of the entire conversation.
          It’s almost scary how accurate and succinct it is, but it’s also kind
          of nice. It’s incredible how smart our AI is. The conversation starts
          with Mark Cuban and the caller exchanging greetings, followed by
        </p>
        <div className={styles.summary_section}>
          <div className={styles.summary}>
            <div className={styles.summary_left}>
              <div className={styles.summary_ratio}>
                <HearingIcon />
                <div className={styles.summary_ratio_right}>
                  <p>Talk/Listen Ratio</p>
                  <h5>66%</h5>
                </div>
              </div>
            </div>
            <div className={styles.summary_right}>
              <p>How much you spoke vs. how much you listened</p>
              <h4>
                <CheckOutlinedIcon />
                Within acceptable parameters
              </h4>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.summary_left}>
              <div className={styles.summary_ratio}>
                <WrapTextOutlinedIcon />
                <div className={styles.summary_ratio_right}>
                  <p>Filler Words</p>
                  <h5>32 wpm</h5>
                </div>
              </div>
            </div>
            <div className={styles.summary_right}>
              <p>How many filler words like ‘um’ you used</p>
              <h4>
                <CheckOutlinedIcon />
                Below recommended range
              </h4>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.summary_left}>
              <div className={styles.summary_ratio}>
                <SpeedOutlinedIcon />
                <div className={styles.summary_ratio_right}>
                  <p>Talking Speed</p>
                  <h5>196 wpm</h5>
                </div>
              </div>
            </div>
            <div className={styles.summary_right}>
              <div className={styles.summary_ratio_right}>
                <p>How fast you spoke</p>
                <h4>
                  <WarningAmberOutlinedIcon className={styles.warning_icon} />
                  Above recommended range
                </h4>
              </div>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.summary_left}>
              <div className={styles.summary_ratio}>
                <SpatialAudioOffOutlinedIcon />
                <div className={styles.summary_ratio_right}>
                  <p>Longest Monologue</p>
                  <h5>1m 23s</h5>
                </div>
              </div>
            </div>
            <div className={styles.summary_right}>
              <div className={styles.summary_ratio_right}>
                <p>
                  The longest uninterrupted stretch of time for which you spoke
                </p>
                <h4>
                  <CheckOutlinedIcon /> Within acceptable parameters
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
