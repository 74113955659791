import React, { useState } from 'react';
import '../styles/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/api';

function CoachLogin() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const showAlert = (message) => {
    alert(message);
  };

  const handleLogin = async () => {
    try {
     
      await api.coachLogin({ email, password });
      navigate('/coachDashboard');
      // Handle the data received from the server (e.g., update state, perform actions)
    } catch (error) {
      console.log(error);
      showAlert('Invalid login, please enter valid login details');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <div className="app-container">
      <div className="container">
        <h1 className="header-text">Hello, Coach</h1>
        <p className="sub-text">Welcome Back</p>

        <input
          className="email-input"
          type="text"
          placeholder="Email Address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className="password-field">
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Enter your password"
            className="password-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="toggle-password"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? 'Hide' : 'Show'}
          </button>
        </div>
          <button className="button" onClick={handleLogin}>
            <span className="button-text">Login</span>
          </button>
      </div>
    </div>
  );
}

export default CoachLogin;