import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { RemoveModalIcon } from 'svg/CustomSVGIcon';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { useLocation } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';
import { CustomAutoComplete } from 'components/AutoComplete';
import { CustomTextField } from 'components/TextField';

const EssayTopic = ({ essayTopicsAndIdeas, getAdmissionTemplateById }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const [newChallenge, setNewChallenge] = useState([]);
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  const handleRemove = async (id) => {
    setLoading(true);
    const temp = newChallenge
      .filter((challenge) => challenge !== id)
      .filter(
        (item) =>
          item.ideaOverview !== '' ||
          item.draft !== '' ||
          item.additionalInfo !== '' ||
          item.areasOfFeedback.length > 0
      )
      .map((item) => {
        return {
          ideaOverview: item.ideaOverview,
          draft: item.draft,
          additionalInfo: item.additionalInfo,
          areasOfFeedback: item.areasOfFeedback,
        };
      });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response = await organizationAPI.addAdmissionTemplatEssayTopic(data);

    await getAdmissionTemplateById();
    setLoading(false);
    handleClose();
  };

  const handleSave = async (e) => {
    setLoading(true);
    const temp = newChallenge.map((item) => {
      return {
        ideaOverview: item.ideaOverview,
        draft: item.draft,
        additionalInfo: item.additionalInfo,
        areasOfFeedback: item.areasOfFeedback,
      };
    });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response = await organizationAPI.addAdmissionTemplatEssayTopic(data);

    await getAdmissionTemplateById();
    setLoading(false);
  };

  const handleEssayTopicData = () => {
    const temp = essayTopicsAndIdeas.map((item) => {
      return {
        id: crypto.randomUUID(),
        ideaOverview: item.ideaOverview,
        draft: item.draft,
        additionalInfo: item.additionalInfo,
        areasOfFeedback: item.areasOfFeedback,
      };
    });
    setNewChallenge(temp);
  };

  useEffect(() => {
    if (essayTopicsAndIdeas) handleEssayTopicData();
  }, [essayTopicsAndIdeas]);

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.container_head}>
        <h6>Essay Topics and Ideas</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
          <button onClick={handleSave} className={styles.save}>
            <SaveIcon /> Save Changes
          </button>
        </div>
      </div>
      <div className={styles.border}></div>
      {newChallenge &&
        newChallenge.map((challenge, index) => (
          <div className={styles.curricular_section}>
            <div className={styles.top}>
              <p>{`Essay Topics and Ideas ${index + 1}`} </p>
              <button onClick={handleOpen} className={styles.delete}>
                <CloseIcon /> Remove
              </button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styles.remove_modal}>
                  <RemoveModalIcon />
                  <h2>Remove College</h2>
                  <p>Are you sure you want to remove this college?</p>
                  <div className={styles.remove_modal_buttons}>
                    <button
                      onClick={() => handleRemove(challenge)}
                      className={styles.remove}
                    >
                      yes
                    </button>
                    <button onClick={handleClose} className={styles.cancel}>
                      no
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className={styles.input_tab}>
              {/* <Autocomplete
                className={styles.autocomplete}
                multiple
                id="tags-filled"
                options={[]}
                defaultValue={[]}
                freeSolo
                onChange={(e, value) => setReceivers((state) => value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="PRELIMINARY IDEA OR TOPIC FOR YOUR ESSAY"
                    placeholder="Preliminary idea or topic for your essay"
                    multiline
                  />
                )}
              /> */}

              <CustomTextField
                label="PRELIMINARY IDEA OR TOPIC FOR YOUR ESSAY"
                placeholder="Preliminary idea or topic for your essay"
                value={challenge.ideaOverview}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, ideaOverview: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
              />

              <CustomTextField
                multiline
                label="ANY DRAFT OR OUTLINE OF THE ESSAY THAT YOU MAY HAVE ALREADY CREATED"
                placeholder="Any draft of outline of the essay that you may have alreadycreated"
                inputProps={{
                  style: {
                    height: '150px',
                  },
                }}
                value={challenge.draft}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, draft: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
              />

              <CustomTextField
                multiline
                label="ADDITIONAL INFORMATION OF RELEVANCE TO YOUR ESSAY"
                placeholder="Additional information of relevance to your essay"
                inputProps={{
                  style: {
                    height: '96px',
                  },
                }}
                value={challenge.additionalInfo}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, additionalInfo: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
              />

              <CustomAutoComplete
                options={[]}
                label="SPECIFIC AREAS IN WHICH YOU SEEK FEEDBACK FOR YOUR ESSAY"
                placeholder="List the specific areas in which you seek feedback for your essay, separated by semicolons (;)"
                value={challenge.areasOfFeedback}
                onChange={(e, value) =>
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, areasOfFeedback: value };
                      }
                      return item;
                    })
                  )
                }
              />
            </div>
          </div>
        ))}

      <div
        className={styles.add}
        onClick={() =>
          setNewChallenge([
            ...newChallenge,
            {
              id: crypto.randomUUID(),
              ideaOverview: '',
              draft: '',
              additionalInfo: '',
              areasOfFeedback: [],
            },
          ])
        }
      >
        <AddIcon />
        <p>Add New Essay Topics and Ideas</p>
      </div>
    </>
  );
};

export default EssayTopic;
