import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/AssessmentSummary.module.css';
import { MainContent } from 'components/sidebar';
import {
  Modal,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import { MultipleSelectDocument } from 'components/SelectField';

import {
  Archive,
  ArrowOutward,
  Assessment,
  Checklist,
  ChevronLeft,
  Delete,
  Download,
  Edit,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  Refresh,
  RemoveCircleOutline,
  Send,
  Summarize,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';
import { CustomTextField } from 'components/TextField';

const Tips = ({ assessmentAnswer }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer?.map((section) => (
        <div className={styles.assessment_answer_list}>
          <div className={styles.assessment_body}>
            <div className={styles.assement_answer_header}>
              <h3>{section.title}</h3>
              {/* <p>Score Subtotal: 17/20</p> */}
            </div>
            <div className={styles.border}></div>
          </div>
          {section.questions.map((question, index) => (
            <div className={styles.assessment_tip}>
              <h2 className={styles.review_title_h2}>
                {`${question.question}`}
                <ArrowOutward />
              </h2>

              <p>{question.tipText}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const AssessmentAnswer = ({ assessmentAnswer }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer?.map((section) => (
        <div className={styles.assessment_answer_list}>
          <div className={styles.assessment_body}>
            <div className={styles.assement_answer_header}>
              <h3>{section.title}</h3>
              {/* <p>Score Subtotal: 17/20</p> */}
            </div>
            <div className={styles.border}></div>
          </div>
          {section.questions.map((question, index) => (
            <div className={styles.assessment_mcq}>
              <h2 className={styles.review_title_h2}>
                {`${index + 1}. ${question.question}`}
              </h2>

              <FormControl className={styles.mcq_radio} disabled>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={question.userAnswer}
                >
                  {question.options.map((option, index) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio size="small" />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const GradeSummary = ({ gradeSummary }) => {
  return (
    <div className={styles.grade_section}>
      <div className={styles.grade_section_header_card}>
        <div className={styles.header_card_left}>
          <div>
            <h5>CANDIDATE</h5>
            <p>{`${gradeSummary?.user.firstName} ${gradeSummary?.user.lastName}`}</p>
            <span>{gradeSummary?.user.email}</span>
          </div>

          <div>
            <h5>TOTAL SCORE</h5>
            <p>
              {gradeSummary?.totalScore}/{gradeSummary?.maxTotalScore}
            </p>
          </div>
        </div>
        <div className={styles.header_card_right}>
          <h5>RESULT</h5>
          <p>{gradeSummary?.scoringBracket?.title}</p>
          <h4>{gradeSummary?.scoringBracket?.description}</h4>
        </div>
      </div>

      <div className={styles.grade_section_body}>
        {gradeSummary?.sectionScores.map((section, index) => (
          <>
            <div className={styles.grade_body_item}>
              <div className={styles.grade_body_item_header}>
                <h5>{section.sectionScoringBracket?.title}</h5>
                <p>
                  Score Subtotal: {section.sectionScore}/
                  {section.sectionMaxScore}
                </p>
              </div>
              <p>{section.sectionScoringBracket?.description}</p>
            </div>
            {index !== gradeSummary.sectionScores.length - 1 && (
              <div className={styles.border}></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState('grade');
  const [gradeSummary, setGradeSummary] = useState(null);

  const location = useLocation();
  const assessmentId = location.pathname.split('/')[5];
  const studentId = location.pathname.split('/')[4];

  const handleCandidateNavigation = (e) => {
    navigate(`/coach/psych/candidates/${assessmentId}`);
  };

  const getAssessmentSummary = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentGradeSummary(
        studentId,
        assessmentId
      );

      setGradeSummary(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getAssessmentSummary();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>View Assessment Script</h1>

            <div className={styles.learner_header_buttons}>
              <button
                c
                className={styles.back}
                onClick={handleCandidateNavigation}
              >
                <ChevronLeft />
                Return to Candidate List
              </button>
              {/* <button className={styles.summarize_button}>
                <Refresh />
                Enable Retaking
              </button> */}
            </div>
          </div>
          <div className={styles.border}></div>
          <div className={styles.assessment_summary_header}>
            <CustomTextField
              label="Assessment Name"
              value={`${gradeSummary?.assessment.assessmentName}`}
              readOnly
              fullWidth
            />
            <CustomTextField
              label="Created By"
              value={`Peter Pan`}
              fullWidth
              readOnly
            />
          </div>

          <div className={styles.assessment_summary_nav}>
            <ul>
              <li
                className={activeMenu === 'grade' ? styles.active : ''}
                onClick={() => setActiveMenu('grade')}
              >
                <Assessment />
                <p>Grade Summary</p>
              </li>
              <li
                className={activeMenu === 'answer' ? styles.active : ''}
                onClick={() => setActiveMenu('answer')}
              >
                <Checklist />
                <p>Assessment Answers</p>
              </li>
              <li
                className={activeMenu === 'tip' ? styles.active : ''}
                onClick={() => setActiveMenu('tip')}
              >
                <Checklist />
                <p>Tips</p>
              </li>
            </ul>
          </div>

          {activeMenu === 'grade' && (
            <GradeSummary gradeSummary={gradeSummary?.scores} />
          )}
          {activeMenu === 'answer' && (
            <AssessmentAnswer
              assessmentAnswer={gradeSummary?.assessment.sections}
            />
          )}
          {activeMenu === 'tip' && (
            <Tips assessmentAnswer={gradeSummary?.assessment.sections} />
          )}
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
