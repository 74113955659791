import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Dashboard.module.css';
import { MainContent } from 'components/sidebar';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import { MultipleSelectDocument } from 'components/SelectField';

import { Download } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState('');

  const [canvasList, setCanvasList] = useState([]);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const documentSubmission = searchParams.get('documentSubmission');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [selectDocumentType, setSelectDocumentType] = useState('All');
  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleDateChange = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleOk = (data) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (documentSubmission === 'true') {
      toast.success('Successfully Updated!', {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'documentSubmission',
      });
    }
  }, [documentSubmission]);

  const getTeacherAIDocumentList = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getTeacherAIDocument();
      setCanvasList(response.data.knowledgeFiles);
      setDocumentId(response.data._id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUploadNewDocument = () => {
    navigate('/admin/teacherai/upload');
  };

  const handleFileStatusUpdate = async (fileId, status) => {
    setLoading(true);
    try {
      const data = {
        documentId,
        fileId,
        status: status ? 'active' : 'inactive',
      };
      const response = await organizationAPI.updateTeacherAIDocumentFileStatus(
        data
      );
      getTeacherAIDocumentList();
      toast.success('Document status updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDelete = async (documentId, fileId) => {
    setLoading(true);
    try {
      await organizationAPI.deleteTeacherAIDocumentFile(documentId, fileId);
      getTeacherAIDocumentList();
      toast.success('Document deleted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getTeacherAIDocumentList();
  }, []);

  return (
    <>
      <MainContent active="teacherai" user={user}>
        {loading && <LoadingOverlay />}
        <div className={styles.content}>
          <h1>NFTE Teacher AI</h1>
          <div className={styles.border}></div>
          <div className={styles.learner_header}>
            <h2>Source Documents</h2>
            <div className={styles.learner_header_buttons}>
              <button
                onClick={handleUploadNewDocument}
                className={styles.iteration}
              >
                <AddIcon />
                Upload New Document
              </button>
            </div>
          </div>

          <div className={styles.input_fields}>
            <FormControl
              className={styles.date_range}
              sx={{
                width: '33%',
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  'background-color': 'white',
                },
              }}
            >
              {/* <TextField
                id="date"
                label="Date Range"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <DateRangePicker
                placeholder="Date Range"
                format={'yyyy-MM-dd'}
                character="  to  "
                onChange={handleDateChange}
                onOk={handleOk}
                // ref={dateRangePickerRef}
                onClean={handleClean}
                style={{ minWidth: 200 }}
                className={styles.date_range_picker}
              />
            </FormControl>

            {/* <FormControl
              className={styles.dropdown_section}
              sx={{
                width: '25%',
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  'background-color': 'white',
                },
              }}
            >
              <MultipleSelectDocument
                value={selectDocumentType}
                setValue={setSelectDocumentType}
                input={'Filter by Document Type'}
                label={'Filter by Document Type'}
              />
            </FormControl> */}
            <FormControl
              className={styles.dropdown_section}
              sx={{
                width: '33%',
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  'background-color': 'white',
                },
              }}
            >
              <MultipleSelectDocument
                value={selectDocumentType}
                setValue={setSelectDocumentType}
                input={'Filter by processing status'}
                label={'Filter by processing status'}
              />
            </FormControl>

            <TextField
              id="outlined-basic"
              label="Search by keyword or tag"
              variant="outlined"
              className="search_input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                width: '33%',
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  'background-color': 'white',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.coach_roaster}>
            <div className={styles.learner_table}>
              <table className="styled_table">
                <thead className="table_headers">
                  <tr>
                    <th>Name</th>

                    <th>Uploaded on</th>
                    <th>Uploaded by</th>

                    <th>AI Finetune Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {canvasList.length > 0 &&
                    canvasList
                      .filter((item) => {
                        if (selectDocumentType === 'All') {
                          return item;
                        } else if (selectDocumentType === 'Active') {
                          return item.status === 'active';
                        } else if (selectDocumentType === 'Inactive') {
                          return item.status === 'inactive';
                        }
                      })
                      .filter((item) => {
                        if (search === '') {
                          return item;
                        } else if (
                          item.fileName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <tr className={styles.coachrow} key={item.fileName}>
                            <td className={styles.data}>
                              {item.fileName?.length <= 30
                                ? item.fileName
                                : item.fileName?.slice(0, 30)}
                            </td>
                            <td className={styles.data}>
                              {convertDate(item.uploadedAt)}
                            </td>
                            <td className={styles.data}>{item.uploadedBy}</td>

                            <td className={styles.data}>
                              <div className={styles.checkBox}>
                                <input
                                  type="checkbox"
                                  defaultChecked={item.status === 'active'}
                                  onChange={(e) => {
                                    console.log(e.target.checked);
                                    handleFileStatusUpdate(
                                      item.fileId,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span>Active</span>
                              </div>
                            </td>

                            <td className={styles.data}>
                              <div className={styles.status}>
                                {/* <div className={styles.resend}>
                                <VisibilityOutlinedIcon />
                                <a href={item.url} target="_blank">
                                  View
                                </a>
                              </div> */}
                                <div className={styles.resend}>
                                  <Download />
                                  <a href={item.url}>Download</a>
                                </div>
                                <div className={styles.resend}>
                                  <DeleteOutlineOutlinedIcon />
                                  <button
                                    onClick={() =>
                                      handleDelete(documentId, item.fileId)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {canvasList.length === 0 && (
                <div className={styles.empty_table}>
                  <p>No submissions have been made yet.</p>
                  <p onClick={handleUploadNewDocument} className={styles.link}>
                    Click here to make a new submission template.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
