import { Navigate, Outlet } from 'react-router-dom';
import organizationAPI from 'api/api';
import { useState, useEffect } from 'react';

const RequireAuth = ({ role }) => {
  const [user, setUser] = useState();
  const handleAuth = async () => {
    try {
      const res = await organizationAPI.getMe();
      setUser(res.data.user);
    } catch (error) {}
  };
  useEffect(() => {
    if (!user) {
      handleAuth();
    }
  }, [user]);

  if (user) {
    if (user.role.toLowerCase() !== role)
      return (
        <Navigate
          to={`/${organizationAPI.getOrganizationCodeFromLocalStorage()}/login`}
        />
      );
    else {
      return <Outlet />;
    }
  }
};

export default RequireAuth;
