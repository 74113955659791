import React from 'react';
import { useState } from 'react';
import './login.css';
import google from '../images/Google.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [allEntry, setAllEntry] = useState([]);

  const submitForm = (e) => {
    e.preventDefault();

    console.log(allEntry);
  };

  return (
    <>
      <div className="container">
        <div className="loginform">
          <div>
            <h3>Welcome to NFTE</h3>
            <p className="headline">Please log in to your coach account.</p>
          </div>

          <form action="" onSubmit={submitForm} className="form">
            <button className="login_button" type="submit">
              Log In
            </button>
            <p style={{ margin: 0, fontSize: 12 }}>OR</p>

            <button className="google">
              <img src={google} alt="google" />
              Sign in with Google
            </button>
            <p>Don’t have a coach account?</p>
            <p>Please contact your administrator.</p>
          </form>
        </div>
        <p className="policy">Terms of Use • Privacy Policy</p>
      </div>
    </>
  );
};

export default Login;
