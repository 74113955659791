import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { RemoveModalIcon } from 'svg/CustomSVGIcon';

import LoadingOverlay from 'components/LoadingOverlay';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';

const PersonalExperience = ({
  personalExperiencesAndChallanges,
  getAdmissionTemplateById,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [loading, setLoading] = useState(false);
  const [newChallenge, setNewChallenge] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  const handleRemove = async (id) => {
    setLoading(true);

    const temp = newChallenge
      .filter((challenge) => challenge !== id)
      .map((item) => {
        return {
          faced: item.faced,
          overcome: item.overcome,
          growth: item.growth,
        };
      });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response =
      await organizationAPI.addAdmissionTemplatePersonalExperiences(data);

    await getAdmissionTemplateById();
    setLoading(false);
    handleClose();
  };

  const handleSave = async (e) => {
    setLoading(true);
    const temp = newChallenge.map((item) => {
      return {
        faced: item.faced,
        overcome: item.overcome,
        growth: item.growth,
      };
    });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response =
      await organizationAPI.addAdmissionTemplatePersonalExperiences(data);

    await getAdmissionTemplateById();
    setLoading(false);
  };

  const hanlePersonalExperiencesData = () => {
    const temp = personalExperiencesAndChallanges.map((item) => {
      return {
        id: crypto.randomUUID(),
        faced: item.faced,
        overcome: item.overcome,
        growth: item.growth,
      };
    });
    setNewChallenge(temp);
  };

  useEffect(() => {
    if (personalExperiencesAndChallanges) hanlePersonalExperiencesData();
  }, [personalExperiencesAndChallanges]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className={styles.container_head}>
        <h6>Personal Experiences and Challenges</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
        </div>
      </div>
      <div className={styles.border}></div>

      {newChallenge &&
        newChallenge.map((challenge, index) => (
          <div className={styles.curricular_section} key={challenge}>
            <div className={styles.top}>
              <p>{`Personal Experiences and Challenges ${index + 1}`}</p>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styles.remove_modal}>
                  <RemoveModalIcon />
                  <h2>Remove College</h2>
                  <p>Are you sure you want to remove this college?</p>
                  <div className={styles.remove_modal_buttons}>
                    <button
                      onClick={() => {
                        handleRemove(challenge);
                      }}
                      className={styles.remove}
                    >
                      yes
                    </button>
                    <button onClick={handleClose} className={styles.cancel}>
                      no
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className={styles.input_tab}>
              <CustomTextField
                value={challenge.faced}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, faced: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
                label="CHALLENGE THAT YOU HAVE FACED"
                placeholder="Describe a challenge that you have faced"
                multiline
                disabled
              />
              <CustomTextField
                value={challenge.overcome}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, overcome: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
                label="HOW DID YOU OVERCOME THIS CHALLENGE?"
                placeholder="Describe how you overcame said challenge"
                multiline
                disabled
              />
              <CustomTextField
                value={challenge.growth}
                onChange={(e) => {
                  setNewChallenge(
                    newChallenge.map((item) => {
                      if (item.id === challenge.id) {
                        return { ...item, growth: e.target.value };
                      }
                      return item;
                    })
                  );
                }}
                label="HOW HAS THIS EXPERIENCE CONTRIBUTED TO YOUR GROWTH?"
                placeholder="Explain how this experience contributed to your growth"
                multiline
                disabled
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default PersonalExperience;
