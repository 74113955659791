import React, { useState, useEffect } from 'react';
import styles from './NfteForget.module.css';
import NfteLogo from 'images/nfte_logo.png';
import adminAPI from 'api/api';
import { useLocation, useNavigate } from 'react-router-dom';

import { TextField, Box } from '@mui/material';

import LoadingOverlay from 'components/LoadingOverlay';
import { CustomTextField } from 'components/TextField';
import { CustomButton } from 'components/Button';

const NFTEForget = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationCode = location.pathname.split('/')[1];

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [recipientEmailErrorMessage, setRecipientEmailErrorMessage] =
    useState('');

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setRecipientEmailErrorMessage(`Enter valid Email(s)`);
    } else {
      setRecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (email) validateEmail(email);
    else setRecipientEmailErrorMessage('');
  }, [email]);

  const [error, setError] = React.useState('');

  const handleForget = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      email,
      organizationCode,
    };

    const response = await adminAPI.organizationUserForget(data);

    setLoading(false);
    if (response.status === 200 && response.data.success) {
      setError('');
      navigate(`/${organizationCode}/login`);
    } else {
      setError(response.data.error);
    }
  };

  return (
    <div className={styles.container}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}

      <div className={styles.login}>
        <div className={styles.login_container}>
          <img src={NfteLogo} alt="NFTE Logo" />
          {error && <p className={styles.error}>{error}</p>}
          <h1>Forget Password</h1>

          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <CustomTextField
              error={recipientEmailErrorMessage}
              label="EMAIL ADDRESS"
              value={email}
              helperText={recipientEmailErrorMessage}
              onChange={(e) => setEmail(e.target.value)}
            />

            <CustomButton
              className={styles.signInButton}
              onClick={handleForget}
            >
              Send Recovery Link
            </CustomButton>
          </Box>

          <a className={styles.loginText} href={`/${organizationCode}/login`}>
            Back to Login
          </a>
        </div>
      </div>
      {/* <div className={styles.branding}>
        <div className={styles.brainding_section}>
          <h1>This is an extremely exciting title!</h1>
          <h3>
            And here is some extremely exciting body text to go with it. You
            should really schedule a demo. Yeah.
          </h3>
          <a href="#">Schedule Demo</a>
        </div>
      </div> */}
    </div>
  );
};

export default NFTEForget;
