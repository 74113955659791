import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles/Dashboard.module.css';
import { MainContent } from 'components/sidebar';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import organizationAPI from 'api/api';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import LoadingOverlay from 'components/LoadingOverlay';
import SendIcon from '@mui/icons-material/Send';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Close,
  Done,
  MoreHoriz,
  OpenInNew,
  QueryStats,
} from '@mui/icons-material';
import { ProfileIcon } from 'components/sidebaricon';
import { SidebarCollapseIcon } from 'svg/CustomSVGIcon';
import Lottie from 'react-lottie';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import spinnerData from 'components/lottieConfig/spinner.json';
import { RemoveCircleOutline } from '@mui/icons-material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Link } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinnerData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ShareModal = ({ open, setOpen, setLoading }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,

    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={styles.share_modal}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.share_modal_title}
        >
          <h5>Share Link to Chat</h5>
          <Close onClick={() => setOpen(false)} />
        </Typography>
        <p>
          Messages you send after creating your link will not be shared. Anyone
          with the URL will be able to view the shared chat.
        </p>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.more_info}>
            More Info <OpenInNew />
          </button>
          <button
            onClick={() => setOpen(false)}
            className={styles.copy_new_link}
          >
            <Link /> Copy Link
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveModal = ({
  open,
  setOpen,
  setLoading,
  selectedRemoveQuestion,
  getAIChat,
  setSelectedQuestion,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,

    p: 4,
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setSelectedQuestion(null);
      await organizationAPI.deleteAIChat(selectedRemoveQuestion._id);
      await getAIChat();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <DeleteOutlineOutlinedIcon
            style={{
              color: '#E03838',
              fontSize: '48px',
            }}
          />
          <h5>Delete Chat</h5>
          <p>
            Are you sure you want to delete the chat ‘What is the best way to
            perform accounting audits?’? This action cannot be undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={handleRemove} className={styles.remove}>
            Delete Chat
          </button>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const DragAndDrop = ({ onDrop, files, setFiles }) => {
  const navigate = useNavigate();

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles([...files, ...acceptedFiles]);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropCallback,
    noClick: true,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.upload}>
      <div {...getRootProps()} onClick={open}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <div className={styles.upload_header}>
              <AttachFileOutlinedIcon />
              <div className={styles.upload_text}>
                <h4>
                  Drag a document file here, or click here to browse for a
                  document file.
                </h4>
                <p>
                  After adding the documents, please use the prompt field below
                  to provide further instructions/context or ask further
                  questions.
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {files.length > 0 &&
        files.map((file) => (
          <div className={styles.upload_bottom} key={file.name}>
            <ArticleOutlinedIcon />
            <p>{file.name}</p>
            <div className={styles.border}></div>
            <CloseOutlinedIcon onClick={() => removeFile(file)} />
          </div>
        ))}
    </div>
  );
};

const AssistantAI = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [replyBody, setReplyBody] = useState('');
  const [disableReplyButton, setDisableReplyButton] = useState(true);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [hideSection, setHideSection] = useState(false);

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const endOfMessagesRef = useRef(null);

  const [selectedRename, setSelectedRename] = useState(null);
  const [thinking, setThinking] = useState(false);

  const [startNewChat, setStartNewChat] = useState(true);
  const [startNewFileChat, setStartNewFileChat] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const [groupedQuestions, setGroupedQuestions] = useState(null);
  const [selectedDateGroup, setSelectedDateGroup] = useState(null);
  const [selectedThreadIndex, setSelectedThreadIndex] = useState(null);
  const [selectedRemoveQuestion, setSelectedRemoveQuestion] = useState(null);

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    setFiles([...files, ...acceptedFiles]);
  }, []);

  const handleThreadClick = (index, date) => {
    // Check if the menu is not open
    setSelectedThreadIndex(index);
    setSelectedDateGroup(date);
    setSelectedDropdown(null);
    setTimeout(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const groupThreads = (questions) => {
    const groupedThreads = questions.reduce((groups, question) => {
      const date = moment(question.createdAt);
      let dateKey;

      if (date.isSame(moment(), 'day')) {
        dateKey = 'Today';
      } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
        dateKey = 'Yesterday';
      } else if (date.isAfter(moment().subtract(30, 'days'))) {
        dateKey = 'Previous 30 Days';
      } else {
        dateKey = date.format('MMMM YYYY');
      }

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }

      groups[dateKey].push(question);

      return groups;
    }, {});

    if (selectedDateGroup === null) {
      Object.keys(groupedThreads).forEach((key, index) => {
        if (index === 0) {
          setSelectedDateGroup(key);
        }
      });
    }
    console.log(groupedThreads);
    setGroupedQuestions(groupedThreads);
  };

  useEffect(() => {
    if (replyBody.length > 0) {
      setDisableReplyButton(false);
    } else {
      setDisableReplyButton(true);
    }
  }, [replyBody]);

  const handleReply = async (e) => {
    e.preventDefault();

    setDisableReplyButton(true);
    setThinking(true);

    if (startNewChat) {
      const data = {
        question: replyBody,
      };

      setReplyBody('');

      setThinking(true);
      setTimeout(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
      const response = await organizationAPI.createAIChat(data);

      const reader = response.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          console.log('End of stream');
          setThinking(false);
          break;
        }
        let chunk = new TextDecoder('utf-8').decode(value);

        // Remove 'data: "' prefix and the last double quote
        chunk = chunk.replace(/^data: /, ''); // Remove 'data:' prefix and any following spaces
        chunk = chunk.replace(/"/g, ''); // Remove double quotes

        // Replace all escaped characters with their unescaped versions
        // chunk = chunk.replace(/\\(.)/g, '$1');

        chunk = chunk.replace(/\\n/g, '\n');
        const message = chunk.split('').join('');

        console.log(message);
      }

      await getAIChat();
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });

      setThinking(false);
      setStartNewChat(false);
    } else if (startNewFileChat) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });

      formData.append('question', replyBody);

      await organizationAPI
        .createAIChatWithFile(formData)
        .then((res) => {
          setFiles([]);
          setReplyBody('');
          setStartNewFileChat(false);
          setThinking(false);
          getAIChat();
        })
        .catch((err) => {});
    } else {
      const data = {
        question: replyBody,
        questionId: selectedQuestion._id,
      };

      let tempGroupedQuestions = { ...groupedQuestions };
      tempGroupedQuestions[selectedDateGroup][
        selectedThreadIndex
      ].messages.push({
        text: replyBody,
        role: 'user',
      });

      setGroupedQuestions(tempGroupedQuestions);
      setReplyBody('');
      setSelectedQuestion(
        tempGroupedQuestions[selectedDateGroup][selectedThreadIndex]
      );

      setThinking(true);
      setTimeout(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);

      const response = await organizationAPI.createAIChat(data);
      const reader = response.getReader();
      tempGroupedQuestions[selectedDateGroup][
        selectedThreadIndex
      ].messages.push({
        text: '',
        role: 'assistant',
      });
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          console.log('End of stream');
          setThinking(false);
          break;
        }
        let chunk = new TextDecoder('utf-8').decode(value);

        // Remove 'data: "' prefix and the last double quote
        // chunk = chunk.replace(/^data: /, ''); // Remove 'data:' prefix and any following spaces
        chunk = chunk.replace(/"/g, ''); // Remove double quotes

        // Replace all escaped characters with their unescaped versions
        // chunk = chunk.replace(/\\(.)/g, '$1');

        chunk = chunk.replace(/\\n/g, '\n');
        const message = chunk.split('').join('');

        console.log(message);
        tempGroupedQuestions[selectedDateGroup][selectedThreadIndex].messages[
          tempGroupedQuestions[selectedDateGroup][selectedThreadIndex].messages
            .length - 1
        ].text += message;

        setGroupedQuestions(tempGroupedQuestions);

        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
      }

      await getAIChat();
      setThinking(false);
    }
  };

  const getAIChat = async () => {
    try {
      const response = await organizationAPI.getAIChat();
      groupThreads(response.data.reverse());
      if (response.data.length > 0 && selectedQuestion === null) {
        setStartNewChat(false);
        setSelectedQuestion(response.data[0]);
        setSelectedThreadIndex(0);
        setSelectedDateGroup('Today');
      }

      setTimeout(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!dropdownRef.current?.contains(event.target)) {
        setSelectedDropdown(null);
      }
    };

    window.addEventListener('mousedown', handleOutSideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutSideClick);
    };
  }, [dropdownRef]);

  useEffect(() => {
    getMe();
    setLoading(true);
    getAIChat();
  }, []);

  return (
    <>
      <MainContent active="assisstantAI" user={user}>
        {loading && <LoadingOverlay />}
        <RemoveModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          setLoading={setLoading}
          selectedRemoveQuestion={selectedRemoveQuestion}
          getAIChat={getAIChat}
          setSelectedQuestion={setSelectedQuestion}
        />
        <ShareModal
          open={shareModalOpen}
          setOpen={setShareModalOpen}
          setLoading={setLoading}
        />

        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>NFTE Assistant AI</h1>
          </div>
          <div className={styles.border}></div>

          <div className={styles.learner_body}>
            {!hideSection && (
              <div className={styles.chatBox}>
                <div className={styles.questionList}>
                  <button
                    onClick={() => {
                      setStartNewChat(true);
                      setStartNewFileChat(false);
                      setSelectedQuestion(null);
                      setReplyBody('');
                      setThinking(false);
                    }}
                  >
                    Start new Question <NoteAltOutlinedIcon />
                  </button>
                  <div className={styles.questionList_scroll}>
                    {groupedQuestions &&
                      Object.entries(groupedQuestions).map(
                        ([date, chats], index) => (
                          <div key={index}>
                            <h3>{date}</h3>
                            <ul>
                              {chats.map((chat, index) => (
                                <li key={index}>
                                  {selectedRename !== null &&
                                  selectedRename.index === index &&
                                  selectedRename.date === date ? (
                                    <TextField
                                      id="outlined-basic"
                                      label={'Rename Chat'}
                                      value={chat.messages[0].text}
                                      size="small"
                                      fullWidth
                                    />
                                  ) : (
                                    <span
                                      onClick={() => {
                                        handleThreadClick(index, date);
                                        setSelectedQuestion(chat);
                                        setReplyBody('');
                                        setStartNewChat(false);
                                        setStartNewFileChat(false);
                                      }}
                                    >
                                      {chat.messages[0].text.length > 35
                                        ? chat.messages[0].text.substring(
                                            0,
                                            35
                                          ) + '...'
                                        : chat.messages[0].text}
                                    </span>
                                  )}{' '}
                                  {selectedRename !== null &&
                                  selectedRename.index === index &&
                                  selectedRename.date === date ? (
                                    <div className={styles.rename_buttons}>
                                      <Done
                                        onClick={() => setSelectedRename(null)}
                                      />
                                      <Close
                                        onClick={() => setSelectedRename(null)}
                                      />
                                    </div>
                                  ) : (
                                    <MoreHoriz
                                      onClick={() => {
                                        if (
                                          selectedDropdown &&
                                          selectedDropdown.date === date &&
                                          selectedDropdown.index === index
                                        ) {
                                          setSelectedDropdown(null);
                                        } else {
                                          setSelectedDropdown({
                                            date: date,
                                            index,
                                          });
                                        }
                                      }}
                                    />
                                  )}
                                  {selectedDropdown &&
                                    selectedDropdown.date === date &&
                                    selectedDropdown.index === index && (
                                      <div
                                        className={styles.dropDown}
                                        ref={dropdownRef}
                                      >
                                        {/* <p
                                        onClick={() => {
                                          setShareModalOpen(true);
                                          setSelectedDropdown(null);
                                        }}
                                      >
                                        <ShareOutlinedIcon />
                                        Share
                                      </p> */}
                                        {/* <p
                                          onClick={() => {
                                            if (selectedRename !== null) {
                                              setSelectedRename(null);
                                            } else {
                                              setSelectedRename({
                                                date: date,
                                                index,
                                              });
                                            }

                                            setSelectedDropdown(null);
                                          }}
                                        >
                                          <EditOutlinedIcon />
                                          Rename
                                        </p> */}
                                        <p
                                          onClick={() => {
                                            setRemoveModalOpen(true);
                                            setSelectedRemoveQuestion(chat);
                                            setSelectedDropdown(null);
                                          }}
                                        >
                                          <DeleteOutlineOutlinedIcon />
                                          Delete Chat
                                        </p>
                                      </div>
                                    )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                      )}
                  </div>
                </div>
                {/* <div className={styles.learner_body_left}>
                <>
                  <ChatBubbleOutlineOutlinedIcon />
                  <h4>There are no chats.</h4>
                </>
              </div> */}
                <p>
                  NFTE Assistant AI is pretty amazing, but it’s not completely
                  infallible. Please double-check all important information.
                </p>
              </div>
            )}
            <div
              className={`${styles.learner_body_right} ${
                hideSection && styles.hideSectionWidth
              }`}
            >
              <SidebarCollapseIcon
                className={styles.collapseIcon}
                onClick={() => setHideSection(!hideSection)}
              />
              <div className={styles.learner_scroll}>
                {!startNewChat && !startNewFileChat && (
                  <>
                    {Object.keys(groupedQuestions).length !== 0 &&
                      groupedQuestions?.[selectedDateGroup]?.[
                        selectedThreadIndex
                      ]?.messages
                        ?.slice()
                        .map((message, index) => (
                          <div key={index} className={styles.question_message}>
                            {message.role === 'user' ? (
                              <>
                                <div
                                  className={styles.learner_body_right_header}
                                >
                                  <ProfileIcon />
                                  <h4>You</h4>
                                </div>
                                <div className={styles.message}>
                                  <p>{message.text}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={styles.learner_body_right_header}
                                >
                                  <AssistantOutlinedIcon />
                                  <h4>NFTE Assistant AI</h4>
                                </div>
                                <div className={styles.message}>
                                  {message.text
                                    .split(/\n(?=###|\d\.|-)/)
                                    .map((section) =>
                                      section.replace(/###|\*\*/g, '').trim()
                                    )
                                    .filter(Boolean)
                                    .map((section) => {
                                      const parts = section.split(':');
                                      if (parts.length > 1) {
                                        return (
                                          <p key={index}>
                                            <strong>{parts[0].trim()}</strong>
                                            {`: ${parts
                                              .slice(1)
                                              .join(':')
                                              .trim()}`}
                                          </p>
                                        );
                                      } else {
                                        return <p key={index}>{section}</p>;
                                      }
                                    })}
                                  {/* {message.text} */}
                                  <div ref={endOfMessagesRef} />
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                  </>
                )}
                {thinking && (
                  <div className={styles.question_message}>
                    <div className={styles.learner_body_right_header}>
                      <AssistantOutlinedIcon />
                      <h4>NFTE Assistant AI</h4>
                    </div>
                    <div className={styles.lottie_section}>
                      <p>
                        <Lottie
                          options={defaultOptions}
                          height={20}
                          width={20}
                          style={{
                            margin: 0,
                          }}
                        />
                        <span>Generating response...</span>
                      </p>
                      <p>
                        <QuizOutlinedIcon />
                        <span>
                          {' '}
                          Formulating query to retrieve relevant information
                        </span>
                      </p>
                      <p>
                        <QueryStats />
                        Retrieving relevant and authoritative results
                        <span>
                          Retrieving relevant and authoritative results
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {startNewChat && !startNewFileChat && !thinking && (
                <>
                  <div className={styles.learner_body_right_header}>
                    <AssistantOutlinedIcon />
                    <h4>NFTE Assistant AI</h4>
                  </div>
                  <h3>How can I assist you today?</h3>
                  <div className={styles.button_section}>
                    <button className={styles.answer_button}>
                      <QuizOutlinedIcon />
                      Answer a Question
                    </button>
                    <button
                      className={styles.upload_button}
                      onClick={() => {
                        setStartNewChat(false);
                        setStartNewFileChat(true);
                        setSelectedQuestion(null);
                      }}
                    >
                      <UploadOutlinedIcon />
                      Upload and Summarize a Document
                    </button>
                  </div>
                </>
              )}

              {!startNewChat && startNewFileChat && !thinking && (
                <>
                  <div className={styles.learner_body_right_header}>
                    <AssistantOutlinedIcon />
                    <h4>NFTE Assistant AI</h4>
                  </div>
                  <p>
                    Hello! Would you like me to summarize a document for you?
                  </p>
                  <DragAndDrop
                    onDrop={handleDrop}
                    files={files}
                    setFiles={setFiles}
                  />
                </>
              )}
              <div className={styles.learner_body_right_footer}>
                {startNewChat && !thinking && (
                  <>
                    <h6>Examples of Questions to Ask:</h6>
                    <p>What are the key steps to starting my own business?</p>
                    <p>How do I create a compelling business plan?</p>
                    <p>How do I find funding for my startup?</p>
                  </>
                )}

                <TextField
                  id="outlined-controlled"
                  label="TYPE YOUR PROMPT HERE"
                  placeholder="Type your reply here"
                  multiline
                  fullWidth
                  value={replyBody}
                  onChange={(e) => setReplyBody(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Button
                          className={
                            disableReplyButton
                              ? styles.disableReplyButton
                              : styles.reply
                          }
                          variant="outlined"
                          style={{ fontWeight: 'bold' }}
                          disabled={disableReplyButton}
                          onClick={handleReply}
                        >
                          <SendOutlinedIcon />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default AssistantAI;
