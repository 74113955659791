import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import {
  TextField,
  InputAdornment,
  Autocomplete,
  Chip,
  FormControl,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { SelectCollege } from 'components/SelectField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { RemoveModalIcon } from 'svg/CustomSVGIcon';
import SaveIcon from '@mui/icons-material/Save';
import LoadingOverlay from 'components/LoadingOverlay';
import organizationAPI from 'api/api';
import { CustomAutoComplete } from 'components/AutoComplete';

const SpecificInterests = ({ specificInterests, getAdmissionTemplateById }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];
  const [newUniversity, setNewUniversity] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  const handleRemove = async (id) => {
    setLoading(true);
    const temp = newUniversity
      .filter((challenge) => challenge !== id)
      .map((item) => {
        return {
          college: item.college,
          reasons: item.reasons,
          programInterest: item.programInterest,
          courseInterest: item.courseInterest,
          professorInterest: item.professorInterest,
          campusInterest: item.campusInterest,
        };
      });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response = await organizationAPI.addAdmissionTemplateSpecificInterest(
      data
    );

    await getAdmissionTemplateById();
    setLoading(false);
    handleClose();
  };

  const handleSave = async (e) => {
    setLoading(true);
    const temp = newUniversity.map((item) => {
      return {
        college: item.college,
        reasons: item.reasons,
        programInterest: item.programInterest,
        courseInterest: item.courseInterest,
        professorInterest: item.professorInterest,
        campusInterest: item.campusInterest,
      };
    });

    const data = {
      templateId: admissionId,
      data: temp,
    };

    const response = await organizationAPI.addAdmissionTemplateSpecificInterest(
      data
    );

    await getAdmissionTemplateById();
    setLoading(false);
  };

  const hanleSpecificInterestsData = () => {
    const temp = specificInterests.map((item) => {
      return {
        id: crypto.randomUUID(),
        college: item.college,
        reasons: item.reasons,
        programInterest: item.programInterest,
        courseInterest: item.courseInterest,
        professorInterest: item.professorInterest,
        campusInterest: item.campusInterest,
      };
    });
    setNewUniversity(temp);
  };

  useEffect(() => {
    if (specificInterests) hanleSpecificInterestsData();
  }, [specificInterests]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className={styles.container_head}>
        <h6>Specific Interests in the College</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
        </div>
      </div>
      <div className={styles.border}></div>

      {newUniversity &&
        newUniversity.map((university, index) => (
          <div className={styles.body}>
            <div className={styles.college_section}>
              <div className={styles.top}>
                <p>{`Specific Interests in the College ${index + 1}`}</p>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className={styles.remove_modal}>
                    <RemoveModalIcon />
                    <h2>Remove College</h2>
                    <p>Are you sure you want to remove this college?</p>
                    <div className={styles.remove_modal_buttons}>
                      <button
                        onClick={() => handleRemove(university)}
                        className={styles.remove}
                      >
                        yes
                      </button>
                      <button onClick={handleClose} className={styles.cancel}>
                        no
                      </button>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div className={styles.input_tab}>
                <CustomAutoComplete
                  options={['Auburn University', 'Adelphi University']}
                  value={university.college}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, college: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="NAME OF SCHOOL"
                  placeholder="Select the name of the school you are applying to"
                  readOnly
                />
              </div>
              <div className={styles.programs}>
                <CustomAutoComplete
                  className={styles.autocomplete}
                  options={[]}
                  value={university.reasons}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, reasons: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="REASONS FOR APPLYING TO THIS SPECIFIC SCHOOL"
                  placeholder="List your reasons for applying to this school here, separated by semicolons (;)"
                  readOnly
                />
              </div>
              <div className={styles.programs}>
                <CustomAutoComplete
                  className={styles.autocomplete}
                  options={[]}
                  value={university.programInterest}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, programInterest: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="PROGRAMS OF INTEREST"
                  placeholder="List programs of interest to you, separated by semicolons (;)"
                  readOnly
                />
                <CustomAutoComplete
                  className={styles.autocomplete}
                  options={[]}
                  value={university.courseInterest}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, courseInterest: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="COURSES OF INTEREST"
                  placeholder="List courses of interest to you, separated by semicolons (;)"
                  readOnly
                />
              </div>
              <div className={styles.programs}>
                <CustomAutoComplete
                  className={styles.autocomplete}
                  options={[]}
                  value={university.professorInterest}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, professorInterest: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="PROFESSORS OF INTEREST"
                  placeholder="List professors of interest to you, separated by semicolons (;)"
                  readOnly
                />
                <CustomAutoComplete
                  className={styles.autocomplete}
                  options={[]}
                  value={university.campusInterest}
                  onChange={(e, value) =>
                    setNewUniversity(
                      newUniversity.map((item) => {
                        if (item.id === university.id) {
                          return { ...item, campusInterest: value };
                        }
                        return item;
                      })
                    )
                  }
                  label="CAMPUS ORGANIZATIONS OF INTEREST"
                  placeholder="List campus organizations of interest to you, separated by semicolons (;)"
                  readOnly
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default SpecificInterests;
