import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Dashboard.module.css';
import { MainContent, Sidebar } from 'components/sidebar';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';
import { UploadIcon } from 'svg/CustomSVGIcon';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useNavigate } from 'react-router-dom';

const DragAndDrop = ({
  onDrop,
  buttonDisabled,
  setButtonDisabled,
  setLoading,
}) => {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles([...files, ...acceptedFiles]);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDropCallback,
    noClick: true,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('documentId', '65fbc479837994a78f9f45d2');

    const response = await organizationAPI.uploadTeacherAI(formData);
    setLoading(false);

    navigate('/admin/teacherai/dashboard?documentSubmission=true');
  };

  useEffect(() => {
    if (files.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [files]);

  return (
    <div className={styles.dropBox}>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          color: '#888',
          textAlign: 'center',
          marginBottom: '20px',
        }}
        className={styles.dropBox}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <div className={styles.mid_section}>
              <p
                onClick={open}
                style={{
                  cursor: 'pointer',
                }}
              >
                <UploadIcon />{' '}
              </p>
              <p>
                To upload your study material documents,{' '}
                <span
                  onClick={open}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  click here
                </span>
                , or
              </p>
              <p>
                directly drag the files into this space from your file browser
              </p>
              {files.length > 0 && (
                <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
                  {files.map((file) => (
                    <li
                      key={file.name}
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center',
                        zIndex: 100,
                      }}
                    >
                      <div>{file.name}</div>
                      <div>
                        <CloseIcon onClick={() => removeFile(file)} />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <div className={styles.upload_button}>
                <button onClick={handleFileUpload} disabled={buttonDisabled}>
                  <SendOutlinedIcon />
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Upload = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [files, setFiles] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (files.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [files]);

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    setFiles([...files, ...acceptedFiles]);
  }, []);

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <MainContent active="teacherai" user={user}>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <h1>NFTE Teacher AI</h1>
        <div className={styles.border}></div>

        <div className={styles.coach_roaster}>
          <div className={styles.upload_section}>
            <DragAndDrop
              buttonDisabled={buttonDisabled}
              onDrop={handleDrop}
              setButtonDisabled={setButtonDisabled}
              setLoading={setLoading}
            />
            <div className={styles.upload_bottom}>
              <p>Supported file formats: .pdf, .doc, .docx, .ppt, .pptx</p>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default Upload;
