import React, { useState, useEffect } from 'react';
import '../styles/UserDashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import api from '../api/api';

function UserDashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');

  // const updateActive = (id) => {
  //   setListData((prevListData) => {
  //     const newListData = prevListData.map((item) => {
  //       if (item.id === id) {
  //         return { ...item, active: !item.active };
  //       }
  //       return item;
  //     });
  //     return newListData;
  //   });
  // };

  const getUserProfileData = async () => {
    try {
      const response = await api.getUserProfile();
    } catch (error) {
      console.log(error);
    }
  };

  const LogoutButton = () => {
    const handleLogout = () => {
      // Perform logout actions here
      // ...

      // Navigate to the landing page
      navigate('/');
    };

    return (
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    );
  };
  
  const updateActive = (id) => {
    setListData((prevListData) => {
      const newListData = prevListData.map((item) => {
        if (item.id === id) {
          setSelectedQuestion(id);
          return { ...item, active: true };
        }
        return { ...item, active: false }; // Set active to false for all other items
      });
      return newListData;
    });
  };
  const getQuestionsList = async () => {
    setIsLoading(true);
    try {
      const response = await api.getUserQuestions();
      setListData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const showAlert = (message) => {
    alert(message);
  };

  const SubChatList = ({ subListData }) => {
    return (
      <>
        {subListData.map((item, index) => (
          <div key={index}>
            <div>
              <div className="chat-meta-div-parent">
                <span className="coach-name-text">{item.name}</span>
                <div className="pm-coach-box">
                  <span className="pm-coach-text">{item.type}</span>
                </div>
                <span className="chat-date-2">{item.date}</span>
              </div>
              <p className="chat-text">{item.body}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  const ChatList = ({ listData, updateActive }) => {
    const [question, setQuestion] = useState('');

    const submitQuestion = async () => {
      setIsLoading(true);
      try {
        await api.submitQuestion({
          question_id: selectedQuestion,
          question,
          coach_id: api.getCoachIDToLocalStorage(),
        });
        getQuestionsList();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        showAlert('Somethign went wrong try again later');
        setIsLoading(false);
      }
    };
    return (
      <>
        {listData.map((item, index) => (
          <div key={index}>
            {item.active ? (
              <div className="row-chat-open">
                <span className="chat-date">{item.date}</span>
                <div className="chat-title-div">
                {item.question.length < 50 ? (
                    <span className="chat-title" id="chatTitle">
                      {item.question}
                    </span>
                  ) : (
                    <span className="chat-title" id="chatTitle">
                      {item.question.substring(0, 50)}...
                    </span>
                  )}
                  <Link onClick={() => updateActive(item.id)}>
                    <AiOutlineUp />
                  </Link>
                </div>
                <span className="my-answer-text">My Answer</span>
                <div className="horozontal-line"></div>
                {item.userAnswer === null ? (
                  <p className="chat-text">
                    {'You did not have answer for this questions'}
                  </p>
                ) : (
                  <p className="chat-text">{item.userAnswer}</p>
                )}
                <div className="divider-line "></div>
                <div>
                  <SubChatList subListData={item.thread} />
                </div>

                <div className="reply-box-parent">
                  <div className="chat-meta-div-parent">
                    <span className="coach-name-text">{item.name}</span>
                    <div className="client-box">
                      <span className="pm-coach-text">Member</span>
                    </div>
                  </div>
                  <textarea
                    className="reply-textarea"
                    placeholder="Your reply"
                    value={question}
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                  ></textarea>
                  <div className="chat-meta-div-parent">
                    <button
                      className="post-reply-button"
                      onClick={submitQuestion}
                    >
                      <span className="post-reply-text">Post Reply</span>
                    </button>
                    {/* <button className="confirm-close-button">
                      <span className="confirm-close-text">
                        Confirm and Close Thread
                      </span>
                    </button> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row-chat-close">
                <span className="chat-date">{item.date}</span>
                <div className="chat-title-div">
                  {item.question.length < 50 ? (
                    <span className="chat-title" id="chatTitle">
                      {item.question}
                    </span>
                  ) : (
                    <span className="chat-title" id="chatTitle">
                      {item.question.substring(0, 50)}...
                    </span>
                  )}
                  <Link onClick={() => updateActive(item.id)}>
                    <AiOutlineDown />
                  </Link>
                </div>
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    getUserProfileData();
    getQuestionsList();
  }, []);

  return (
    <div className="parent-container-user-dash">
       <LogoutButton />
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p className="loading-message">Submit for Vetting" : "Processing time may be extended</p>
        </div>
      )}
      <div className="main-container">
        <span className="head-text">Your Chat Archive</span>
        <Link to="/newQuestion" style={{ textDecoration: 'none' }}>
          <button className="new-post-button">
            <span className="new-post-text">Post New Chat</span>
          </button>
        </Link>
      </div>
      <div>
        <p className="body-text">
        Welcome to your chat archive. This is a list of all the queries you have submitted.
        </p>
      </div>
      <div className="parent-chat-box">
        <div className="chat-box">
          <ChatList listData={listData} updateActive={updateActive} />
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;