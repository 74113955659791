import { Box, LinearProgress } from '@mui/material';

const CustomLinearProgress = ({ value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          sx={{
            '& .MuiLinearProgress-bar1Determinate': {
              backgroundColor: '#4c12a1',
            },
          }}
          variant="determinate"
          value={value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <p>{`${Math.round(value)}%`}</p>
      </Box>
    </Box>
  );
};

export { CustomLinearProgress };
