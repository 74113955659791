import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Summary.module.css';
import Person from '../../../images/Ellipse.png';

const Questions = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <h6>Questions Raised During the Conversation</h6>
        <div className={styles.border}></div>

        <div className={styles.questions_section}>
          <img src={Person} alt="" />
          <h6>0:15</h6>
          <p>
            You've got to work like someone is trying to steal your business.
          </p>
        </div>
        <div className={styles.questions_section}>
          <img src={Person} alt="" />
          <h6>0:35</h6>
          <p>
            Always wake up with a smile knowing that today you are going to have
            fun accomplishing what others are too afraid to do.
          </p>
        </div>
        <div className={styles.questions_section}>
          <img src={Person} alt="" />
          <h6>0:15</h6>
          <p>
            Doesn't matter if the glass is half-empty or half-full. All that
            matters is that you are the one pouring the water.
          </p>
        </div>
      </div>
    </>
  );
};

export default Questions;
