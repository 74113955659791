import React, { useState, useEffect } from 'react';
import styles from './NfteReset.module.css';
import NfteLogo from 'images/nfte_logo.png';
import adminAPI from 'api/api';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  TextField,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import LoadingOverlay from 'components/LoadingOverlay';
import { CustomTextField } from 'components/TextField';
import { CustomButton } from 'components/Button';

const NFTEReset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resetToken = new URLSearchParams(location.search).get('resetToken');

  const organizationCode = location.pathname.split('/')[1];

  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [error, setError] = React.useState('');

  useEffect(() => {
    if (
      password !== '' &&
      confirmPassword !== '' &&
      password !== confirmPassword
    ) {
      setPasswordMatch(false);
      setErrorMessage('Passwords do not match');
    } else {
      setPasswordMatch(true);
      setErrorMessage('');
    }
  }, [password, confirmPassword]);

  const handleForget = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return;
    }

    setLoading(true);

    const data = {
      organizationCode,
      resetToken,
      password,
    };

    const response = await adminAPI.organizationUserReset(data);

    setLoading(false);
    if (response.status === 200 && response.data.success) {
      setError('');
      navigate(`/${organizationCode}/login`);
    } else {
      setError(response.data.error);
    }
  };

  return (
    <div className={styles.container}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}

      <div className={styles.login}>
        <div className={styles.login_container}>
          <img src={NfteLogo} alt="NFTE Logo" />
          {error && <p className={styles.error}>{error}</p>}
          <h1>Reset Password</h1>

          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl className={styles.formControl} variant="filled">
              <CustomTextField
                label="Password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                helperText={!passwordMatch ? errorMessage : ''}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl className={styles.formControl} variant="filled">
              <CustomTextField
                label="Confirm Password"
                value={confirmPassword}
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) => setConfirmPassword(e.target.value)}
                helperText={!passwordMatch ? errorMessage : ''}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <CustomButton
              className={styles.signInButton}
              onClick={handleForget}
            >
              Reset Password
            </CustomButton>
          </Box>

          <a className={styles.loginText} href={`/${organizationCode}/login`}>
            Back to Login
          </a>
        </div>
      </div>
      {/* <div className={styles.branding}>
        <div className={styles.brainding_section}>
          <h1>This is an extremely exciting title!</h1>
          <h3>
            And here is some extremely exciting body text to go with it. You
            should really schedule a demo. Yeah.
          </h3>
          <a href="#">Schedule Demo</a>
        </div>
      </div> */}
    </div>
  );
};

export default NFTEReset;
