import { Autocomplete, Chip, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
const usestyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      'border-color': '#DAE8E7',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
      {
        'border-color': '#4C12A1',
      },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        'border-color': '#4C12A1',
      },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
      {
        'border-color': '#DAE8E7',
      },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline':
      {
        'border-color': '#DAE8E7',
      },
    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiButtonBase-root.MuiIconButton-root:hover':
      {
        color: '#4C12A1',
        'background-color': 'transparent',
      },
  },

  inputRoot: {
    '& .MuiAutocomplete-inputRoot': {
      fontFamily: 'Lexend',
    },
    '& .MuiInputLabel-outlined': {
      fontFamily: 'Lexend',
    },
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: '#5B706F',
    },
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#F3F8F8',
      color: '#243030',
      fontFamily: 'Lexend',
      fontSize: '14px',
      fontWeight: '400',
      '& .MuiChip-deleteIcon': {
        color: '#5B706F',
      },
    },
  },
});

const textStyle = {
  fontFamily: 'Lexend',
  '& .MuiInputBase-root': {
    '& > fieldset': {
      borderColor: '#DAE8E7',
    },
    '&:hover fieldset': {
      borderColor: '#4C12A1',
    },

    '&.Mui-focused fieldset': {
      borderColor: '#4C12A1',
      color: '#243030',
    },
    '&.Mui-focused svg': {
      color: '#4C12A1',
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-1.25rem',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: '#DAE8E7',
    },
    '&:hover fieldset': {
      borderColor: '#DAE8E7',
    },
  },
};

const inputStyle = {
  style: {
    fontFamily: 'Lexend',
    fontSize: '14px',
    fontWeight: '400',
    color: '#243030',
  },
};

const inputLabelStyle = {
  style: {
    fontFamily: 'Lexend',
    fontSize: '14px',
    fontWeight: '300',
    color: '#8CA19F',
  },
  sx: {
    '& .MuiOutlinedInput-notchedOutline': { fontFamily: 'Lexend' },
  },
};
const CustomAutoComplete = ({
  options,
  label,
  placeholder,
  className,
  multiple = true,
  ...props
}) => {
  const classes = usestyles();

  return (
    <Autocomplete
      classes={{ root: classes.inputRoot }}
      className={className}
      multiple={multiple}
      options={options}
      defaultValue={[]}
      freeSolo
      {...props}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          label={label}
          placeholder={placeholder}
          multiline
          classes={{ root: classes.root }}
          sx={textStyle}
          inputProps={inputStyle}
          InputLabelProps={{
            style: inputLabelStyle.style,
            sx: inputLabelStyle.sx,
          }}
          {...params}
        />
      )}
    />
  );
};

const CustomAutoCompleteForUserList = ({
  options,
  label,
  placeholder,
  className,
  ...props
}) => {
  const classes = usestyles();

  const getOptionLabel = (option) => {
    if (option && option.firstName && option.lastName && option.email) {
      return `${option.firstName} ${option.lastName} (${option.email})`;
    }
    return '';
  };

  return (
    <Autocomplete
      classes={{ root: classes.inputRoot }}
      className={className}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props}>
          {option.firstName} {option.lastName} ({option.email})
        </li>
      )}
      filterOptions={(options, { inputValue }) => {
        const filtered = options.filter(
          (option) =>
            `${option.firstName} ${option.lastName}`
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.email.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filtered;
      }}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          classes={{ root: classes.root }}
          InputLabelProps={{
            style: inputLabelStyle.style,
            sx: inputLabelStyle.sx,
          }}
        />
      )}
    />
  );
};

export { CustomAutoComplete, CustomAutoCompleteForUserList };
