import { RadioGroup, FormControlLabel, FormLabel, Radio } from '@mui/material';

const formControlLabelStyle = {
  '& .MuiFormControlLabel-label': {
    color: '#5B706F',
    fontFamily: 'Lexend',
  },

  '& .MuiRadio-root': {
    color: '#4C12A1 !important',
  },
};

const CustomFormLabel = ({ children }) => {
  return (
    <FormLabel
      sx={{
        fontFamily: 'Lexend',
        color: '#243030',
      }}
    >
      {children}
    </FormLabel>
  );
};

const CustomRadioGroup = ({ options, value, setValue }) => {
  return (
    <RadioGroup row value={value} onChange={(e) => setValue(e.target.value)}>
      {options.map((item, index) => {
        return (
          <FormControlLabel
            sx={formControlLabelStyle}
            key={index}
            value={item.toUpperCase()}
            control={<Radio />}
            label={item}
          />
        );
      })}
    </RadioGroup>
  );
};

export { formControlLabelStyle, CustomFormLabel, CustomRadioGroup };
