import React, { useState, useEffect } from 'react';
import '../styles/CoachDashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import api from '../api/api';

function CoachDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [parentQuestionId, setParentQuestionId] = useState('');
  const navigate = useNavigate();

  const showAlert = (message) => {
    alert(message);
  };

  const handleLogout = () => {
    navigate('/');
  };

  const updateActive = (id) => {
    setListData((prevListData) => {
      const newListData = prevListData.map((item) => {
        if (item.id === id) {
          setParentQuestionId(id);
          return { ...item, active: true };
        }
        return { ...item, active: false }; // Set active to false for all other items
      });
      return newListData;
    });
  };

  const getQuestionsList = async () => {
    setIsLoading(true);
    try {
      const response = await api.getCoachQuestions();
      setListData(response);
      if (response.length > 0) {
        setParentQuestionId(response[0].question_id);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const SubChatList = ({ subListData }) => {
    const [feedback, setFeedback] = useState('');

    const approveFeedback = async (data) => {
      setIsLoading(true);
      try {
        await api.submitFeedback(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        showAlert('Something went wrong, try again later');
        setIsLoading(false);
      }
      getQuestionsList();
    };

    return (
      <>
        {subListData.map((item, index) => (
          <div key={index}>
            <div>
              <div className="chat-meta-div-parent">
                <span className="coach-name-text">{item.name}</span>
                <div className="pm-coach-box">
                  <span className="pm-coach-text">{item.type}</span>
                </div>
                <span className="chat-date-2">{item.date}</span>
              </div>
              <p className="chat-text">{item.body}</p>
            </div>
            {item.type === 'GPT' ? (
              <div className="reply-box-parent">
                <div className="chat-meta-div-parent">
                  <span className="coach-name-text">Coach</span>
                </div>
                <textarea
                  className="reply-textarea"
                  placeholder="Your reply"
                  value={feedback || item.body} // Set the value to feedback state if available, otherwise use item.body
                  onChange={(e) => setFeedback(e.target.value)} // Update feedback state when the textarea value changes
                />
                <div className="chat-meta-div-parent">
                  <button
                    className="post-reply-button"
                    onClick={() => {
                      if (item.isParent) {
                        approveFeedback({
                          parent_question_id: item.id,
                          thread_question_id: ' ',
                          feedback: feedback || item.body, // Use feedback state if available, otherwise use item.body
                          accuracy: 'ok',
                        });
                      } else {
                        approveFeedback({
                          parent_question_id: parentQuestionId,
                          thread_question_id: item.id,
                          feedback: feedback || item.body, // Use feedback state if available, otherwise use item.body
                          accuracy: 'ok',
                        });
                      }
                    }}
                  >
                    <span className="post-reply-text">Approve</span>
                  </button>
                  {/* <button
                    className="confirm-close-button"
                    onClick={() => {
                      setFeedback(item.body); // Set feedback state to the original item.body value
                    }}
                  >
                    <span className="confirm-close-text">Edit and Approve</span>
                  </button> */}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </>
    );
  };

  const ChatList = ({ listData, updateActive }) => {
    return (
      <>
        {listData.map((item, index) => (
          <div key={index}>
            {item.active ? (
              <div className="row-chat-open">
                <span className="chat-date">{item.date}</span>
                <div className="chat-title-div">
                  {item.question.length < 50 ? (
                    <span className="chat-title" id="chatTitle">
                      {item.question}
                    </span>
                  ) : (
                    <span className="chat-title" id="chatTitle">
                      {item.question.substring(0, 50)}...
                    </span>
                  )}
                  <Link onClick={() => updateActive(item.id)}>
                    <AiOutlineUp />
                  </Link>
                </div>
                <span className="my-answer-text">Provided Answer</span>
                <div className="horozontal-line"></div>
                {item.userAnswer === null ? (
                  <p className="chat-text">
                    {'Member did not have answer for this question'}
                  </p>
                ) : (
                  <p className="chat-text">{item.userAnswer}</p>
                )}
                <div className="divider-line "></div>
                <div>
                  <SubChatList subListData={item.thread} />
                </div>
              </div>
            ) : (
              <div
                className={`row-chat-close${
                  !item.replied ? ' light-blue' : ''
                }`}
              >
                <span className="chat-date">{item.date}</span>
                <div className="chat-title-div">
                  {item.question.length < 50 ? (
                    <span className="chat-title" id="chatTitle">
                      {item.question}
                    </span>
                  ) : (
                    <span className="chat-title" id="chatTitle">
                      {item.question.substring(0, 50)}...
                    </span>
                  )}
                  <Link onClick={() => updateActive(item.id)}>
                    <AiOutlineDown />
                  </Link>
                </div>
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    getQuestionsList();
  }, []);
  return (
    <div className="parent-container">
      <div className="page-container">{/* Rest of your page content */}</div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p className="loading-message">Submit for Vetting" : "Processing time may be extended</p>
        </div>
      )}
      <div className="main-container">
        <span className="head-text">Queries</span>
        <button className="new-post-button" onClick={handleLogout}>
          <span className="new-post-text">Logout</span>
        </button>
      </div>
      <div className="parent-chat-box">
        <div className="chat-box">
          <ChatList listData={listData} updateActive={updateActive} />
        </div>
      </div>
    </div>
  );
}

export default CoachDashboard;