import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Summary.module.css';
import Person from '../../../images/Ellipse.png';

const Objection = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <h6>Objections Concerning the Conversation</h6>
        <div className={styles.border}></div>

        <div className={styles.questions_section}>
          <img src={Person} alt="" />
          <h6>0:15</h6>
          <div className={styles.objection}>
            <p>
              You've got to work like someone is trying to steal your business.
            </p>
          </div>
        </div>
        <div className={styles.objection_list}>
          <div className={styles.objection_section}>
            <span>ER</span>
            <p>
              A more suitable response to this question than the one provided
              would have been to express polite curiosity and allow the
              conversation to take a natural flow.
            </p>
          </div>
        </div>

        <div className={styles.questions_section}>
          <img src={Person} alt="" />
          <h6>0:15</h6>
          <p>
            You've got to work like someone is trying to steal your business.
          </p>
        </div>
        <div className={styles.objection_list}>
          <div className={styles.objection_section}>
            <span>ER</span>
            <p>
              A more suitable response to this question than the one provided
              would have been to express polite curiosity and allow the
              conversation to take a natural flow.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Objection;
