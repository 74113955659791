import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import LoadingOverlay from 'components/LoadingOverlay';
import organizationAPI from 'api/api';
import { CustomAutoComplete } from 'components/AutoComplete';

const ExtracurricularActivities = ({
  extracurricularActivities,
  getAdmissionTemplateById,
}) => {
  const [loading, setLoading] = useState(false);
  const [extracurricular, setExtracurricular] = useState([]);
  const [leadershipRoles, setLeadershipRoles] = useState([]);
  const [notableAcomplishments, setNotableAcomplishments] = useState([]);

  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  const handleSave = async (e) => {
    setLoading(true);

    const data = {
      templateId: admissionId,
      data: [],
    };

    if (extracurricular.length > 0) {
      data.data.push({
        name: 'extracurricular',
        activities: extracurricular,
      });
    }

    if (leadershipRoles.length > 0) {
      data.data.push({
        name: 'leadershipRoles',
        activities: leadershipRoles,
      });
    }

    if (notableAcomplishments.length > 0) {
      data.data.push({
        name: 'notableAcomplishments',
        activities: notableAcomplishments,
      });
    }

    const response =
      await organizationAPI.addAdmissionTemplateExtracurricularActivities(data);

    await getAdmissionTemplateById();

    setLoading(false);
  };

  useEffect(() => {
    extracurricularActivities.map((item) => {
      if (item.name === 'extracurricular') {
        setExtracurricular(item.activities);
      } else if (item.name === 'leadershipRoles') {
        setLeadershipRoles(item.activities);
      } else if (item.name === 'notableAcomplishments') {
        setNotableAcomplishments(item.activities);
      }
    });
  }, []);
  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.container_head}>
        <h6>Extracurricular Activities</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
          <button onClick={handleSave} className={styles.save}>
            <SaveIcon /> Save Changes
          </button>
        </div>
      </div>
      <div className={styles.border}></div>

      <div className={styles.body}>
        <div className={styles.input_tab}>
          <CustomAutoComplete
            options={['Photography', 'Music']}
            label="Extracurricular Activities"
            placeholder="List your extracurricular activities here, separated by semicolons (;)"
            value={extracurricular}
            onChange={(e, value) => setExtracurricular((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="LEADERSHIP ROLES"
            placeholder="List your leadership roles here, separated by semicolons (;)"
            value={leadershipRoles}
            onChange={(e, value) => setLeadershipRoles((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="NOTABLE ACCOMPLISHMENTS"
            placeholder="List your notable accomplishments here, separated by semicolons (;)"
            value={notableAcomplishments}
            onChange={(e, value) => setNotableAcomplishments((state) => value)}
          />
        </div>
      </div>
    </>
  );
};

export default ExtracurricularActivities;
