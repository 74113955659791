import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import styles from '../styles/Newquery.module.css';

import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { MainContent, Sidebar } from 'components/sidebar';

import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from 'components/TextField';
import { CustomFormLabel, formControlLabelStyle } from 'components/RadioGroup';
import { CustomButton } from 'components/Button';
import Lottie from 'react-lottie';
import spinnerData from 'components/lottieConfig/spinner.json';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinnerData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const AILoadingModal = ({ uploadLoading, setUploadLoading, open, setOpen }) => {
  useEffect(() => {
    if (uploadLoading) {
      const timer = setTimeout(() => {
        if (uploadLoading.step1 === 'waiting') {
          setUploadLoading({
            step1: 'working',
            step2: 'waiting',
            step3: 'waiting',
          });
        } else if (uploadLoading.step1 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'working',
            step3: 'waiting',
          });
        } else if (uploadLoading.step2 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'done',
            step3: 'working',
          });
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [uploadLoading]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={uploadLoading ? null : handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modal_view}>
        <div className={styles.modal_content}>
          <>
            <div className={styles.file_uploading}>
              <h3>
                <Lottie
                  options={defaultOptions}
                  height={20}
                  width={20}
                  style={{
                    margin: 0,
                  }}
                />
                Preparing answer for your query, please wait...
              </h3>
              <p>
                {' '}
                {uploadLoading.step1 === 'waiting' && <PendingOutlinedIcon />}
                {uploadLoading.step1 === 'working' && (
                  <Lottie
                    options={defaultOptions}
                    height={20}
                    width={20}
                    style={{
                      margin: 0,
                    }}
                  />
                )}
                {uploadLoading.step1 === 'done' && <CheckIcon />}
                Processing your response
              </p>
              <p>
                {uploadLoading.step2 === 'waiting' && <PendingOutlinedIcon />}
                {uploadLoading.step2 === 'working' && (
                  <Lottie
                    options={defaultOptions}
                    height={20}
                    width={20}
                    style={{
                      margin: 0,
                    }}
                  />
                )}
                {uploadLoading.step2 === 'done' && <CheckIcon />}
                Initializing AI analysis engine
              </p>
              <p>
                {uploadLoading.step3 === 'waiting' && <PendingOutlinedIcon />}
                {uploadLoading.step3 === 'working' && (
                  <Lottie
                    options={defaultOptions}
                    height={20}
                    width={20}
                    style={{
                      margin: 0,
                    }}
                  />
                )}
                Loading answer
              </p>
            </div>
          </>
        </div>
      </Box>
    </Modal>
  );
};

const NewQuery = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [inputQuestion, setInputQuestion] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [questionIsForNFTEAI, setQuestionIsForNFTEAI] = useState(null);
  const [harmful, setHarmful] = useState(false);
  const [harmfulMessage, setHarmfulMessage] = useState('');

  const [userId, setUserId] = useState('');
  const [organization_id, setOrganization_id] = useState('');
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  const [selectQuestion, setSelectQuestion] = useState([
    {
      name: 'What qualifications do I need to run a business?',
      isSelected: false,
    },
    { name: 'What is a competitive advantage?', isSelected: false },
    {
      name: 'How do I calculate Return on Investment (ROI)?',
      isSelected: false,
    },
    { name: 'How do I estimate my market size?', isSelected: false },
    { name: "What are the 4 P's of Marketing?", isSelected: false },
    { name: 'How do I calculate my Economics of One Unit?', isSelected: false },
  ]);

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUserId(response.data.user._id);
      setOrganization_id(response.data.organization_id);
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateQuestion = (e) => {
    setInputQuestion(e.target.value);
    handleSelectedQuestion(-1);
  };

  const handleSelectedQuestion = (index) => {
    let temp = [...selectQuestion];
    temp.map((item, i) => {
      if (i === index) {
        item.isSelected = true;
        setQuestion(item.name);
        setInputQuestion('');
      } else {
        item.isSelected = false;
      }
    });

    if (index === -1) {
      setQuestion('');
    }
    setSelectQuestion(temp);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/query-archive');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(true);
    setUploadLoading({
      step1: 'waiting',
      step2: 'waiting',
      step3: 'waiting',
    });
    const tempQuestion = inputQuestion ? inputQuestion : question;
    const data = {
      question: tempQuestion,
      questionIsForNFTEAI,
      organization_id,
      student: user,
    };

    const response = await organizationAPI.askQuestion(data);
    setUploadLoading(false);

    if (response.data.harmful) {
      setHarmfulMessage(response.data.message);
      setHarmful(true);
      setLoading(false);
      setUploadLoading(false);
      return;
    }

    navigate('/student/query-archive');
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if ((question || inputQuestion) && questionIsForNFTEAI) {
      setConfirm(true);
    } else {
      setConfirm(false);
    }
  }, [question, inputQuestion, questionIsForNFTEAI]);
  return (
    <MainContent active="query" user={user}>
      {loading && <LoadingOverlay />}
      <AILoadingModal
        open={open}
        setOpen={setOpen}
        uploadLoading={uploadLoading}
        setUploadLoading={setUploadLoading}
      />
      <div className={styles.query__container__header}>
        <h4>Chat</h4>

        <button onClick={handleBack} className={styles.back}>
          <ArrowBackIosIcon />
          Back
        </button>
      </div>
      <div className={styles.border}></div>

      <div className={styles.query__container__body}>
        <h4>Post a New Question</h4>
        <p>
          You can select from our list of most popular questions, given below.
          Your data will be kept completely confidential.
        </p>

        {harmful && (
          <div className={styles.error}>
            <p>{harmfulMessage}</p>
            <CloseIcon onClick={() => setHarmful(false)} />
          </div>
        )}
        <div className={styles.qus_section}>
          {selectQuestion.map((item, index) => (
            <button
              className={
                item.isSelected ? styles.qus_button_selected : styles.qus_button
              }
              onClick={() => handleSelectedQuestion(index)}
            >
              {item.name}
            </button>
          ))}

          <div className={styles.bottom_section__button}>
            {/* <a className={styles.seemore_option}>
                  <p>SEE MORE POPULARLY ASKED QUESTIONS</p>
                  <ChevronRightIcon />
                </a> */}
            <div className={styles.bottom_section__button__input}>
              <CustomTextField
                label="Or, ask your own question!"
                value={inputQuestion}
                onChange={handleUpdateQuestion}
                className={styles.ask_qus}
                minRows={3}
                multiline
              />

              <div className={styles.submit_qus}>
                <FormControl
                  className={styles.options}
                  sx={{ flexDirection: 'row' }}
                >
                  <CustomFormLabel>Submit to</CustomFormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={questionIsForNFTEAI}
                    onChange={(e) => setQuestionIsForNFTEAI(e.target.value)}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      value={true}
                      control={<Radio />}
                      label={<span className={styles.radioText}>NFTE AI</span>}
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      value={false}
                      control={<Radio />}
                      label={<span className={styles.radioText}>Coach</span>}
                    />
                  </RadioGroup>
                </FormControl>

                <CustomButton
                  className={styles.submit}
                  onClick={handleSubmit}
                  disabled={!confirm}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default NewQuery;
