import React, { useEffect, useState } from 'react';
import styles from './styles/View.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ErrorOutlineRounded } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
const Analysis = ({
  documentStatus,
  documentId,
  missingSlides,
  slides,
  topicCount,
  correctSequence,
  title,
  rubric,
  getDocumentById,
}) => {
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const collaboratorList = [{ name: 'Photography' }, { name: 'Music' }];
  const [receivers, setReceivers] = useState([]); // [
  const navigate = useNavigate();
  const [Back, setBack] = useState([]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  return (
    <>
      <div className={styles.container_head}>
        <p>DOCUMENT ANALYSIS </p>
        <h2>{title}</h2>
      </div>
      <div className={styles.border}></div>

      <div
        className={
          documentStatus === 'pass' ? styles.success_text : styles.error_text
        }
      >
        <div
          className={
            documentStatus === 'pass'
              ? styles.success_header
              : styles.error_section
          }
        >
          <h3>
            {documentStatus === 'pass' ? (
              <>
                <CheckCircleIcon /> Congratulations, your document is complete!
              </>
            ) : (
              <>
                <ErrorOutlineRounded /> There are problems with the submitted
                document.
              </>
            )}
          </h3>
        </div>
        <p
          className={
            documentStatus === 'pass' ? styles.success_icon : styles.error_icon
          }
        >
          {documentStatus === 'pass' ? (
            <>
              {' '}
              <CheckCircleOutlineIcon /> 11 of 11 required slides are present
              and accounted for
            </>
          ) : (
            <>
              {' '}
              <ErrorIcon /> {11 - topicCount} of 11 slides are missing
            </>
          )}
        </p>
        <p
          className={correctSequence ? styles.success_icon : styles.error_icon}
        >
          {correctSequence ? (
            <>
              <CheckCircleOutlineIcon />
              All slides are in the correct order
            </>
          ) : (
            <>
              <ErrorIcon /> The slides are not in the correct order
            </>
          )}
        </p>
        <p
          className={
            missingSlides.length === 0 ? styles.success_icon : styles.error_icon
          }
        >
          {missingSlides.length === 0 ? (
            <>
              <CheckCircleOutlineIcon /> All slides are correctly filled out
            </>
          ) : (
            <>
              <ErrorIcon /> {missingSlides.length} slides are missing
            </>
          )}
        </p>
      </div>

      <div className={styles.intro}>
        {rubric &&
          rubric?.slides?.map((slide) => {
            return (
              <>
                <h4>{slide.criteriaName}</h4>
                <p>{slide.feedback}</p>
              </>
            );
          })}
      </div>
    </>
  );
};

export default Analysis;
