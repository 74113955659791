import React, { useEffect, useState } from 'react';
import styles from '../../styles/Canvas.module.css';
import { useNavigate } from 'react-router-dom';
import { MainContent, Sidebar } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { AddCollaboratorIcon } from 'svg/CustomSVGIcon';

import LoadingOverlay from 'components/LoadingOverlay';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';
import { CustomGreenButton } from 'components/Button';

const AddCollaboratorModal = ({
  open,
  setOpen,
  student,
  studentList,
  additionalMember,
  setLoading,
  getCanvas,
  getAllStudent,
  canvasId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
  };

  const [collaboratorList, setCollaboratorList] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState([]);

  useEffect(() => {
    if (studentList) {
      const temp = studentList
        .filter((item) => item.id !== student._id)
        .filter(
          (item) =>
            !additionalMember.find((member) => member.userId === item.id)
        )
        .map((item) => {
          return {
            name: `${item.firstName} ${item.lastName}`,
            userId: item.id,
            email: item.email,
            role: item.role,
          };
        });
      setCollaboratorList(temp);
    }
  }, [studentList]);

  const handleAddCollaborator = async () => {
    setLoading(true);
    const data = selectedCollaborator;

    const response = await organizationAPI.addCollaboratorToCanvas(
      data,
      canvasId
    );
    getCanvas();
    getAllStudent();
    setSelectedCollaborator([]);
    setLoading(false);
  };

  const handleDeleteCollaborator = async (userId) => {
    setLoading(true);

    const response = await organizationAPI.deleteCollaboratorToCanvas(
      canvasId,
      userId
    );
    getCanvas();
    getAllStudent();
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={styles.modalInviteCollaborator}>
        <div className={styles.inviteCollaboratorHeader}>
          <h1>Invite Collaborators</h1>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <p>Current Collaborators</p>
        <div className={styles.currentUserList}>
          <ul>
            {
              <li>
                <p className={styles.currentUser}>
                  {student.firstName} {student.lastName} (Owner)
                </p>
              </li>
            }
            {additionalMember &&
              additionalMember.map((item) => (
                <li>
                  <p className={styles.currentUser}>{item.name}</p>
                  <CloseOutlined
                    onClick={() => handleDeleteCollaborator(item.userId)}
                  />
                </li>
              ))}
          </ul>
        </div>

        <div className={styles.inviteCollaborator}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={collaboratorList}
            className={styles.autocomplete}
            getOptionLabel={(option) => option?.name}
            // defaultValue={[top100Films[13]]}
            filterSelectedOptions
            value={selectedCollaborator}
            inputValue={selectedCollaborator?.name}
            onChange={(e, value) => {
              setSelectedCollaborator(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="ADD NEW COLLABORATORS"
                placeholder="Search by name"
              />
            )}
          />
          <button
            className={styles.inviteButton}
            onClick={() => handleAddCollaborator()}
          >
            Add Personnel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const CommentModal = ({
  canvasId,
  comment,
  user,
  student,
  studentList,
  open,
  setOpen,
  setLoading,
  getCanvas,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const tempRecipientList = [...studentList, student];
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
      recipientList: tempRecipientList,
      schoolName: 'NFTE',
    };

    const response = await organizationAPI.addCommentToCanvas(data, canvasId);
    const canvasResponse = getCanvas();
    setReply('');
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <CustomTextField
          label={`Reply`}
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          multiline
          className={styles.reply_field}
        />

        <div className={styles.modal_bottom}>
          <button
            disabled={!reply}
            className={styles.reply}
            onClick={handleReply}
          >
            Post Reply
          </button>
          <button className={styles.collapse} onClick={() => setOpen(false)}>
            Collapse Comments
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const OverallScore = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
  user,
  gradeId,
  tileId,
  setLoading,
  getCanvas,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      coachId: user._id,
      coachName: user.firstName + ' ' + user.lastName,
      tileId: tileId,
      gradingId: gradeId,
      filedsToUpdate: {},
    };

    if (justification !== justificationText) {
      data.filedsToUpdate.justification = justification;
    }

    if (feedback !== feedbackText) {
      data.filedsToUpdate.feedback = feedback;
    }

    if (score !== scoreVal) {
      data.filedsToUpdate.score = score;
    }

    const response = await organizationAPI.updateCanvasTile(data);
    getCanvas();
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>Total Score</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button className={styles.save} onClick={handleSave}>
              Save and Return to Scorecard
            </button>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={false}
            actualScore={36}
          />
        </div>
      </Box>
    </Modal>
  );
};

const EditModalScoreSection = ({
  subtitle,
  justification,
  setJustification,
  feedback,
  setFeedback,
  score,
  setScore,
  actualScore,
  disabledInput,
}) => {
  return (
    <div className={styles.edit_modal_body}>
      <div className={styles.scorecard_header}>
        <span>ANSWER </span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{subtitle}</h4>
        <h4>
          <input
            className={styles.score}
            value={score}
            onChange={(e) => {
              if (e.target.value <= actualScore) {
                setScore(e.target.value);
              }
            }}
            disabled={disabledInput}
          />
          / {actualScore}
        </h4>
      </div>
      <div className={styles.progress}>
        <LinearProgress variant="determinate" value={50} />
      </div>

      <CustomTextField
        multiline
        className={styles.edit_textfield}
        id="input-with-icon-textfield"
        label={`JUSTIFICATION`}
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
        disabled={disabledInput}
      />
      <CustomTextField
        multiline
        className={styles.edit_textfield}
        id="input-with-icon-textfield"
        label={`FEEDBACK`}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        disabled={disabledInput}
      />
    </div>
  );
};

const EditModal = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
  user,
  gradeId,
  tileId,
  setLoading,
  getCanvas,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      coachId: user._id,
      coachName: user.firstName + ' ' + user.lastName,
      tileId: tileId,
      gradingId: gradeId,
      filedsToUpdate: {},
    };

    if (justification !== justificationText) {
      data.filedsToUpdate.justification = justification;
    }

    if (feedback !== feedbackText) {
      data.filedsToUpdate.feedback = feedback;
    }

    if (score !== scoreVal) {
      data.filedsToUpdate.score = score;
    }

    const response = await organizationAPI.updateCanvasTile(data);
    getCanvas();
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>NFTE AI Scorecard</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button className={styles.save} onClick={handleSave}>
              Save and Return to Scorecard
            </button>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={false}
            actualScore={4}
          />
        </div>
      </Box>
    </Modal>
  );
};

const ViewModal = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_view_header_left}>
            <h5>NFTE AI Scorecard</h5>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={true}
            actualScore={4}
          />
        </div>
      </Box>
    </Modal>
  );
};

const Scrorecard = ({
  gradeId,
  title,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  return (
    <div className={styles.scorecard}>
      <EditModal
        subtitle={title}
        open={open}
        setOpen={setOpen}
        justificationText={gradeList.justification}
        feedbackText={gradeList.feedback}
        scoreVal={gradeList.score}
        user={user}
        gradeId={gradeId}
        tileId={gradeList.tile_id}
        setLoading={setLoading}
        getCanvas={getCanvas}
      />
      <ViewModal
        subtitle={title}
        open={viewModal}
        setOpen={setViewModal}
        justificationText={gradeList.justification}
        feedbackText={gradeList.feedback}
        scoreVal={gradeList.score}
      />
      <div className={styles.scorecard_header}>
        <span>TOPIC</span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{gradeList.name}</h4>
        <h4>{`${gradeList.score} / ${title === 'overall' ? 36 : 4}`}</h4>
      </div>
      <div className={styles.progress}>
        {title === 'overall' ? (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 36}
          />
        ) : (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 4}
          />
        )}
      </div>
      <div className={styles.scorecard_bottom}>
        <a
          onClick={() => {
            setViewModal(true);
          }}
        >
          <span>View Details</span>
          <ArrowForwardIosIcon />
        </a>
        <a
          onClick={() => {
            setOpen(true);
          }}
        >
          Edit
        </a>
      </div>
    </div>
  );
};

// const RemoveModal = ({ open, setOpen, deleteProblem, index }) => {
//   const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 500,
//     bgcolor: 'background.paper',
//     borderRadius: '10px',
//     boxShadow: 24,
//     p: 4,
//   };
//   return (
//     <Modal
//       open={open}
//       onClose={() => setOpen(false)}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//       className={styles.remove_modal}
//     >
//       <Box sx={style}>
//         <Typography
//           id="modal-modal-title"
//           variant="h6"
//           component="h2"
//           className={styles.modal_title}
//         >
//           <h5>Remove Feedback</h5>
//           <p>Are you sure you want to remove this Feedback?</p>
//         </Typography>

//         <div className={styles.modal_buttons}>
//           <button onClick={() => setOpen(false)} className={styles.cancel}>
//             Cancel
//           </button>
//           <button
//             onClick={() => {
//               deleteProblem(index);
//               setOpen(false);
//             }}
//             className={styles.remove}
//           >
//             Remove
//           </button>
//         </div>
//       </Box>
//     </Modal>
//   );
// };

// const NFTEAIFeedback = ({ feedbackText, deleteFeedback, index }) => {
//   const [openRemove, setOpenRemove] = useState(false);

//   const [star, setStar] = useState(false);

//   return (
//     <>
//       <RemoveModal
//         open={openRemove}
//         setOpen={setOpenRemove}
//         deleteProblem={deleteFeedback}
//         index={index}
//       />
//       <div className={styles.ai_card_body}>
//         <p> {feedbackText.slice(0, 80) + '...'}</p>

//         <div className={styles.ai_card_body_bottom}>
//           <span>Dec 5, 2023 • 3:29 PM</span>
//           <div className={styles.ai_card_body_bottom_buttons}>
//             {star ? (
//               <StarIcon onClick={() => setStar(!star)} />
//             ) : (
//               <StarBorderOutlinedIcon onClick={() => setStar(!star)} />
//             )}

//             <DeleteOutlineOutlinedIcon onClick={() => setOpenRemove(true)} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const AIFeedbackModal = ({
  open,
  setOpen,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [overall, setOverall] = useState(false);
  const [iterationId, setIterationId] = useState(null);
  const [active, setActive] = useState('scorecard');

  useEffect(() => {
    if (gradeList) {
      if (gradeList.length > 0) {
        setIterationId(gradeList[0]._id);
      }
    }
  }, [gradeList]);

  // const [feedbackList, setFeedbackList] = useState([
  //   'This is an example of AI-generated feedback from the NFTE AI. It can be regenerated by clicking the button above.',
  //   'Each regeneration of the feedback creates its own card in this scrollable stack.',
  //   'Individual regenerations can be starred (to be moved to the top of the stack) or deleted.',
  // ]);
  // const deleteFeedback = (index) => {
  //   const tempProblemList = [...feedbackList];
  //   tempProblemList.splice(index, 1);
  //   setFeedbackList(tempProblemList);
  // };

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleConfirmGradeStatus = async (status) => {
    setLoading(true);
    const data = {
      gradingId: iterationId,
      status,
    };

    const response = await organizationAPI.updateCanvasGradingStatus(data);
    getCanvas();
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.ai_modal}>
        <div className={styles.ai_modal_top}>
          {gradeList &&
            gradeList.map((item) => {
              if (item._id === iterationId) {
                return (
                  <>
                    <CustomTextField
                      id="input-with-icon-textfield"
                      label={`GRADING STATUS`}
                      value={`${item.status.toUpperCase()}`}
                      className={styles.gradelist_status}
                      disabled
                    />
                    <button
                      onClick={() => handleConfirmGradeStatus('done')}
                      className={styles.return}
                    >
                      Confirm
                    </button>
                  </>
                );
              }
            })}

          <button onClick={() => setOpen(false)} className={styles.return}>
            Return to Canvas
          </button>
          <Box
            className={styles.filter_menu}
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{}} className={styles.drop_down}>
              <select
                className={styles.select}
                value={iterationId}
                onChange={(e) => setIterationId(e.target.value)}
              >
                {gradeList &&
                  gradeList.map((item) => (
                    <option value={item._id}>
                      {convertDate(item.createdAt)}
                    </option>
                  ))}
              </select>
              <ArrowDropDownIcon />
            </FormControl>
          </Box>
        </div>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5
              className={active === 'scorecard' && styles.modal_header_card}
              onClick={() => setActive('scorecard')}
            >
              NFTE AI Scorecard
            </h5>
          </div>
          <div className={styles.modal_header_left}>
            <h5
              className={active === 'activity' && styles.modal_header_card}
              onClick={() => setActive('activity')}
            >
              Activity Log
            </h5>
          </div>
        </div>

        {active === 'scorecard' && (
          <>
            {gradeList &&
              gradeList.map((item) => {
                if (item._id === iterationId) {
                  return (
                    <>
                      <div className={styles.ai_body}>
                        <div className={styles.scorecard_section}>
                          {item.tiles.map((tile) => {
                            if (tile.name !== 'overall')
                              return (
                                <Scrorecard
                                  gradeId={item._id}
                                  title={tile.name}
                                  gradeList={tile}
                                  user={user}
                                  setLoading={setLoading}
                                  getCanvas={getCanvas}
                                />
                              );
                          })}
                        </div>
                      </div>

                      {item.tiles.map((tile) => {
                        if (tile.name === 'overall') {
                          return (
                            <div className={styles.total}>
                              <OverallScore
                                subtitle={tile.name}
                                justificationText={tile.justification}
                                feedbackText={tile.feedback}
                                open={overall}
                                setOpen={setOverall}
                                scoreVal={tile.score}
                                user={user}
                                gradeId={item._id}
                                tileId={tile.tile_id}
                                setLoading={setLoading}
                                getCanvas={getCanvas}
                              />
                              <div className={styles.top}>
                                <div className={styles.top_left}>
                                  <p>Total Score </p>
                                  <a
                                    onClick={() => {
                                      setOverall(true);
                                    }}
                                  >
                                    <span>View Details</span>
                                    <ArrowForwardIosIcon />
                                  </a>
                                </div>
                                <h4>{tile.score} / 36</h4>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={(tile.score * 100) / 36}
                                color="success"
                              />
                            </div>
                          );
                        }
                      })}
                    </>
                  );
                }
              })}
          </>
        )}

        {active === 'activity' && (
          <>
            <div className={styles.activity_log_section}>
              <ul>
                {gradeList &&
                  gradeList.map((item) => {
                    if (item._id === iterationId) {
                      return (
                        <>
                          {item.activities.map((activity) => (
                            <li>
                              <div>
                                <p className={styles.date}>
                                  {convertDate(activity.modifiedAt)}
                                </p>
                                <p className={styles.activity}>
                                  <span className={styles.user}>
                                    {activity.coach_name}{' '}
                                  </span>
                                  modified the canvas
                                </p>
                              </div>
                              <div className={styles.activity_log_border}></div>
                            </li>
                          ))}
                        </>
                      );
                    }
                  })}
              </ul>
            </div>
          </>
        )}
        {/* <div>
          {feedbackList.map((feedback, index) => (
            <NFTEAIFeedback
              feedbackText={feedback}
              deleteFeedback={deleteFeedback}
              index={index}
            />
          ))}

          <div className={styles.generate}>
            <button>
              <FeedbackOutlinedIcon /> Generate New Feedback
            </button>
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

const Card = ({ title, type, workflowList, body }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={styles.card}>
      <div className={styles.card_header}>
        <h5>{title}</h5>
        <p>
          <RemoveRedEyeOutlinedIcon onClick={handleOpen} />
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.modal}>
                <div className={styles.modal_header}>
                  <h5>{title}</h5>
                  <CloseIcon onClick={handleClose} />
                </div>

                {workflowList &&
                  workflowList.map((item) => {
                    if (item.type === type) {
                      return (
                        <div className={styles.modal_body}>
                          <h3>{item.title}</h3>
                          <p className={styles.modal_body_text}>{item.body}</p>
                        </div>
                      );
                    }
                  })}

                <div className={styles.modal_footer}>
                  <button className={styles.close} onClick={handleClose}>
                    Close
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </p>
      </div>
      <div className={styles.card_border}></div>

      <div className={styles.card_body}>
        {body.map((item) => (
          <p>{item}</p>
        ))}
      </div>
    </div>
  );
};

const CanvasDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [comment, setComment] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [canvas, setCanvas] = useState(null);
  const [student, setStudent] = useState(null);
  const [gradeList, setGradeList] = useState(null);

  const [openComment, setOpenComment] = useState(false);
  const [openAIModal, setOpenAIModal] = useState(false);

  const [collaboratorOpen, setCollaboratorOpen] = useState(false);
  const [additionalMember, setAdditionalMember] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [status, setStatus] = useState('');
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const location = useLocation();
  const canvasId = location.pathname.split('/')[4];

  const getCanvas = async () => {
    setLoading(true);
    const response = await organizationAPI.getIndividualCanvasForCoach(
      canvasId
    );
    setCanvas(response.data);
    setWorkflowList(response.data.workflow);
    setComment(response.data.comments);
    setStudent(response.data.userProfile);
    setAdditionalMember(response.data.additionalMembers);
    const gradingResponse =
      await organizationAPI.getGradingAIForIndividualCanvas(canvasId);
    setGradeList(gradingResponse.data.reverse());
    setLoading(false);
  };

  const getProfile = async () => {
    setLoading(true);
    const response = await organizationAPI.getUser();
    setUser(response.data.user);
    setLoading(false);
  };

  const getAllStudent = async () => {
    const studentResponse = await organizationAPI.getAllCanvasStudent();
    const tempStudentList = studentResponse.data.filter((item) => {
      if (item.id !== student._id) return true;
      else return false;
    });

    setStudentList(tempStudentList);
  };

  const handleArchive = async () => {
    setLoading(true);

    const status = canvas?.status === 'archived' ? 'progress' : 'archived';

    try {
      await organizationAPI.updateCanvasStatus(canvasId, status);
      getCanvas();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProfile();
    getCanvas();
  }, []);

  useEffect(() => {
    if (user && student) getAllStudent();
  }, [user, student]);

  const handleGradingStatus = async (status) => {
    setLoading(true);
    const response = await organizationAPI.updateCanvasStatus(canvasId, status);
    getCanvas();
    setLoading(false);
    navigate('/coach/learner/canvas');
  };

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      {user && student && studentList && additionalMember && (
        <AddCollaboratorModal
          open={collaboratorOpen}
          setOpen={setCollaboratorOpen}
          student={student}
          studentList={studentList}
          additionalMember={additionalMember}
          user={user}
          setLoading={setLoading}
          getCanvas={getCanvas}
          getAllStudent={getAllStudent}
          canvasId={canvasId}
        />
      )}
      <AIFeedbackModal
        open={openAIModal}
        gradeList={gradeList}
        setOpen={setOpenAIModal}
        user={user}
        setLoading={setLoading}
        getCanvas={getCanvas}
      />

      <CommentModal
        canvasId={canvasId}
        comment={comment}
        user={user}
        open={openComment}
        setOpen={setOpenComment}
        setLoading={setLoading}
        getCanvas={getCanvas}
        student={student}
        studentList={studentList}
      />

      <MainContent active="canvas" user={user}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>{canvas && canvas.canvasName}</h1>
            <div className={styles.header_buttons}>
            <button onClick={handleArchive} className={styles.comments}>
            {canvas && canvas.status === 'archived' ? (
              <UnarchiveOutlinedIcon />
            ) : (
              <ArchiveOutlinedIcon />
            )}
          </button>
              <button
                className={styles.comment}
                onClick={() => setOpenComment(true)}
              >
                <CommentOutlinedIcon /> Comments
              </button>
              <button
                onClick={() => setCollaboratorOpen(true)}
                className={styles.comments}
              >
                <AddCollaboratorIcon /> Add Collaborator
              </button>
              <button
                className={styles.back}
                onClick={() => handleGradingStatus('progress')}
              >
                <UndoOutlinedIcon />
                Send Back to Student
              </button>
              <CustomGreenButton onClick={() => setOpenAIModal(true)}>
                <FeedbackOutlinedIcon />
                AI Feedback
              </CustomGreenButton>
              <button
                className={styles.complete}
                onClick={() => handleGradingStatus('done')}
              >
                <DoneOutlinedIcon />
                Complete
              </button>
            </div>
          </div>

          <div className={styles.border}></div>
          <div className={styles.tag}>
            <p>
              Click on each section to edit it and fill in its respective
              questions.
            </p>
          </div>

          <div className={styles.input_fields}>
            {canvas && (
              <CustomTextField
                label={`BUSINESS NAME`}
                value={`${canvas.canvasName}`}
                disabled
              />
            )}
            {student && (
              <CustomTextField
                label={`STUDENT NAME`}
                value={`${student.firstName} ${student.lastName}`}
                disabled
              />
            )}
            {canvas && (
              <CustomTextField
                label={`DATE`}
                value={`${convertDate(canvas.createdAt)}`}
                disabled
              />
            )}

            {canvas && (
              <CustomTextField
                label={`TASK STATUS`}
                value={`${canvas.status.toUpperCase()}`}
                disabled
              />
            )}

            {/* <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Task Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Task Status"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Inprogress</MenuItem>
                  <MenuItem value={20}>Done</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
          </div>

          <div className={styles.body}>
            <div className={styles.section_one}>
              <Card
                title="Problem"
                type={'problem'}
                body={['Top 3 problems']}
                workflowList={workflowList}
              />
              <div className={styles.mid}>
                <Card
                  title="Solution"
                  body={['Top 3 solutions']}
                  type={'solution'}
                  workflowList={workflowList}
                />
                <Card
                  title="Key Metrics"
                  body={['Key activities you measure']}
                  type={'metrics'}
                  workflowList={workflowList}
                />
              </div>
              <Card
                title="Unique Value Propositions"
                body={[
                  'Single, clean, compelling message that states why you are different and worth buying',
                ]}
                type={'uniqueValue'}
                workflowList={workflowList}
              />
              <div className={styles.mid}>
                <Card
                  title="Unfair Advantage"
                  body={['Can’t be easily copied/bought']}
                  type={'unfairAdvantage'}
                  workflowList={workflowList}
                />
                <Card
                  title="Channels"
                  body={['Path to customers']}
                  type={'channels'}
                  workflowList={workflowList}
                />
              </div>
              <Card
                title="Customer Segments"
                body={['Target customers']}
                type={'customerSegment'}
                workflowList={workflowList}
              />
            </div>
            <div className={styles.section_two}>
              <Card
                title="Cost Structure"
                body={[
                  'What are the most important costs inherent in our business model?',
                  'Which key resources/activities are most expensive?',
                ]}
                type={'costStructure'}
                workflowList={workflowList}
              />
              <Card
                title="Revenue Streams"
                body={[
                  'For what value are our customers really willing to pay?',
                  'For what do they currently pay?',
                  'How are they currently paying?',

                  'How would they prefer to pay?',
                ]}
                type={'revenueStream'}
                workflowList={workflowList}
              />
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CanvasDashboard;
