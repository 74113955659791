import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  InputAdornment,
  Box,
  Chip,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import _without from 'lodash/without';

import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import StartIcon from '@mui/icons-material/Start';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { NFTEAIIcon, CoachIcon } from 'svg/CustomSVGIcon';

import { MainContent } from 'components/sidebar';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import ErrorOverlay from 'components/ErrorOverlay';

import '../styles/postQus.css';
import { CustomTextField } from 'components/TextField';
import { CustomButton } from 'components/Button';
import { MultipleFilterSelect } from 'components/SelectField';

const convertDate = (dateString) => {
  return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
};

const PersonReply = ({ name, reply, avatar }) => {
  return (
    <>
      <div className="person">
        <div className="personHeader">
          {avatar ? (
            <img src={avatar} alt="Person" referrerpolicy="no-referrer" />
          ) : (
            <AccountCircleIcon style={{ color: '#4c12a1' }} />
          )}
          <span className="personNameText">{name}</span>
        </div>
      </div>

      <p>{reply}</p>
    </>
  );
};

const Question = ({
  user,
  name,
  date,
  data,
  question_id,
  isAI,
  isStarred,
  isQuestionClosed,
  setLoading,
  getQuestions,
}) => {
  const gptReply =
    data[data.length - 1].status === 'gpt' ? data[data.length - 1].text : '';
  const [reply, setReply] = useState(gptReply);
  const [star, setStar] = useState(isStarred);
  const [errorVisible, setErrorVisible] = useState(false);

  const thread_question_id = data[data.length - 1].isThread
    ? data[data.length - 1]._id
    : '';

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const [confirm, setConfirm] = useState(isQuestionClosed);
  const handleReply = async (e) => {
    e.preventDefault();
    setLoading(true);

    const tempData = {
      feedback: reply,
      parent_question_id: question_id,
      student: data[0].user.user,
      coach: {
        name: user.firstName + ' ' + user.lastName,
      },
      questionTitle: data[0].text,
    };

    if (thread_question_id) tempData.thread_question_id = thread_question_id;

    try {
      await organizationAPI.coachFeedback(tempData);
    } catch (error) {
      setErrorVisible(true);
    }
    setReply('');
    getQuestions();
    setLoading(false);
  };

  const handleStarChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question_id,
      flag: !star,
    };
    try {
      await organizationAPI.updateStar(data);
    } catch (error) {
      setErrorVisible(true);
    }
    setStar(!star);
    setLoading(false);
  };

  const handleCloseQuestionChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question_id,
      flag: !confirm,
    };
    try {
      await organizationAPI.updateQuestionClose(data);
    } catch (error) {
      setErrorVisible(true);
    }
    setConfirm(!confirm);
    getQuestions();
    setLoading(false);
  };

  return (
    <div className="sub_container_right">
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <h2>{date}</h2>
      <div className="star">
        <h6>{name}</h6>

        {/* {star ? (
          <StarIcon onClick={handleStarChange} />
        ) : (
          <StarBorderIcon onClick={handleStarChange} />
        )} */}
      </div>

      {data.length === 0 && (
        <p>
          Your question is currently being processed. You will receive a
          response from Coach soon.
        </p>
      )}

      {data.length > 0 &&
        data.map((item, index) => {
          const name = item.user?.user
            ? `${item.user.user.firstName} ${item.user.user.lastName}`
            : 'NFTE AI';

          return item.user?.user ? (
            <PersonReply
              avatar={item.user?.user?.avatar}
              name={name}
              reply={item.text}
            />
          ) : (
            isAI && (
              <PersonReply avatar={null} name={'NFTE AI'} reply={item.text} />
            )
          );
        })}

      <div className="border"></div>

      {!confirm && !isAI && (
        <>
          <h4>Post Reply</h4>

          <CustomTextField
            autoFocus
            multiline
            minRows={5}
            label={'Reply'}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </>
      )}
      <div className="bottom">
        <div className="bottom_section_button">
          {confirm ? (
            <p className="confirm_msg">
              <DoneIcon /> Thread confirmed and closed.
            </p>
          ) : (
            !isAI && (
              <>
                <CustomButton disabled={!reply} onClick={handleReply}>
                  <SendIcon />
                  Post Reply
                </CustomButton>
                <button
                  className={`${data.length === 0 ? 'disabled' : 'close'}`}
                  onClick={handleCloseQuestionChange}
                >
                  <DoneIcon />
                  Confirm and Close Thread
                </button>
              </>
            )
          )}
        </div>
        {confirm ? (
          <p className="reopen" onClick={handleCloseQuestionChange}>
            <StartIcon />
            Reopen Issue
          </p>
        ) : (
          <p className="report">
            {/* <ReportGmailerrorredIcon /> Report Issue */}
          </p>
        )}
      </div>
    </div>
  );
};

const CoachQueryArchive = () => {
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [userId, setUserId] = useState('');
  const [organization_id, setOrganization_id] = useState('');
  const [search, setSearch] = useState('');
  const [user, setUser] = useState({});
  const [personName, setPersonName] = useState(['All']);

  const [selectedQuestion, setSelectedQuestion] = useState([]);

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUserId(response.data.user._id);
      setOrganization_id(response.data.organization_id);
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
    }
    setLoading(false);
  };

  const getQuestions = async () => {
    if (organization_id) {
      setLoading(true);
      try {
        const response = await organizationAPI.getCoachQuestion(
          organization_id,
          userId
        );

        const reverseData = response.data.reverse();

        if (!selectedQuestionId && reverseData.length > 0)
          setSelectedQuestionId(reverseData[0].question_id);

        setSelectedQuestion(reverseData);
      } catch (error) {
        console.log(error);
        setErrorVisible(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    getQuestions();
  }, [organization_id]);

  const handleSelectedQuestion = (question) => {
    let temp = [...selectedQuestion];

    temp.map((item) => {
      if (item.question_id === question.question_id) {
        setSelectedQuestionId(item.question_id);
      }
    });
    setSelectedQuestion(temp);
  };

  return (
    <MainContent active={'query'} user={user}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}
      <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />

      <div className="query__container__header">
        <h4>Query</h4>
        <div className="query_header__right">
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <MultipleFilterSelect
                value={personName}
                setValue={setPersonName}
              />
            </FormControl>
          </div>

          <CustomTextField
            id="input-with-icon-textfield"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="border"></div>

      <div className="query__container__body">
        <h4>Your Chat Archive</h4>
        <p>
          Welcome to your chat archive. This is a complete segregated list of
          all the queries you have posted to the Coach and AI in the past, along
          with their respective discussion threads.
        </p>
      </div>
      {selectedQuestion.length > 0 ? (
        <div className="sub_container">
          <div className="sub_container_left">
            {selectedQuestion
              .filter((item) => {
                if (personName.includes('All') || personName.length === 0) {
                  return true;
                }
                if (personName.includes('Open')) {
                  return !item.isQuestionClosed;
                }
                if (personName.includes('Starred')) {
                  return item.isStarred;
                }
                if (personName.includes('Closed')) {
                  return item.isQuestionClosed;
                }
                if (personName.includes('NFTE AI')) {
                  return item.isAI;
                }
                if (personName.includes('Coach')) {
                  return !item.isAI;
                }
              })
              .filter((item) => {
                if (search === '') {
                  return true;
                } else {
                  return item.question
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }
              })
              .map((item) => {
                if (item.question_id === selectedQuestionId) {
                  return (
                    <div
                      className="sub_container_left_bottom"
                      onClick={() => handleSelectedQuestion(item)}
                    >
                      <p>{convertDate(item.createdAt)}</p>

                      <h5>
                        {item.question.length > 55
                          ? item.question.substring(0, 55) + '...'
                          : item.question}
                      </h5>
                      <p>
                        {item.isAI ? (
                          <NFTEAIIcon fill={'#F3F8F8'} />
                        ) : (
                          <CoachIcon fill={'#F3F8F8'} />
                        )}
                        {item.isAI ? 'NFTE AI' : 'Coach'}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="sub_container_left_top"
                      onClick={() => handleSelectedQuestion(item)}
                    >
                      <p>{convertDate(item.createdAt)}</p>
                      <h5>
                        {item.question.length > 55
                          ? item.question.substring(0, 55) + '...'
                          : item.question}
                      </h5>
                      <p>
                        {item.isAI ? (
                          <NFTEAIIcon fill={'#8CA19F'} />
                        ) : (
                          <CoachIcon fill={'#8CA19F'} />
                        )}
                        {item.isAI ? 'NFTE AI' : 'Coach'}
                      </p>
                    </div>
                  );
                }
              })}
          </div>
          <div className="sub_container_right_section">
            {selectedQuestion
              .filter((item) => {
                if (personName.includes('All') || personName.length === 0) {
                  return true;
                }
                if (personName.includes('Open')) {
                  return !item.isQuestionClosed;
                }
                if (personName.includes('Starred')) {
                  return item.isStarred;
                }
                if (personName.includes('Closed')) {
                  return item.isQuestionClosed;
                }
                if (personName.includes('NFTE AI')) {
                  return item.isAI;
                }
                if (personName.includes('Coach')) {
                  return !item.isAI;
                }
              })
              .filter((item) => {
                if (search === '') {
                  return true;
                } else {
                  return item.question
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }
              })
              .map((item) => {
                if (item.question_id === selectedQuestionId) {
                  return (
                    <Question
                      key={item.question_id}
                      user={user}
                      name={item.question}
                      date={convertDate(item.createdAt)}
                      data={item.data}
                      question_id={item.question_id}
                      isAI={item.isAI}
                      isStarred={item.isStarred}
                      isQuestionClosed={item.isQuestionClosed}
                      setLoading={setLoading}
                      getQuestions={getQuestions}
                    />
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <div className="query__container__body__box">
          <p className="empty_text">There are no chat yet.</p>
        </div>
      )}

      {selectedQuestion.length > 0 &&
        selectedQuestion
          .filter((item) => {
            if (personName.includes('All') || personName.length === 0) {
              return true;
            }
            if (personName.includes('Open')) {
              return !item.isQuestionClosed;
            }
            if (personName.includes('Starred')) {
              return item.isStarred;
            }
            if (personName.includes('Closed')) {
              return item.isQuestionClosed;
            }
            if (personName.includes('NFTE AI')) {
              return item.isAI;
            }
            if (personName.includes('Coach')) {
              return !item.isAI;
            }
          })
          .filter((item) => {
            if (search === '') {
              return true;
            } else {
              return item.question.toLowerCase().includes(search.toLowerCase());
            }
          }).length === 0 && (
          <div className="query__container__body__box">
            <p className="empty_text">There are no chat for the filter.</p>
          </div>
        )}
    </MainContent>
  );
};

export default CoachQueryArchive;
