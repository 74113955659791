import React, { useEffect, useState } from 'react';
import styles from './styles/Assessment.module.css';
import { MainContent, Sidebar } from 'components/sidebar';
import { Navigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import { useLocation, useNavigate } from 'react-router-dom';

import LoadingOverlay from 'components/LoadingOverlay';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UploadIcon from '@mui/icons-material/Upload';
import SendIcon from '@mui/icons-material/Send';
import { Document, Page, pdfjs } from 'react-pdf';

import organizationAPI from 'api/api';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Assessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [file, setFile] = useState(null);
  const [assessment, setAssessment] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getEssayById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getEssayById(admissionId);
      // setAssessment(response.data.essayContent);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getEssayById();
  }, []);

  useEffect(() => {
    if (title && body) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [title, body]);

  const [selectedQuestion, setSelectedQuestion] = React.useState([
    {
      name: 'Do I have to pay any taxes?',
      date: 'Tuesday • May 2, 2023 • 9:24 AM',
      isSelected: true,
    },
    {
      name: 'What is a competitive advantage?',
      date: 'Tuesday • May 2, 2023 • 9:24 AM',
      isSelected: false,
    },
  ]);
  const handleSelectedQuestion = (index) => {
    let temp = [...selectedQuestion];
    temp.map((item, i) => {
      if (i === index) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setSelectedQuestion(temp);
  };

  const handleFileLoad = async (e) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const fileType = ['application/pdf'];
    const file = e.target.files[0];

    if (file && fileType.includes(file.type)) {
      setFile(file);
    }
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    // navigate('/student/admission/submittedAssessment');
    const data = {
      templateId: admissionId,
      essayContent: {
        title,
        body,
      },
    };

    const response = await organizationAPI.submitEssay(data);
    console.log(response.data);
  };

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  function formatText(texts) {
    let textFinal = '';
    for (let i = 0; i < texts.items.length; i++) {
      textFinal += texts.items[i].str;
    }

    setBody(textFinal);
  }
  return (
    <MainContent active="admission" user={user}>
      {loading && <LoadingOverlay />}
      {file && (
        <Document file={file}>
          <Page
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={true}
            onGetTextSuccess={(text) => formatText(text)}
          />
        </Document>
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Admission Attempt Spring 2024</h1>

          <div className={styles.header_buttons}>
            <button onClick={handleReturn} className={styles.return}>
              <ArrowBackIosIcon />
              <span>Return to Dashboard</span>
            </button>
            <button className={styles.return}>
              <EditIcon />
              <span>Edit Template</span>
            </button>
          </div>
        </div>
        <div className={styles.border}></div>

        <div className={styles.all}>
          {assessment.length === 0 && (
            <div className={styles.assessment_section}>
              <p>You have not posted any content for assessment yet.</p>
            </div>
          )}
          {assessment.length > 0 && (
            <div className="sub_container_left">
              {selectedQuestion.map((item, index) => {
                if (item.isSelected) {
                  return (
                    <div
                      className="sub_container_left_bottom"
                      onClick={() => handleSelectedQuestion(index)}
                    >
                      <p>{item.date}</p>
                      <h5>
                        {item.name.length > 60
                          ? item.name.substring(0, 60) + '...'
                          : item.name}
                      </h5>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="sub_container_left_top"
                      onClick={() => handleSelectedQuestion(index)}
                    >
                      <p>{item.date}</p>

                      <h5>
                        {item.name.length > 60
                          ? item.name.substring(0, 60) + '...'
                          : item.name}
                      </h5>
                    </div>
                  );
                }
              })}
            </div>
          )}

          <div className={styles.essay_section}>
            <div className={styles.essay_heading}>
              <p>All fields are required.</p>
            </div>

            <TextField
              id="input-with-icon-textfield"
              label="ESSAY TITLE"
              placeholder="Title of your essay (leave blank to parse automatically from submitted content or document)"
              variant="outlined"
              multiline
              className={styles.description}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              id="input-with-icon-textfield"
              label="ESSAY BODY"
              placeholder="Type your essay into this text field. You can also click the button below to upload it as a document, or drag a document into this field to upload it."
              variant="outlined"
              multiline
              className={styles.description}
              inputProps={{
                style: {
                  height: '192px',
                },
              }}
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />

            <div className={styles.essay_buttons}>
              <input
                type="file"
                id="file"
                style={{ display: 'none' }}
                onChange={handleFileLoad}
              />
              <label htmlFor="file" className={styles.upload}>
                <UploadIcon />
                <span>Upload Document</span>
              </label>

              <button
                onClick={handleSubmission}
                className={styles.submit}
                disabled={disableButton}
              >
                <SendIcon />
                <span>Submit for Assessment</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default Assessment;
