import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';
import { CustomAutoComplete } from 'components/AutoComplete';
import { CustomTextField } from 'components/TextField';

const FutureGoals = ({ futureGoals, getAdmissionTemplateById }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];
  const [loading, setLoading] = useState(false);
  const [shortTermAcademic, setShortTermAcademic] = useState([]);
  const [longTermAcademic, setLongTermAcademic] = useState([]);
  const [shortTermCareer, setShortTermCareer] = useState([]);
  const [longTermCareer, setLongTermCareer] = useState([]);
  const [description, setDescription] = useState('');

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  const handleSave = async (e) => {
    setLoading(true);
    const data = {
      templateId: admissionId,
      data: [
        {
          name: 'shortTermAcademic',
          goals: shortTermAcademic,
        },
        {
          name: 'longTermAcademic',
          goals: longTermAcademic,
        },
        {
          name: 'shortTermCareer',
          goals: shortTermCareer,
        },
        {
          name: 'longTermCareer',
          goals: longTermCareer,
        },
        {
          name: 'description',
          goals: [description],
        },
      ],
    };

    const response = await organizationAPI.addAdmissionTemplateFutureGoals(
      data
    );

    await getAdmissionTemplateById();
    setLoading(false);
  };

  const handleFutureGoalsData = () => {
    futureGoals.map((item) => {
      if (item.name === 'shortTermAcademic') {
        setShortTermAcademic(item.goals);
      } else if (item.name === 'longTermAcademic') {
        setLongTermAcademic(item.goals);
      } else if (item.name === 'shortTermCareer') {
        setShortTermCareer(item.goals);
      } else if (item.name === 'longTermCareer') {
        setLongTermCareer(item.goals);
      } else if (item.name === 'description') {
        setDescription(item.goals[0]);
      }
    });
  };

  useEffect(() => {
    if (futureGoals) handleFutureGoalsData();
  }, [futureGoals]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className={styles.container_head}>
        <h6>Future Goals and Aspirations</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
          <button onClick={handleSave} className={styles.save}>
            <SaveIcon /> Save Changes
          </button>
        </div>
      </div>
      <div className={styles.border}></div>

      <div className={styles.body}>
        <div className={styles.input_tab}>
          <CustomAutoComplete
            options={[]}
            label="SHORT-TERM ACADEMIC GOALS"
            placeholder="List your short-term academic goals here, separated by semicolons (;)"
            value={shortTermAcademic}
            onChange={(e, value) => setShortTermAcademic((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="LONG-TERM ACADEMIC GOALS"
            placeholder="List your long-term academic goals here, separated by semicolons (;)"
            value={longTermAcademic}
            onChange={(e, value) => setLongTermAcademic((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="SHORT-TERM CAREER GOALS"
            placeholder="List your short-term career goals here, separated by semicolons (;)"
            value={shortTermCareer}
            onChange={(e, value) => setShortTermCareer((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="LONG-TERM CAREER GOALS"
            placeholder="List your long-term career goals here, separated by semicolons (;)"
            value={longTermCareer}
            onChange={(e, value) => setLongTermCareer((state) => value)}
          />

          <CustomTextField
            label="HOW DO YOU BELIEVE AN EDUCATION FROM YOUR SCHOOL OF CHOICE WILL HELP YOU TO ACHIEVE THESE GOALS?"
            placeholder="Describe in detail how you believe an education from your school of choice will help you to achieve these goals"
            variant="outlined"
            multiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={styles.description}
            inputProps={{
              style: {
                height: '150px',
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FutureGoals;
