import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { MainContent, Sidebar } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';

import { PendingIcon, ProgressIcon, DoneIcon } from 'svg/CustomSVGIcon';
// import PersonalInformationCard from './PersonalInformationCard';
import Accademic from './Accademic';
import ExtracurricularActivities from './ExtracurricularActivities';
import PersonalExperience from './PersonalExperience';

import TextField from '@mui/material/TextField';

import { useLocation, useNavigate } from 'react-router-dom';

import LoadingOverlay from 'components/LoadingOverlay';
import organizationAPI from 'api/api';
import FutureGoals from './FutureGoals';
import SpecificInterests from './SpecificInterests';
import EssayTopic from './EssayTopic';
import { ArrowBackIos } from '@mui/icons-material';

const AdmissionPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [admissionData, setAdmissionData] = useState(null);

  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [active, setActive] = useState([
    // { name: 'Personal Information', active: true, status: 'pending' },
    { name: 'Academic Interests', active: true, status: 'pending' },
    { name: 'Extracurricular Activities', active: false, status: 'pending' },
    {
      name: 'Personal Experiences and Challenges',
      active: false,
      status: 'pending',
    },
    {
      name: 'Future Goals and Aspirations',
      active: false,
      status: 'pending',
    },
    {
      name: 'Specific Interests in the College',
      active: false,
      status: 'pending',
    },
    { name: 'Essay Topics and Ideas', active: false, status: 'pending' },
  ]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/coach/admission/dashboard');
  };

  const getAdmissionTemplateById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAdmissionTemplateById(
        admissionId
      );
      setAdmissionData(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleStatus = (admissionData) => {
    const temp = active.map((item) => {
      if (
        item.name === 'Academic Interests' &&
        admissionData.academicInterests.length === 2
      ) {
        item.status = 'done';
      } else if (
        item.name === 'Academic Interests' &&
        admissionData.academicInterests.length > 0
      ) {
        item.status = 'progress';
      } else if (
        item.name === 'Academic Interests' &&
        admissionData.academicInterests.length === 0
      ) {
        item.status = 'pending';
      }

      if (
        item.name === 'Extracurricular Activities' &&
        admissionData.extracurricularActivities.length === 3
      ) {
        item.status = 'done';
      } else if (
        item.name === 'Extracurricular Activities' &&
        admissionData.extracurricularActivities.length > 0
      ) {
        item.status = 'progress';
      } else if (
        item.name === 'Extracurricular Activities' &&
        admissionData.extracurricularActivities.length === 0
      ) {
        item.status = 'pending';
      }

      if (
        item.name === 'Personal Experiences and Challenges' &&
        admissionData.personalExperiencesAndChallanges.length > 0
      ) {
        item.status = 'done';
      } else if (
        item.name === 'Personal Experiences and Challenges' &&
        admissionData.personalExperiencesAndChallanges.length === 0
      ) {
        item.status = 'pending';
      }

      if (
        item.name === 'Future Goals and Aspirations' &&
        admissionData.futureGoals.length === 5
      ) {
        if (
          admissionData.futureGoals[0].goals.length > 0 &&
          admissionData.futureGoals[1].goals.length > 0 &&
          admissionData.futureGoals[2].goals.length > 0 &&
          admissionData.futureGoals[3].goals.length > 0 &&
          admissionData.futureGoals[4].goals.length > 0
        ) {
          item.status = 'done';
        } else {
          item.status = 'progress';
        }
      } else if (
        item.name === 'Future Goals and Aspirations' &&
        admissionData.futureGoals.length > 0
      ) {
        item.status = 'progress';
      } else if (
        item.name === 'Future Goals and Aspirations' &&
        admissionData.futureGoals.length === 0
      ) {
        item.status = 'pending';
      }

      if (
        item.name === 'Specific Interests in the College' &&
        admissionData.specificInterests.length > 0
      ) {
        item.status = 'done';
      } else if (
        item.name === 'Specific Interests in the College' &&
        admissionData.specificInterests.length === 0
      ) {
        item.status = 'pending';
      }

      if (
        item.name === 'Essay Topics and Ideas' &&
        admissionData.essayTopicsAndIdeas.length > 0
      ) {
        item.status = 'done';
      } else if (
        item.name === 'Essay Topics and Ideas' &&
        admissionData.essayTopicsAndIdeas.length === 0
      ) {
        item.status = 'pending';
      }

      return item;
    });

    setActive(temp);
  };

  useEffect(() => {
    getMe();
    getAdmissionTemplateById();
  }, []);

  useEffect(() => {
    if (admissionData) {
      handleStatus(admissionData);
    }
  }, [admissionData]);

  return (
    <MainContent active="admission" user={user}>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{admissionData && admissionData.templateName}</h1>

          <div className={styles.header_buttons}>
            <button onClick={handleBack} className={styles.iteration}>
              <ArrowBackIos /> Back to Submissions
            </button>
          </div>
        </div>
        <div className={styles.border}></div>
        <div className={styles.course_list}>
          <ul>
            {active.map((item, index) => (
              <li
                key={index}
                className={item.active ? styles.active : ''}
                onClick={() => {
                  const newActive = active.map((item, i) => {
                    if (i === index) {
                      return { ...item, active: true };
                    } else {
                      return { ...item, active: false };
                    }
                  });
                  setActive(newActive);
                }}
              >
                {item.status === 'pending' ? (
                  <PendingIcon />
                ) : item.status === 'progress' ? (
                  <ProgressIcon fill={'#0AA6E0'} />
                ) : (
                  <DoneIcon />
                )}
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.all}>
          <div className={styles.container}>
            {admissionData &&
              active.map((item) => {
                if (item.active) {
                  // if (item.name === 'Personal Information') {
                  //   return <PersonalInformationCard />;
                  // } else
                  if (item.name === 'Academic Interests') {
                    return (
                      <Accademic
                        academicInterests={admissionData.academicInterests}
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  } else if (item.name === 'Extracurricular Activities') {
                    return (
                      <ExtracurricularActivities
                        extracurricularActivities={
                          admissionData.extracurricularActivities
                        }
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  } else if (
                    item.name === 'Personal Experiences and Challenges'
                  ) {
                    return (
                      <PersonalExperience
                        personalExperiencesAndChallanges={
                          admissionData.personalExperiencesAndChallanges
                        }
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  } else if (item.name === 'Future Goals and Aspirations') {
                    return (
                      <FutureGoals
                        futureGoals={admissionData.futureGoals}
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  } else if (
                    item.name === 'Specific Interests in the College'
                  ) {
                    return (
                      <SpecificInterests
                        specificInterests={admissionData.specificInterests}
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  } else if (item.name === 'Essay Topics and Ideas') {
                    return (
                      <EssayTopic
                        essayTopicsAndIdeas={admissionData.essayTopicsAndIdeas}
                        getAdmissionTemplateById={getAdmissionTemplateById}
                      />
                    );
                  }
                }
              })}
          </div>
          {/* <div className={styles.card}>
              <NFTEAIFeedbackCard />
            </div> */}
        </div>
      </div>
    </MainContent>
  );
};

export default AdmissionPage;
