import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Scoreboard.module.css';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Scoreboard = () => {
  const [loading, setLoading] = useState(false);

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <h6>Total Score</h6>
          <h6>9/12</h6>
        </div>
        <div className={styles.border}></div>
        <p>
          This is an automatically generated summary of the entire conversation.
          It’s almost scary how accurate and succinct it is, but it’s also kind
          of nice. It’s incredible how smart our AI is. The conversation starts
          with Mark Cuban and the caller exchanging greetings, followed by
        </p>
      </div>
      <div className={styles.score_section}>
        <div className={styles.scoreboard}>
          <div className={styles.scoreboard_head}>
            <h4>Opener</h4>
            <h4>4/5</h4>
          </div>

          <p>
            <CheckOutlinedIcon /> Parameter 1
          </p>
          <p>
            <CheckOutlinedIcon />
            Parameter 2
          </p>
          <p>
            <CheckOutlinedIcon />
            Parameter 3
          </p>
          <p>
            <CheckOutlinedIcon /> Parameter 4
          </p>
          <p>
            <CloseOutlinedIcon className={styles.close_icon} />
            Parameter 5
          </p>
        </div>
        <div className={styles.scoreboard}>
          <div className={styles.scoreboard_head}>
            <h4>Discovery</h4>
            <h4>3/5</h4>
          </div>

          <p>
            <CheckOutlinedIcon />
            Parameter 1
          </p>
          <p>
            <CloseOutlinedIcon className={styles.close_icon} />
            Parameter 2
          </p>
          <p>
            <CheckOutlinedIcon />
            Parameter 3
          </p>
        </div>
        <div className={styles.scoreboard}>
          <div className={styles.scoreboard_head}>
            <h4>Closing</h4>
            <h4>4/5</h4>
          </div>

          <p>
            <CheckOutlinedIcon />
            Parameter 1
          </p>
          <p>
            <CheckOutlinedIcon />
            Parameter 2
          </p>
        </div>
      </div>
    </>
  );
};

export default Scoreboard;
