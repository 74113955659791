import React, { useEffect, useState } from 'react';
import styles from './styles/AdmissionPage.module.css';
import { TextField, InputAdornment, Autocomplete, Chip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { useLocation, useNavigate } from 'react-router-dom';

import organizationAPI from 'api/api';
import { CustomAutoComplete } from 'components/AutoComplete';

const Accademic = ({ academicInterests, getAdmissionTemplateById }) => {
  const [loading, setLoading] = useState(false);
  const [majors, setMajors] = useState([]);
  const [achivements, setAchivements] = useState([]);
  const navigate = useNavigate();
  const [Back, setBack] = useState([]);

  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const handleSave = async (e) => {
    setLoading(true);

    const data = {
      templateId: admissionId,
      data: [],
    };

    if (majors.length > 0) {
      data.data.push({
        name: 'majors',
        interests: majors,
      });
    }

    if (achivements.length > 0) {
      data.data.push({
        name: 'achivements',
        interests: achivements,
      });
    }

    const response = await organizationAPI.addAdmissionTemplateAcademicInterest(
      data
    );

    await getAdmissionTemplateById();

    setLoading(false);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  useEffect(() => {
    academicInterests.map((item) => {
      if (item.name === 'majors') {
        setMajors(item.interests);
      } else if (item.name === 'achivements') {
        setAchivements(item.interests);
      }
    });
  }, []);

  return (
    <>
      <div className={styles.container_head}>
        <h6>Academic Interests</h6>
        <div className={styles.button_group}>
          {/* <button onClick={handleBack} className={styles.iteration}>
            <ArrowBackIosIcon /> Back to Submissions
          </button> */}
          <button onClick={handleSave} className={styles.save}>
            <SaveIcon /> Save Changes
          </button>
        </div>
      </div>
      <div className={styles.border}></div>

      <div className={styles.body}>
        <div className={styles.input_tab}>
          <CustomAutoComplete
            options={['Photography', 'Music']}
            label="AREAS OF ACADEMIC INTEREST/INTENDED MAJOR"
            placeholder="List your areas of academic interest and/or intended majors, separated by semicolons (;)"
            value={majors}
            onChange={(e, value) => setMajors((state) => value)}
          />

          <CustomAutoComplete
            options={[]}
            label="ACADEMIC ACHIEVEMENTS OR PROJECTS YOU ARE PROUD OF"
            placeholder="List your notable academic achievements and/or projects here, separated by semicolons (;)  "
            value={achivements}
            onChange={(e, value) => setAchivements((state) => value)}
          />
        </div>
      </div>
    </>
  );
};

export default Accademic;
