import React, { useState } from 'react';
import '../styles/ThankYouQuestion.css';
import { Link } from 'react-router-dom';

function ThankYouQuestion() {
  return (
    <div className="app-container">
      <div className="container">
        <h1 className="header-text">Thank you!</h1>
        <p className="sub-text">
          We have received your query. Our experts will get back to you shortly.
          <br></br>
          You can safely close this tab, post another query, or view queries and{' '}
          <br></br>
          responses from this session or past sessions.
        </p>
        <div className="button-container">
          <Link to="/newQuestion" style={{ textDecoration: 'none' }}>
            <button className="button">
              <span className="button-text">Post Another Query</span>
            </button>
          </Link>
          <div style={{ marginRight: '2%' }}></div>
          <Link to="/userDashboard" style={{ textDecoration: 'none' }}>
            <button className="clear-button">
              <span className="button-text">Visit Query Archive</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThankYouQuestion;