import React from 'react';
import '../styles/SplashScreen.css';
import pgLogo from '../images/pg-logo.png';
import tierOneLogo from '../images/tier_logo.png';
import { Link } from 'react-router-dom';

function SplashScreen() {
  return (
    <div className="app-container">
      <div className="container">
        {/* Your elements here */}
        <img src={tierOneLogo} alt="Image 1" />
        <h1 className="header-text">Welcome </h1>
        <p className="p-text">
          Get your interview questions and answers vetted by industry
          professionals.
        </p>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <button className="button">
            <span className="button-text">Login</span>
          </button>
        </Link>
        <span className="link-text">
          Not a member? <a href="/signup">Sign up here.</a>
        </span>
        <span className="link-text">
          Are you a coach? <a href="/coachLogin">Log in here</a>
        </span>
      </div>
    </div>
  );
}

export default SplashScreen;
