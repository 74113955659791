import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Thankyou.module.css';
import { MainContent } from 'components/sidebar';
import { Modal, Box, Typography, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import { MultipleSelectDocument } from 'components/SelectField';

import {
  Archive,
  ArrowForward,
  ChevronLeft,
  ChevronRight,
  Delete,
  DoNotDisturb,
  Download,
  Edit,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  RemoveCircleOutline,
  Send,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';
import { CustomTextField } from 'components/TextField';
import Save from '@mui/icons-material/Save';
import Check from '@mui/icons-material/Check';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import TipsCaterpillarIcon from './tip_caterpillar.png';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [testList, setTestList] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  const assessmentId = location.pathname.split('/')[4];
  const [tips, setTips] = useState(null);
  const [seen, setSeen] = useState(false);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const [user, setUser] = useState(null);

  const getAssessmentEvalutionTips = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentEvalutionTips(
        assessmentId
      );
      const temp = response.data.map((item) => {
        return { ...item, seen: false };
      });
      setTips(response.data);
      if (response.data.length === 0) {
        setSeen(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getAssessmentEvalutionTips();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}

        <div className={styles.content}>
          {tips && !seen ? (
            tips.map((item, index) => {
              if (index === currentTipIndex) {
                return (
                  <div className={styles.tip_section}>
                    <img src={TipsCaterpillarIcon} alt="Caterpillar Icon" />
                    <div className={styles.tip_body}>
                      <h3>Tip #{index + 1}</h3>
                      <p>{item.tipText}</p>
                      <div className={styles.tip_nav}>
                        <p>
                          TIPS UNLOCKED: {index + 1} of {tips.length}
                        </p>
                        <button
                          onClick={() => {
                            setCurrentTipIndex(currentTipIndex + 1);
                            if (index === tips.length - 1) {
                              setSeen(true);
                            }
                          }}
                        >
                          Next Tip <ArrowForward />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div className={styles.thankyou_section}>
              <CheckCircleOutline />
              <h3>Thank you!</h3>
              <p>
                We have received your answer submissions for this Assessment.
                You will be returned to the Assessments dashboard in a moment.
              </p>
              <button
                onClick={() => {
                  navigate('/student/psych/dashboard');
                }}
              >
                Return to Dashboard
              </button>
            </div>
          )}
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
