import React, { useState, useEffect } from 'react';
import '../styles/NewQuestion.css';
import { Link, useNavigate } from 'react-router-dom';
import tierOneLogo from '../images/tier_logo.png';
import elevateLogo from '../images/elevate_logo.png';
import pgLogo from '../images/pg-logo.png';
import api from '../api/api';

function NewQuestion() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const navigate = useNavigate();

  const showAlert = (message) => {
    alert(message);
  };

  const showLoadingSpinner = () => {
    setIsLoading(true);
  };

  const submitQuestion = async () => {
    setIsLoading(true);
    const data = {
      question_id: ' ',
      question,
      coach_id: api.getCoachIDToLocalStorage(),
    };

    if (answer.length > 0) {
      data.userAnswer = answer;
    }
    try {
      await api.submitQuestion(data);
      setIsLoading(false);
      navigate('/thankYouQuestion');
    } catch (error) {
      console.log(error);
      showAlert('You must have question');
      setIsLoading(false);
    }
  };

  const getUserProfileData = async () => {
    try {
      const response = await api.getUserProfile();
      setType(response.data.data.type);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <div className="app-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p className="loading-message">
            Submit for Vetting" : "Processing time may be extended
          </p>
        </div>
      )}
      <div className="container">
        {type === 'pg' ? <img src={pgLogo} alt="Image 1" /> : null}
        {type === 'elevate' ? <img src={elevateLogo} alt="Image 1" /> : null}
        <h1 className="header-text">Ready to roll!</h1>
        <p className="sub-text">
          Please provide a question and an answer for vetting. <br></br> Your
          data will be kept completely confidential.
        </p>
        <textarea
          className="answer-input"
          placeholder="Type your interview question"
          value={question}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
        />
        <textarea
          className="answer-input"
          placeholder="Type your answer here (Optional)"
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        ></textarea>
        <button className="button" onClick={submitQuestion}>
          <span className="button-text">Submit for Vetting</span>
        </button>
      </div>
    </div>
  );
}

export default NewQuestion;
