import React, { useEffect, useState } from 'react';
import styles from './styles/View.module.css';
import { TextField, InputAdornment, Button, Box } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import RichTextEditor from 'react-rte';
import { useNavigate } from 'react-router-dom';
import Person from '../../../images/person.png';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { DocumentAnalyzerIcon } from 'svg/CustomSVGIcon';

const ExtracurricularActivities = () => {
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const collaboratorList = [{ name: 'Photography' }, { name: 'Music' }];
  const [receivers, setReceivers] = useState([]);
  const navigate = useNavigate();

  const [reply, setReply] = useState(RichTextEditor.createEmptyValue());
  const [replyBody, setReplyBody] = useState('');
  const [disableReplyButton, setDisableReplyButton] = useState(true);
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };

  useEffect(() => {
    if (replyBody.length > 0) {
      setDisableReplyButton(false);
    } else {
      setDisableReplyButton(true);
    }
  }, [replyBody]);
  return (
    <>
      <div className={styles.aiChat}>
        <>
          <div className={styles.post_reply}>
            <div className={styles.top}>
              <div className={styles.student_profile}>
                <DocumentAnalyzerIcon />
                <h4>NFTE Document Analyzer AI</h4>
              </div>
              <div className={styles.icons}>
                <ThumbUpOutlinedIcon />
                <ThumbDownOffAltOutlinedIcon />
                <ReplayOutlinedIcon />
              </div>
            </div>
            <p>
              Hello, your summary has been processed.
              <a> Please click here to see it in the other tab!</a>
            </p>
          </div>
          <div className={styles.post_reply}>
            <div className={styles.top}>
              <div className={styles.student_profile}>
                <img src={Person} alt="" />
                <h4>Mr. Kene Turner</h4>
              </div>
              <div className={styles.icons}>
                <ThumbUpOutlinedIcon />
                <ThumbDownOffAltOutlinedIcon />
                <ReplayOutlinedIcon />
              </div>
            </div>
            <p>
              Mark your calendars and watch this space for more details. Get
              ready to supercharge your tech skills and boost your career with
              TechEd Pro!
            </p>
          </div>
          <div className={styles.reply_section}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', marginLeft: '0' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-controlled"
                label="TYPE YOUR PROMPT HERE"
                placeholder="Message NFTE Document Analyzer..."
                multiline
                // rows={4}
                // maxRows={5}

                onChange={(e) => setReplyBody(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className={
                          disableReplyButton
                            ? styles.disableReplyButton
                            : styles.post_reply
                        }
                        // variant="outlined"
                        style={{ fontWeight: 'bold' }}
                        disabled={disableReplyButton}
                      >
                        <SendOutlinedIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </div>
        </>
      </div>
    </>
  );
};

export default ExtracurricularActivities;
