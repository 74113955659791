import styles from './Button.module.css';
const CustomButton = ({ disabled, className, onClick, children, style }) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
};

const CustomGreenButton = ({ disabled, className, onClick, children }) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.greenButton} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const CustomTextButton = ({ disabled, className, onClick, children }) => {
  return (
    <button
      className={`${styles.unstyledButton} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const CustomWhiteButton = ({ disabled, className, onClick, children }) => {
  return (
    <button className={`${styles.whiteButton} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};
export { CustomButton, CustomGreenButton, CustomTextButton, CustomWhiteButton };
