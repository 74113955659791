import { useEffect, useState } from 'react';
import styles from './styles/View.module.css';
import { MainContent, Sidebar } from 'components/sidebar';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';
import Analysis from './Analysis';
import Analyzer from './Analyzer';
import { DocumentType } from 'components/SelectField';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Person from '../../../images/person.png';
import LoadingOverlay from 'components/LoadingOverlay';
import { AnalysisIcon, AnalyzerIcon } from 'svg/CustomSVGIcon';
import { DoneOutlined, UndoOutlined } from '@mui/icons-material';

const CommentModal = ({
  documentId,
  comment,
  user,
  open,
  setOpen,
  setLoading,
  getDocumentById,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
      schoolName: 'NFTE',
    };

    const response = await organizationAPI.addCommentToDocumentAnalyze(
      data,
      documentId
    );
    await getDocumentById();
    setReply('');
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <div className={styles.bottom_section}>
          <CustomTextField
            label={`Reply`}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            multiline
            className={styles.reply_field}
          />

          <div className={styles.modal_bottom}>
            <button
              disabled={!reply}
              className={styles.reply}
              onClick={handleReply}
            >
              Post Reply
            </button>
            <button className={styles.collapse} onClick={() => setOpen(false)}>
              Collapse Comments
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const DocumentAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [comment, setComment] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [user, setUser] = useState({});

  const [missingSlides, setMissingSlides] = useState([]);
  const [slides, setSlides] = useState([]);
  const [documentStatus, setDocumentStatus] = useState('');
  const [topicCount, setTopicCount] = useState(0);
  const [correctSequence, setCorrectSequence] = useState(false);
  const [title, setTitle] = useState('');
  const [rubric, setRubric] = useState([]);

  const location = useLocation();
  const documentId = location.pathname.split('/')[4];

  const [active, setActive] = useState([
    { name: 'Analysis View', active: true, status: 'pending' },
    // { name: 'Analyzer AI Chat', active: false, status: 'pending' },
  ]);

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/coach/documentanalysis/dashboard');
  };

  const getDocumentById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getDocumentAnalyzeById(documentId);
      const answer = response.data.answers.map((item) => {
        setMissingSlides(item.answer.missingTopics);
        setDocumentStatus(item.answer.status);
        setSlides(item.answer.content);
        setCorrectSequence(item.answer.correctSequence);
        setTopicCount(item.answer.topicCount);
        setTitle(item.answer.title);
        setRubric(JSON.parse(item.answer.rubric));
        if (item.answer.rubric) {
          return { ...item, rubric: JSON.parse(item.answer.rubric) };
        }
        return { ...item };
      });
      setComment(response.data.comments);
      console.log(answer);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangeStatusToStudentReview = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.updateDocumentAnalyzeStatusById(
        documentId,
        { status: 'studentReview' }
      );
      navigate('/coach/documentanalysis/dashboard');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangeStatusToDone = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.updateDocumentAnalyzeStatusById(
        documentId,
        { status: 'done' }
      );
      navigate('/coach/documentanalysis/dashboard');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentById();
  }, []);

  return (
    <MainContent user={user} active="documentanalysis">
      {loading && <LoadingOverlay />}
      <CommentModal
        documentId={documentId}
        comment={comment}
        user={user}
        open={openComment}
        setOpen={setOpenComment}
        setLoading={setLoading}
        getDocumentById={getDocumentById}
      />

      <div className={styles.header}>
        <h2>Document Analysis</h2>

        <div className={styles.button_section}>
          <button className={styles.comment_button} onClick={handleReturn}>
            <ArrowBackIos />
            Return to Documents
          </button>
          <button
            className={styles.comment_button}
            onClick={() => setOpenComment(true)}
          >
            <CommentOutlinedIcon />
            Comments
          </button>
          <button
            className={styles.submit_bar}
            onClick={handleChangeStatusToStudentReview}
          >
            <UndoOutlined /> Return to Student
          </button>
          <button
            className={styles.submit_bar}
            onClick={handleChangeStatusToDone}
          >
            <DoneOutlined /> Submit to NFTE
          </button>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.body}>
        <div className={styles.document_section}>
          {/* <FormControl
                fullWidth
                variant="outlined"
                className={styles.document_type}
              >
                <DocumentType
                  value={selectedDocumentType}
                  setValue={setSelectedDocumentType}
                  input={'SELECT DOCUMENT'}
                  label={'SELECTED DOCUMENT'}
                />
              </FormControl> */}
          <div className={styles.document}>
            {slides.map((item, index) => {
              return (
                <div className={styles.alert_text} key={index}>
                  <div className={styles.alert_text_content}>
                    <h4>{item.topic}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.analyzer_section}>
          <div className={styles.course_list}>
            <ul>
              {active.map((item, index) => (
                <li
                  key={index}
                  className={item.active ? styles.active : ''}
                  onClick={() => {
                    const newActive = active.map((item, i) => {
                      if (i === index) {
                        return { ...item, active: true };
                      } else {
                        return { ...item, active: false };
                      }
                    });
                    setActive(newActive);
                  }}
                >
                  {item.name === 'Analysis View' ? (
                    <AnalysisIcon />
                  ) : (
                    <AnalyzerIcon />
                  )}
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.container}>
            {active.map((item) => {
              if (item.active) {
                if (item.name === 'Analysis View') {
                  return (
                    <Analysis
                      documentStatus={documentStatus}
                      documentId={documentId}
                      missingSlides={missingSlides}
                      slides={slides}
                      topicCount={topicCount}
                      correctSequence={correctSequence}
                      title={title}
                      rubric={rubric}
                      getDocumentById={getDocumentById}
                    />
                  );
                } else if (item.name === 'Analyzer AI Chat') {
                  return <Analyzer />;
                }
              }
            })}
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default DocumentAnalysis;
